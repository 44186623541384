
import { TargetActionTypes, TargetState } from '../types/target_types';
import axios from 'axios'
axios.defaults.withCredentials = true;

const initialState: TargetState = {
    id: 0,
    name: '',
    targets: [],
    error: null,
    message: ''
};

const TargetReducer = (state = initialState, action: TargetActionTypes) => {
    switch (action.type) {
        
        case 'POST_TARGET':
            // const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/`, {});
            // console.log(response)
            return {...state, targets: action.payload.targets};
        case 'POST_SUCCESS_TARGET':
            return {...state, error: null, message: action.payload.message};
        case 'POST_FAIL_TARGET':
            return {...state, error: action.payload.error, message: action.payload.message};
        
        case 'PUT_TARGET':
            // const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/`, {});
            // console.log(response)
            return {...state, data: []};
        case 'PUT_SUCCESS_TARGET':
            return {...state, error: null, message: action.payload.message};
        case 'PUT_FAIL_TARGET':
            return {...state, error: action.payload.error, message: action.payload.message};
        
        case 'UPDATE_TARGET':
            // const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/`, {});
            // console.log(response)
            return {...state, data: []};
        case 'UPDATE_SUCCESS_TARGET':
            return {...state, error: null, message: action.payload.message};
        case 'UPDATE_FAIL_TARGET':
            return {...state, error: action.payload.error, message: action.payload.message};
            
        case 'DELETE_TARGET':
            // const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/`, {});
            // console.log(response)
            return {...state, data: []};
        case 'DELETE_SUCCESS_TARGET':
            return {...state, error: null, message: action.payload.message};
        case 'DELETE_FAIL_TARGET':
            return {...state, error: action.payload.error, message: action.payload.message};
        
        case 'GET_TARGET':
            // const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/`, {});
            // console.log(response)
            return {...state, targets: action.payload.targets};
        case 'GET_SUCCESS_TARGET':
            return {...state, error: null, message: action.payload.message};
        case 'GET_FAIL_TARGET':
            return {...state, error: action.payload.error, message: action.payload.message};
// [ANCHOR_1]
        case 'START_LOADING':
            return {...state, loading: true};
        case 'END_LOADING':
            return {...state, loading: false};
        default:
            return state;
    }
};

export default TargetReducer;
    