import { Dispatch } from "redux";
import {
  CategoryActionTypes,
  CategoryRequest,
  CategoryState,
} from "../types/category_types";
import axios from "axios";
axios.defaults.withCredentials = true;

export const postCategory =
  (data: CategoryState) => (dispatch: Dispatch<CategoryActionTypes>) => {
    try {
      dispatch({
        type: "START_LOADING",
        payload: { ...data, error: null, message: "START_LOADING" },
      });

      dispatch({ type: "POST_CATEGORY", payload: data });

      dispatch({
        type: "POST_SUCCESS_CATEGORY",
        payload: { ...data, error: null, message: "POST_SUCCESS" },
      });

      dispatch({
        type: "END_LOADING",
        payload: { ...data, error: null, message: "END_LOADING" },
      });
    } catch (error) {
      dispatch({
        type: "POST_FAIL_CATEGORY",
        payload: { ...data, error: error, message: "POST_FAIL" },
      });
    }
  };

export const putCategory =
  (data: CategoryState) => (dispatch: Dispatch<CategoryActionTypes>) => {
    try {
      dispatch({
        type: "START_LOADING",
        payload: { ...data, error: null, message: "START_LOADING" },
      });

      dispatch({ type: "PUT_CATEGORY", payload: data });

      dispatch({
        type: "PUT_SUCCESS_CATEGORY",
        payload: { ...data, error: null, message: "PUT_SUCCESS" },
      });

      dispatch({
        type: "END_LOADING",
        payload: { ...data, error: null, message: "END_LOADING" },
      });
    } catch (error) {
      dispatch({
        type: "PUT_FAIL_CATEGORY",
        payload: { ...data, error: error, message: "PUT_FAIL" },
      });
    }
  };

export const updateCategory =
  (data: CategoryState) => (dispatch: Dispatch<CategoryActionTypes>) => {
    try {
      dispatch({
        type: "START_LOADING",
        payload: { ...data, error: null, message: "START_LOADING" },
      });

      dispatch({ type: "UPDATE_CATEGORY", payload: data });

      dispatch({
        type: "UPDATE_SUCCESS_CATEGORY",
        payload: { ...data, error: null, message: "UPDATE_SUCCESS" },
      });

      dispatch({
        type: "END_LOADING",
        payload: { ...data, error: null, message: "END_LOADING" },
      });
    } catch (error) {
      dispatch({
        type: "UPDATE_FAIL_CATEGORY",
        payload: { ...data, error: error, message: "UPDATE_FAIL" },
      });

      dispatch({
        type: "END_LOADING",
        payload: { ...data, error: null, message: "END_LOADING" },
      });
    }
  };

export const deleteCategory =
  (data: CategoryState) => (dispatch: Dispatch<CategoryActionTypes>) => {
    try {
      dispatch({
        type: "START_LOADING",
        payload: { ...data, error: null, message: "START_LOADING" },
      });

      dispatch({ type: "DELETE_CATEGORY", payload: data });

      dispatch({
        type: "DELETE_SUCCESS_CATEGORY",
        payload: { ...data, error: null, message: "DELETE_SUCCESS" },
      });

      dispatch({
        type: "END_LOADING",
        payload: { ...data, error: null, message: "END_LOADING" },
      });
    } catch (error) {
      dispatch({
        type: "DELETE_FAIL_CATEGORY",
        payload: { ...data, error: error, message: "DELETE_FAIL" },
      });
      dispatch({
        type: "END_LOADING",
        payload: { ...data, error: null, message: "END_LOADING" },
      });
    }
  };

export const getCategory =
  (data: CategoryRequest) =>
  async (dispatch: Dispatch<CategoryActionTypes>) => {
    try {
      dispatch({
        type: "START_LOADING",
        payload: { ...data, error: null, message: "START_LOADING" },
      });

      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/api/lawyer/category?id=0&name=''`
      );
      dispatch({ type: "GET_CATEGORY", payload: response.data });

      dispatch({
        type: "GET_SUCCESS_CATEGORY",
        payload: { ...response.data, error: null, message: "GET_SUCCESS" },
      });

      dispatch({
        type: "END_LOADING",
        payload: { ...data, error: null, message: "END_LOADING" },
      });
    } catch (error) {
      dispatch({
        type: "GET_FAIL_CATEGORY",
        payload: {
          error: error,
          message: "GET_FAIL",
          id: 0,
          name: "",
          categories: [],
        },
      });
      dispatch({
        type: "END_LOADING",
        payload: { ...data, error: null, message: "END_LOADING" },
      });
    }
  };
// [ANCHOR_1]
