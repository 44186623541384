import { Dialog, Transition } from "@headlessui/react";
import React, {
  JSXElementConstructor,
  ReactElement,
  useCallback,
  useState,
} from "react";
import { useDropzone } from "react-dropzone";
import { CustomButton } from "../../../../../core/components/buttons/custom_button";
import { HiCheck } from "react-icons/hi";
import { FallingLines } from "react-loader-spinner";
import { GoodsProductRequest } from "../../../redux/types/goods_types";

interface Props {
  folder_id: string;
  colors: string[];
  icon: ReactElement<any, string | JSXElementConstructor<any>>;
  label: string;
  onDrop: () => void;
  handleUpload: () => void;
  postDocument: (document: GoodsProductRequest, files: File[]) => void;
  the_photos: GoodsProductRequest[];
}

const Dropzone: React.FC<Props> = (props: Props) => {
  const [uploadedFiles, setUploadedFiles] = useState<File[]>([]);
  const [isOpen, setIsOpen] = useState(false);

  const onDrop = useCallback(
    (acceptedFiles: File[]) => {
      const handleUpload = async () => {
        setUploadedFiles(acceptedFiles);
        console.log("acceptedFiles folder_id: ", props.folder_id);
        if (props.folder_id) {
          // await props.postDocument(
          //   {
          //     id: "",
          //     name: "",
          //     folder_id: props.folder_id,
          //     documents: [],
          //     is_open: false,
          //     type: "document",
          //   },
          //   acceptedFiles
          // );
          // await props.onDrop();
          // setUploadedFiles([]);
        }
      };
      handleUpload();
    },
    [uploadedFiles]
  );

  const handleCancel = () => {
    setUploadedFiles([]);
    props.onDrop();
  };

  const { getRootProps, getInputProps } = useDropzone({ onDrop });

  function openModal(): void {
    console.log("openModal folder_id:", props.folder_id);
    setUploadedFiles([]);
    setIsOpen(true);
  }

  function closeModal() {
    setIsOpen(false);
  }

  return (
    <>
      <CustomButton
        id="new-case-button"
        colors={props.colors}
        icon={props.icon}
        label={props.label}
        onClick={openModal}
      />

      <Transition onClose={closeModal} appear show={isOpen} as={Dialog}>
        <Dialog
          onClose={closeModal}
          className="fixed inset-0 z-50 overflow-y-auto"
          aria-labelledby="modal-title"
          open={isOpen}
        >
          <div className="flex items-center justify-center min-h-screen">
            <Transition.Child
              as={Dialog.Overlay}
              className="fixed inset-0 bg-black opacity-30"
            />
            <div className="bg-white rounded-lg shadow-lg p-4 w-full md:w-1/2 mx-auto z-10">
              <Dialog.Title
                id="modal-title"
                className="text-lg md:text-xl p-1 font-medium leading-6 text-gray-900"
              >
                <strong>Nuevo expediente</strong>
              </Dialog.Title>

              {props.folder_id && (
                <div>
                  <div
                    {...getRootProps()}
                    className="dropzone p-5 m-1 mx-auto bg-gray-200"
                  >
                    <p>Sera agregado al folder número: {props.folder_id}</p>
                    <input disabled={!!props.folder_id} {...getInputProps()} />
                    <p>Drag & drop some files here, or click to select files</p>
                  </div>
                  <div>
                    {props.the_photos.map((file, index) => (
                      <div
                        key={index}
                        className="flex items-center border py-3 m-1 bg-white"
                      >
                        <div className="px-1">
                          <p>{file.name}</p>
                        </div>
                        <div className="px-1">
                          <HiCheck />
                        </div>
                      </div>
                    ))}
                  </div>
                  <div>
                    {uploadedFiles.map((file, index) => (
                      <div key={index} className="border p-3 m-1 bg-white">
                        <div className="px-1">
                          <p>{file.name}</p>
                        </div>
                        <div className="px-1">
                          <FallingLines
                            color="#4fa94d"
                            width="100"
                            visible={true}
                          />
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              )}

              {/* 
              <div className=''>
                <CustomButton colors={[]} icon={<HiUpload/>} label={'Enviar'} onClick={props.handleUpload}/>
              </div> */}
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  );
};

export default Dropzone;
