import { Dispatch } from "redux";
import {
  SubjectActionTypes,
  SubjectRequest,
  SubjectState,
} from "../types/subject_types";
import axios from "axios";
axios.defaults.withCredentials = true;

export const postSubject =
  (data: SubjectState) => (dispatch: Dispatch<SubjectActionTypes>) => {
    {
      try {
        dispatch({
          type: "START_LOADING",
          payload: { ...data, error: null, message: "START_LOADING" },
        });
        dispatch({ type: "POST_SUBJECT", payload: data });

        dispatch({
          type: "POST_SUCCESS_SUBJECT",
          payload: { ...data, error: null, message: "POST_SUCCESS" },
        });
        dispatch({
          type: "END_LOADING",
          payload: { ...data, error: null, message: "END_LOADING" },
        });
      } catch (error) {
        dispatch({
          type: "POST_FAIL_SUBJECT",
          payload: { ...data, error: error, message: "POST_FAIL" },
        });
        dispatch({
          type: "END_LOADING",
          payload: { ...data, error: null, message: "END_LOADING" },
        });
      }
    }
  };

export const putSubject =
  (data: SubjectState) => (dispatch: Dispatch<SubjectActionTypes>) => {
    {
      try {
        dispatch({
          type: "START_LOADING",
          payload: { ...data, error: null, message: "START_LOADING" },
        });
        dispatch({ type: "PUT_SUBJECT", payload: data });

        dispatch({
          type: "PUT_SUCCESS_SUBJECT",
          payload: { ...data, error: null, message: "PUT_SUCCESS" },
        });
        dispatch({
          type: "END_LOADING",
          payload: { ...data, error: null, message: "END_LOADING" },
        });
      } catch (error) {
        dispatch({
          type: "PUT_FAIL_SUBJECT",
          payload: { ...data, error: error, message: "PUT_FAIL" },
        });
        dispatch({
          type: "END_LOADING",
          payload: { ...data, error: null, message: "END_LOADING" },
        });
      }
    }
  };

export const updateSubject =
  (data: SubjectState) => (dispatch: Dispatch<SubjectActionTypes>) => {
    {
      try {
        dispatch({
          type: "START_LOADING",
          payload: { ...data, error: null, message: "START_LOADING" },
        });
        dispatch({ type: "UPDATE_SUBJECT", payload: data });

        dispatch({
          type: "UPDATE_SUCCESS_SUBJECT",
          payload: { ...data, error: null, message: "UPDATE_SUCCESS" },
        });
        dispatch({
          type: "END_LOADING",
          payload: { ...data, error: null, message: "END_LOADING" },
        });
      } catch (error) {
        dispatch({
          type: "UPDATE_FAIL_SUBJECT",
          payload: { ...data, error: error, message: "UPDATE_FAIL" },
        });
        dispatch({
          type: "END_LOADING",
          payload: { ...data, error: null, message: "END_LOADING" },
        });
      }
    }
  };

export const deleteSubject =
  (data: SubjectState) => (dispatch: Dispatch<SubjectActionTypes>) => {
    {
      try {
        dispatch({
          type: "START_LOADING",
          payload: { ...data, error: null, message: "START_LOADING" },
        });
        dispatch({ type: "DELETE_SUBJECT", payload: data });

        dispatch({
          type: "DELETE_SUCCESS_SUBJECT",
          payload: { ...data, error: null, message: "DELETE_SUCCESS" },
        });
        dispatch({
          type: "END_LOADING",
          payload: { ...data, error: null, message: "END_LOADING" },
        });
      } catch (error) {
        dispatch({
          type: "DELETE_FAIL_SUBJECT",
          payload: { ...data, error: error, message: "DELETE_FAIL" },
        });
        dispatch({
          type: "END_LOADING",
          payload: { ...data, error: null, message: "END_LOADING" },
        });
      }
    }
  };

export const getSubject =
  (data: SubjectRequest) => async (dispatch: Dispatch<SubjectActionTypes>) => {
    {
      try {
        dispatch({
          type: "START_LOADING",
          payload: { ...data, error: null, message: "START_LOADING" },
        });
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/api/lawyer/subject?id=0&name=''`
        );
        dispatch({ type: "GET_SUBJECT", payload: response.data });

        dispatch({
          type: "GET_SUCCESS_SUBJECT",
          payload: { ...response.data, error: null, message: "GET_SUCCESS" },
        });
        dispatch({
          type: "END_LOADING",
          payload: { ...data, error: null, message: "END_LOADING" },
        });
      } catch (error) {
        dispatch({
          type: "GET_FAIL_SUBJECT",
          payload: {
            error: error,
            message: "GET_FAIL",
            id: 0,
            name: "",
            subjects: [],
          },
        });
        dispatch({
          type: "END_LOADING",
          payload: { ...data, error: null, message: "END_LOADING" },
        });
      }
    }
  };
// [ANCHOR_1]
