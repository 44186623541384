import React, { useEffect, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { HiCheck } from "react-icons/hi";
import Select from "react-select";
import { TextField } from "@mui/material";
import dayjs from "dayjs";
import { MobileDateTimePicker } from "@mui/x-date-pickers";
import { CustomButton } from "../../../core/components/buttons/custom_button";
import { CaseRequest } from "../../cases/redux/types/case_types";
import { TagRequest } from "../../../core/redux/types/tag_types";
import {
  OutcomeRequest,
  OutcomeResponse,
  OutcomeState,
} from "../redux/types/outcome_types";
import { OutcomeTypeRequest } from "../redux/types/outcome_type_types";
import axios, { AxiosResponse } from "axios";
axios.defaults.withCredentials = true;

type Props = {
  outcome: OutcomeRequest | null;
  tags: TagRequest[];
  cases: CaseRequest[];
  case: CaseRequest | null;
  outcome_types: OutcomeTypeRequest[];
  handleFormSubmit: (data: OutcomeState) => void;
  setCase: (data: CaseRequest | null) => void;
  colors: string[];
  icon: React.ReactElement;
  label: string;
};

export default function NewOutcomeModal(props: Props) {
  const [isOpen, setIsOpen] = useState(false);

  function closeModal() {
    setIsOpen(false);
  }

  function openModal() {
    setIsOpen(true);
  }

  const initialRequestData: OutcomeRequest = {
    id: 0,
    amount: 0,
    description: "",
    hours: 0,
    case: props.case,
    tags: [],
    photo: "",
    outcome_type: null,
    validated: false,
    start_at: dayjs(),
    end_at: dayjs().endOf("month"),
    created_by: {
      id: 1,
      name: "",
    },
    validated_by: null,
  };

  const [formData, setFormData] = useState<OutcomeRequest>(initialRequestData);

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleSelectChange =
    (fieldName: keyof OutcomeRequest) => (selectedOptions: any) => {
      if (fieldName === "case" && selectedOptions.id === 0) {
        props.setCase(null);
      } else {
        props.setCase(selectedOptions);
      }
    };

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    axios
      .post(`${process.env.REACT_APP_API_URL}/api/lawyer/add-outcome`, formData)
      .then((response: AxiosResponse<OutcomeResponse>) => {
        console.log(response.data);
        props.handleFormSubmit(response.data);
        closeModal();
      })
      .catch((error) => {
        console.log(error);
        closeModal();
      });
  };

  useEffect(() => {
    setFormData({
      ...formData,
      case: props.case,
    });
  }, [props.case]);

  const getOptionValue = (option: any) => option.id;
  const getOptionLabel = (option: any) => option.name;

  return (
    <>
      <CustomButton
        colors={props.colors}
        icon={props.icon}
        label={props.label}
        onClick={openModal}
      />

      <Transition onClose={closeModal} appear show={isOpen} as={Dialog}>
        <Dialog
          onClose={closeModal}
          className="fixed inset-0 z-50 overflow-y-auto"
          aria-labelledby="modal-title"
          open={isOpen}
        >
          <div className="flex items-center justify-center min-h-screen">
            <Transition.Child
              as={Dialog.Overlay}
              className="fixed inset-0 bg-black opacity-30"
            />
            <div className="bg-white rounded-lg shadow-lg p-4 w-full md:w-1/2 mx-auto z-10">
              <Dialog.Title
                id="modal-title"
                className="text-lg md:text-xl p-1 font-medium leading-6 text-gray-900"
              >
                <strong>Nuevo ingreso</strong>
              </Dialog.Title>

              <form
                onSubmit={handleSubmit}
                className="flex flex-wrap items-left"
              >
                <div className="p-1 w-full">
                  <TextField
                    inputProps={{ inputMode: "numeric", pattern: "[0-9]*" }}
                    name="amount"
                    id="amount"
                    label="Cantidad"
                    variant="outlined"
                    onChange={handleInputChange}
                    value={formData.amount}
                    className="w-full"
                  />
                </div>

                <div className="p-1 w-full">
                  <TextField
                    inputProps={{ inputMode: "numeric", pattern: "[0-9]*" }}
                    name="hours"
                    id="hours"
                    label="Hours"
                    variant="outlined"
                    onChange={handleInputChange}
                    value={formData.hours}
                    className="w-full"
                  />
                </div>

                <div className="p-1 w-full md:w-1/2">
                  <MobileDateTimePicker
                    label="Fecha de ingreso"
                    value={formData.start_at}
                    className="w-full"
                  />
                </div>

                <div className="p-1 w-full md:w-1/2">
                  <MobileDateTimePicker
                    disabled={true}
                    label="Ultimo dia para validar"
                    value={formData.end_at}
                    className="w-full"
                  />
                </div>

                <div className="p-1 w-full">
                  <TextField
                    type="text"
                    name="description"
                    id="description"
                    label="Descripción"
                    variant="outlined"
                    onChange={handleInputChange}
                    value={formData.description}
                    className="w-full"
                  />
                </div>

                <div className="p-1 w-1/2">
                  <label>Tipo de Ingreso</label>
                  <Select
                    placeholder="Tipo de Ingreso"
                    key={props.outcome_types.length}
                    options={props.outcome_types}
                    formatOptionLabel={(data) => {
                      return data.name;
                    }}
                    onChange={handleSelectChange("outcome_type")}
                    value={formData.outcome_type}
                    getOptionValue={getOptionValue}
                    className="w-full"
                  />
                </div>

                <div className="p-1 w-1/2">
                  <label>Tags</label>
                  <Select
                    placeholder="Tags"
                    key={props.tags.length}
                    options={props.tags}
                    formatOptionLabel={(data) => {
                      return data.name;
                    }}
                    onChange={handleSelectChange("tags")}
                    value={formData.tags}
                    getOptionValue={getOptionValue}
                    getOptionLabel={getOptionLabel}
                    isMulti
                    className="w-full"
                  />
                </div>

                <div className="p-1 w-1/2">
                  <label>Caso</label>
                  <Select
                    placeholder="Caso"
                    key={
                      [
                        {
                          id: 0,
                          name: "Sin Caso",
                          new_one: null,
                        },
                        ...props.cases,
                      ].length
                    }
                    options={[
                      {
                        id: 0,
                        name: "Sin Caso",
                        new_one: null,
                      },
                      ...props.cases,
                    ]}
                    formatOptionLabel={(data) => {
                      return data.name;
                    }}
                    onChange={handleSelectChange("case")}
                    value={formData.case}
                    getOptionValue={getOptionValue}
                    getOptionLabel={getOptionLabel}
                    className="w-full"
                  />
                </div>

                <div className="w-full pt-2">
                  <p className="text-sm text-gray-500 text-right">
                    Todo listo para enviar?
                  </p>
                </div>

                <div className="w-full pt-2 flex justify-end space-x-2">
                  <button
                    type="button"
                    className="inline-flex justify-center px-4 py-2 text-sm font-medium text-gray-700 bg-gray-100 border border-gray-200 rounded-md hover:bg-gray-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                    onClick={closeModal}
                  >
                    Cancelar
                  </button>
                  <button
                    type="submit"
                    className="inline-flex justify-center px-4 py-2 text-sm font-medium text-white bg-indigo-600 border border-transparent rounded-md hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                  >
                    Si
                    <HiCheck className="ml-2 -mr- h-5 w-5" aria-hidden="true" />
                  </button>
                </div>
              </form>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  );
}
