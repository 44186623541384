import FullWidthLayout from "../../layouts/full_width_layout";
import { useEffect, useState } from "react";
import { FeesDataTable } from "./components/fees_data_table";
import { HiPlusCircle } from "react-icons/hi";
import NewIncomeModal from "../treasury/components/new_income_modal";
import {
  IncomeRequest,
  IncomeState,
} from "../treasury/redux/types/income_types";
import { RootState } from "../../store";
import { ConnectedProps, connect } from "react-redux";
import { getCase } from "../cases/redux/actions/case_action";
import {
  postOutcome,
  updateOutcome,
} from "../treasury/redux/actions/outcome_action";
import {
  postIncome,
  updateIncome,
} from "../treasury/redux/actions/income_action";
import NewOutcomeModal from "../treasury/components/new_outcome_modal";
import {
  OutcomeRequest,
  OutcomeState,
} from "../treasury/redux/types/outcome_types";
import { getGeneralData } from "../../core/redux/actions/general_data_action";
import { getTreasury } from "../treasury/redux/actions/treasury_action";
import { CaseRequest } from "../cases/redux/types/case_types";

const Fees: React.FC<PropsFromRedux> = ({
  id,
  tag_state,
  treasury_state,
  income_type_state,
  outcome_type_state,
  case_state,
  getCase,
  getTreasury,
  getGeneralData,
  postIncome,
  postOutcome,
  updateIncome,
  updateOutcome,
}: PropsFromRedux) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [_case, setCase] = useState<CaseRequest | null>(null);

  const handleSetCase = (data: CaseRequest | null) => {
    setCase(data);
  };

  const handleIncomeFormSubmit = (data: IncomeState) => {
    postIncome(data);
  };

  const handleOutcomeFormSubmit = (data: OutcomeState) => {
    postOutcome(data);
  };

  useEffect(() => {
    const onInit = async () => {
      try {
        const url = window.location.search;
        const params = new URLSearchParams(url);
        await getGeneralData({ id: 0, name: "" });
        await getCase({ id: 0, new_one: null });
        if (params.has("case_id")) {
          const case_id = params.get("case_id");
          const case_ = case_state?.cases?.find(
            (c) => c.id === parseInt(case_id!)
          );

          if (case_) {
            setCase(case_);
            console.log("case:", case_);
            console.log("case_id:", case_id); // This will log the value of case_id
          }
        } else {
          console.log("case_id not found in the URL");
        }
      } catch (error) {
        console.error("Error fetching data: ", error);
      } finally {
        setLoading(false);
      }
    };

    onInit();
  }, []);

  return (
    <FullWidthLayout>
      <div className="fixed w-full bottom-0 left-0 flex flex-row-reverse">
        <div className="p-2">
          <NewIncomeModal
            tags={tag_state?.tags ? tag_state?.tags : []}
            handleFormSubmit={handleIncomeFormSubmit}
            setCase={handleSetCase}
            colors={[]}
            icon={<HiPlusCircle />}
            label={"Nuevo Ingreso"}
            cases={case_state?.cases ? case_state?.cases : []}
            case={_case}
            income_types={
              income_type_state?.income_types
                ? income_type_state.income_types
                : []
            }
            income={null}
          />
        </div>
        <div className="p-2">
          <NewOutcomeModal
            tags={tag_state?.tags ? tag_state?.tags : []}
            handleFormSubmit={handleOutcomeFormSubmit}
            setCase={handleSetCase}
            colors={[]}
            icon={<HiPlusCircle />}
            label={"Nuevo Egreso"}
            cases={case_state?.cases ? case_state?.cases : []}
            case={_case}
            outcome_types={
              outcome_type_state?.outcome_types
                ? outcome_type_state.outcome_types
                : []
            }
            outcome={null}
          />
        </div>
      </div>

      <div>
        {((!loading && case_state?.cases!.length) || 0) && (
          <FeesDataTable
            setCase={handleSetCase}
            tags={tag_state?.tags ? tag_state?.tags : []}
            selectedRow={0}
            incomes={treasury_state?.incomes ? treasury_state.incomes : []}
            cases={case_state?.cases ? case_state?.cases : []}
            getTreasury={getTreasury}
            outcome_types={
              outcome_type_state?.outcome_types
                ? outcome_type_state.outcome_types
                : []
            }
            income_types={
              income_type_state?.income_types
                ? income_type_state.income_types
                : []
            }
            outcomes={treasury_state?.outcomes ? treasury_state.outcomes : []}
            case={_case}
            updateIncome={function (data: IncomeRequest): void {
              throw new Error("Function not implemented.");
            }}
            updateOutcome={function (data: OutcomeRequest): void {
              throw new Error("Function not implemented.");
            }}
          />
        )}
      </div>
    </FullWidthLayout>
  );
};

const mapStateToProps = (state: RootState) => ({
  id: state.income_state?.id,
  tag_state: state.tag_state,
  income_type_state: state.income_type_state,
  outcome_type_state: state.outcome_type_state,
  treasury_state: state.treasury_state,
  case_state: state.case_state,
  new_income: state.income_state?.new_one,
  new_outcome: state.outcome_state?.new_one,
});

const mapDispatchToProps = {
  getCase,
  getTreasury,
  getGeneralData,
  postIncome,
  postOutcome,
  updateIncome,
  updateOutcome,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(Fees);
