import { Dispatch } from "redux";
import {
  StatusActionTypes,
  StatusRequest,
  StatusState,
} from "../types/status_types";
import axios from "axios";
axios.defaults.withCredentials = true;

export const postStatus =
  (data: StatusState) => (dispatch: Dispatch<StatusActionTypes>) => {
    {
      try {
        dispatch({
          type: "START_LOADING",
          payload: { ...data, error: null, message: "START_LOADING" },
        });
        dispatch({ type: "POST_STATUS", payload: data });

        dispatch({
          type: "POST_SUCCESS_STATUS",
          payload: { ...data, error: null, message: "POST_SUCCESS" },
        });
        dispatch({
          type: "END_LOADING",
          payload: { ...data, error: null, message: "END_LOADING" },
        });
      } catch (error) {
        dispatch({
          type: "POST_FAIL_STATUS",
          payload: { ...data, error: error, message: "POST_FAIL" },
        });
        dispatch({
          type: "END_LOADING",
          payload: { ...data, error: null, message: "END_LOADING" },
        });
      }
    }
  };

export const putStatus =
  (data: StatusState) => (dispatch: Dispatch<StatusActionTypes>) => {
    try {
      dispatch({
        type: "START_LOADING",
        payload: { ...data, error: null, message: "START_LOADING" },
      });
      dispatch({ type: "PUT_STATUS", payload: data });

      dispatch({
        type: "PUT_SUCCESS_STATUS",
        payload: { ...data, error: null, message: "PUT_SUCCESS" },
      });
      dispatch({
        type: "END_LOADING",
        payload: { ...data, error: null, message: "END_LOADING" },
      });
    } catch (error) {
      dispatch({
        type: "PUT_FAIL_STATUS",
        payload: { ...data, error: error, message: "PUT_FAIL" },
      });
      dispatch({
        type: "END_LOADING",
        payload: { ...data, error: null, message: "END_LOADING" },
      });
    }
  };

export const updateStatus =
  (data: StatusState) => (dispatch: Dispatch<StatusActionTypes>) => {
    try {
      dispatch({
        type: "START_LOADING",
        payload: { ...data, error: null, message: "START_LOADING" },
      });
      dispatch({ type: "UPDATE_STATUS", payload: data });

      dispatch({
        type: "UPDATE_SUCCESS_STATUS",
        payload: { ...data, error: null, message: "UPDATE_SUCCESS" },
      });
      dispatch({
        type: "END_LOADING",
        payload: { ...data, error: null, message: "END_LOADING" },
      });
    } catch (error) {
      dispatch({
        type: "UPDATE_FAIL_STATUS",
        payload: { ...data, error: error, message: "UPDATE_FAIL" },
      });
      dispatch({
        type: "END_LOADING",
        payload: { ...data, error: null, message: "END_LOADING" },
      });
    }
  };

export const deleteStatus =
  (data: StatusState) => (dispatch: Dispatch<StatusActionTypes>) => {
    try {
      dispatch({
        type: "START_LOADING",
        payload: { ...data, error: null, message: "START_LOADING" },
      });
      dispatch({ type: "DELETE_STATUS", payload: data });

      dispatch({
        type: "DELETE_SUCCESS_STATUS",
        payload: { ...data, error: null, message: "DELETE_SUCCESS" },
      });
      dispatch({
        type: "END_LOADING",
        payload: { ...data, error: null, message: "END_LOADING" },
      });
    } catch (error) {
      dispatch({
        type: "DELETE_FAIL_STATUS",
        payload: { ...data, error: error, message: "DELETE_FAIL" },
      });
      dispatch({
        type: "END_LOADING",
        payload: { ...data, error: null, message: "END_LOADING" },
      });
    }
  };

export const getStatus =
  (data: StatusRequest) => async (dispatch: Dispatch<StatusActionTypes>) => {
    try {
      dispatch({
        type: "START_LOADING",
        payload: { ...data, error: null, message: "START_LOADING" },
      });
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/api/lawyer/status?id=0&name=''`
      );
      dispatch({ type: "GET_STATUS", payload: response.data });

      dispatch({
        type: "GET_SUCCESS_STATUS",
        payload: { ...response.data, error: null, message: "GET_SUCCESS" },
      });
      dispatch({
        type: "END_LOADING",
        payload: { ...data, error: null, message: "END_LOADING" },
      });
    } catch (error) {
      dispatch({
        type: "GET_FAIL_STATUS",
        payload: {
          error: error,
          message: "GET_FAIL",
          id: 0,
          name: "",
          status: [],
          status_list: [],
          searched: null,
        },
      });
      dispatch({
        type: "END_LOADING",
        payload: { ...data, error: null, message: "END_LOADING" },
      });
    }
  };
// [ANCHOR_1]
