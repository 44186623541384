import NoAuthFullWidthLayoutLegal from "../../layouts/no_auth_full_width_layout_legal";
import { ConnectedProps, connect } from "react-redux";
import LoadingFullWidth from "../../components/loaders/loading_full_width";
import { Navigate } from "react-router-dom";
import { loginWeb3 } from "../../redux/actions/web3";
import { postGoogle } from "../../core/redux/actions/google_action";
import { RootState } from "../../store";
import { UserAccount } from "./redux/types/user_account_types";
import { useEffect, useState } from "react";
import { registrationVerifyEmailUserAccount } from "./redux/actions/user_account_action";

import { useParams } from "react-router-dom";

const RegistrationAccountConfirmEmail: React.FC<PropsFromRedux> = ({
  loading,
  registrationVerifyEmailUserAccount,
  user_account_state,
}: PropsFromRedux) => {
  const { key } = useParams<{ key: string }>();
  const [formData, setFormData] = useState<UserAccount>({
    key: key ?? "",
  });
  const [seconds, setSeconds] = useState<number>(5);

  useEffect(() => {
    registrationVerifyEmailUserAccount(formData);
  }, []);

  useEffect(() => {
    if (user_account_state?.detail && user_account_state?.detail !== "") {
      if (user_account_state?.detail.toLowerCase() === "ok") {
        const timer = setInterval(() => {
          setSeconds((prevSeconds) => prevSeconds - 1);
        }, 1000);

        if (seconds <= 0) {
          clearInterval(timer);
          window.location.href = "/login";
        }
      }
    }
  }, [user_account_state?.detail, seconds]);

  // Now you can use the `id` and `token` variables in your component
  if (user_account_state?.isAuthenticated && localStorage.getItem("access")) {
    return <Navigate to="/home" />;
  }

  //   const handleSubmit = async () => {
  //     try {
  //       registrationVerifyEmailUserAccount(formData);
  //     } catch (error) {
  //       console.log("Error new case modal : ", error);
  //     }
  //   };

  return (
    <NoAuthFullWidthLayoutLegal>
      {!loading ? (
        <div className="text-center">
          {user_account_state?.error && (
            <div className="w-full">
              {Object.keys(user_account_state.error).map((key) => (
                <p
                  className="mt-8 text-xl font-gilroy-bold dark:text-stone-50 text-slate-900 sm:text-3xl sm:tracking-tight lg:text-4xl"
                  key={key}
                >
                  {user_account_state.error[key]}
                </p>
              ))}
            </div>
          )}
          {user_account_state?.detail && user_account_state?.detail !== "" && (
            <div className="w-full">
              <p className="mt-8 text-xl font-gilroy-bold dark:text-stone-50 text-slate-900 sm:text-3xl sm:tracking-tight lg:text-4xl">
                {user_account_state?.detail.toLowerCase() === "ok"
                  ? "Email verificado redireccionando a la página de inicio de sesión"
                  : user_account_state?.detail}
                <br />
                {seconds}
              </p>
            </div>
          )}
        </div>
      ) : (
        <LoadingFullWidth />
      )}
      <br />
    </NoAuthFullWidthLayoutLegal>
  );
};

const mapStateToProps = (state: RootState) => ({
  loading: state.web3.loading,
  account: state.web3.account,
  nextwork: state.web3.network,
  google_state: state.google_state,
  user_account_state: state.user_account_state,
  //my_user: state.user.my_user,
});

const mapDispatchToProps = {
  loginWeb3,
  postGoogle,
  registrationVerifyEmailUserAccount,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(RegistrationAccountConfirmEmail);
