import { Dialog, Transition } from '@headlessui/react';
import React, { JSXElementConstructor, ReactElement, useCallback, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { CustomButton } from '../../../core/components/buttons/custom_button';
import { HiCheck } from 'react-icons/hi';
import { FallingLines } from  'react-loader-spinner'
import { OfficeObjectRequest } from '../redux/types/office_object_types';

interface Props {
  office_object_id: number;
  colors: string[];
  icon: ReactElement<any, string | JSXElementConstructor<any>>;
  label: string;
  onDrop: () => void;
  handleUpload: () => void;
  onDeleteItem: (item: OfficeObjectRequest) => void;
  postOfficeObject: (office_object: OfficeObjectRequest, files: File[]) => void;
  the_documents: OfficeObjectRequest[];
}

const TrackingDropzone: React.FC<Props> = (props: Props) => {
  const [uploadedFiles, setUploadedFiles] = useState<File[]>([]);
  const [isOpen, setIsOpen] = useState(false);

  const onDrop = useCallback((acceptedFiles: File[]) => {
    const handleUpload = async () => {

      setUploadedFiles(acceptedFiles);
      console.log('acceptedFiles folder_id: ', props.office_object_id)
      if(props.office_object_id > 0){
        await props.postOfficeObject({
          id: props.office_object_id,
          name: ""
        }, acceptedFiles)   
        
        props.onDrop();
        setUploadedFiles([]);   
      }
    }
    handleUpload();
  }, [uploadedFiles]);

  const { getRootProps, getInputProps } = useDropzone({ onDrop });

  function openModal(): void {
    setUploadedFiles([]);
    setIsOpen(true);
  }

  function closeModal() {
    setIsOpen(false);
  }

  return (
    <>
      <CustomButton 
      id='new-case-button'
      colors={props.colors} icon={props.icon} label={props.label} onClick={openModal}/>

      <Transition onClose={closeModal} appear show={isOpen} as={Dialog}>
        <Dialog
          onClose={closeModal}
          className="fixed inset-0 z-50 overflow-y-auto"
          aria-labelledby="modal-title"
          open={isOpen}
        >
          <div className="flex items-center justify-center min-h-screen">
            <Transition.Child
              as={Dialog.Overlay}
              className="fixed inset-0 bg-black opacity-30"
            />
            <div className="bg-white rounded-lg shadow-lg p-4 w-full md:w-1/2 mx-auto z-10">
              <Dialog.Title
                id="modal-title"
                className="text-lg md:text-xl p-1 font-medium leading-6 text-gray-900"
              >
                <strong>Nuevo expediente</strong>
              </Dialog.Title>
            
              
              {props.office_object_id && (
                <div>
                  <div {...getRootProps()} className="dropzone p-5 m-1 mx-auto bg-gray-200">
                    <input {...getInputProps()} />
                    <p>Drag & drop some files here, or click to select files</p>
                  </div>
                  {/* <div>
                    <CustomButton colors={[]} icon={<HiUpload/>} label={'Upload'} onClick={handleCancel}/>
                  </div> */}
                  <div>
                    {props.the_documents.map((file, index) => (
                      <div key={index} className="flex items-center border py-3 m-1 bg-white">
                        <div className='px-1'>
                          <p>{file.name}</p>
                        </div>
                        <div className='px-1'>
                          <button onClick={() => props.onDeleteItem(file)}>Delete</button>
                        </div>
                        <div className='px-1'>
                          <HiCheck/>
                        </div>
                      </div>
                    ))}
                  </div>
                  <div>
                    {uploadedFiles.map((file, index) => (
                      <div key={index} className="border p-3 m-1 bg-white">
                        <div className='px-1'>
                          <p>{file.name}</p>
                        </div>
                        <div className='px-1'>
                          <FallingLines
                            color="#4fa94d"
                            width="100"
                            visible={true}
                          />
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              )}
              
              {/* 
              <div className=''>
                <CustomButton colors={[]} icon={<HiUpload/>} label={'Enviar'} onClick={props.handleUpload}/>
              </div> */}
              
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  );

};

export default TrackingDropzone;
