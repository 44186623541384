import { TrashActionTypes, TrashState } from "../types/trash_types";

const initialState: TrashState = {
  id: "",
  name: "",
  folders: [],
  documents: [],
  sub_folders: [],
  error: null,
  message: "",
  created_by: null,
  new_one_folder: null,
  new_one_document: null,
  parent_folder: null,
  loading: false,
  the_documents: [],
  data: [],
  document_new_ones: [],
  folder_new_ones: [],
};

const TrashReducer = (state = initialState, action: TrashActionTypes) => {
  switch (action.type) {
    case "TRASH_GET_TRASH":
      return {
        ...state,
        documents: action.payload.documents,
        folders: action.payload.folders,
        error: null,
        message: action.payload.message,
      };
    case "TRASH_GET_TRASH_SUCCESS":
      return { ...state, error: null, message: action.payload.message };
    case "TRASH_GET_TRASH_FAIL":
      return { ...state, error: action.payload, message: "GET_TRASH_FAIL" };

    case "TRASH_DELETE_DOCUMENT":
      return {
        ...state,
        trashs: action.payload.trashs,
        error: null,
        message: action.payload.message,
      };
    case "TRASH_DELETE_DOCUMENT_SUCCESS":
      return { ...state, error: null, message: action.payload.message };
    case "TRASH_DELETE_DOCUMENT_FAIL":
      return {
        ...state,
        error: action.payload,
        message: "DELETE_DOCUMENT_FAIL",
      };

    case "TRASH_POST_FOLDER":
      return {
        ...state,
        folders: [action.payload.new_folder!, ...state.folders],
        error: null,
        message: action.payload.message,
      };
    case "TRASH_POST_FOLDER_SUCCESS":
      return { ...state, error: null, message: action.payload.message };
    case "TRASH_POST_FOLDER_FAIL":
      return { ...state, error: action.payload, message: "POST_FOLDER_FAIL" };

    case "TRASH_DELETE_FOLDER":
      return {
        ...state,
        trashs: action.payload.trashs,
        error: null,
        message: action.payload.message,
      };
    case "TRASH_DELETE_FOLDER_SUCCESS":
      return { ...state, error: null, message: action.payload.message };
    case "TRASH_DELETE_FOLDER_FAIL":
      return { ...state, error: action.payload, message: "DELETE_FOLDER_FAIL" };

    case "TRASH_POST_DOCUMENT":
      let old_state = { ...state };

      const old_ones = [...(old_state.folders ?? [])];
      const new_ones = [...(action.payload.document_new_ones ?? [])];

      for (let i = 0; i < old_ones.length; i++) {
        for (let j = 0; j < new_ones.length; j++) {
          if (old_ones[i].id === new_ones[j].folder) {
            old_state.folders[i].documents?.unshift(new_ones[j].id);
          }
        }
      }

      old_state.documents = [
        ...(action.payload.document_new_ones ?? []),
        ...state.documents,
      ];

      return {
        ...state,
        documents: old_state.documents,
        folders: old_state.folders,
        error: null,
        message: action.payload.message,
      };
    case "TRASH_POST_DOCUMENT_SUCCESS":
      return { ...state, error: null, message: action.payload.message };
    case "TRASH_POST_DOCUMENT_FAIL":
      return { ...state, error: action.payload, message: "POST_DOCUMENT_FAIL" };

    case "TRASH_UPDATE_FOLDER":
      return {
        ...state,
        trashs: action.payload.trashs,
        error: null,
        message: action.payload.message,
      };
    case "TRASH_UPDATE_FOLDER_SUCCESS":
      return { ...state, error: null, message: action.payload.message };
    case "TRASH_UPDATE_FOLDER_FAIL":
      return { ...state, error: action.payload, message: "UPDATE_FOLDER_FAIL" };

    case "TRASH_UPDATE_DOCUMENT":
      return {
        ...state,
        trashs: action.payload.trashs,
        error: null,
        message: action.payload.message,
      };
    case "TRASH_UPDATE_DOCUMENT_SUCCESS":
      return { ...state, error: null, message: action.payload.message };
    case "TRASH_UPDATE_DOCUMENT_FAIL":
      return {
        ...state,
        error: action.payload,
        message: "UPDATE_DOCUMENT_FAIL",
      };
    // [ANCHOR_1]

    case "TRASH_START_LOADING":
      return { ...state, loading: true };
    case "TRASH_END_LOADING":
      return { ...state, loading: false };
    default:
      return state;
  }
};

export default TrashReducer;
