import React, { useState } from "react";
import { FolderRequest } from "../redux/types/folder_types";
import { DocumentRequest } from "../redux/types/document_types";
import { HiDocument, HiDownload, HiFolder, HiTrash } from "react-icons/hi";
import { DownloadButton } from "../../../core/components/buttons/download_button";
import { CustomButton } from "../../../core/components/buttons/custom_button";

interface Props {
  selected_document: DocumentRequest | null;
  selected_folder: FolderRequest | null;
  folders: FolderRequest[];
  documents: DocumentRequest[];
  onDeleteItem: (item: FolderRequest | DocumentRequest) => void;
  selectFolder: (folder: FolderRequest | null) => void;
  selectDocument: (document: DocumentRequest | null) => void;
  onDeleteDocument: (document: DocumentRequest) => void;
  onUpdateDocument: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onUpdateFolder: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

const FolderContent: React.FC<Props> = (props) => {
  const [selectedItem, setSelectedItem] = useState<
    FolderRequest | DocumentRequest | null
  >(null);

  const handleItemClick = async (item: FolderRequest | DocumentRequest) => {
    if (item && !("sub_folders" in item)) {
      // If 'documents' property exists, it's a DocumentRequest
      console.log("document clicked");
      props.selectDocument(item);
    } else if (item && "sub_folders" in item) {
      // If 'sub_folders' property exists, it's a FolderRequest
      console.log("folder clicked");
      item.is_open = !item.is_open;
      await props.selectFolder(item);
    }
  };

  const handleDelete = async () => {
    if (selectedItem) {
      props.onDeleteItem(selectedItem);
      props.selectDocument(null);
      await props.selectFolder(null);
    }
  };

  const renderFolder = (folder: FolderRequest) => (
    <div key={folder.id} className="p-2">
      <div
        onDoubleClick={() => handleItemClick(folder)}
        className={`flex items-center flex-col ${
          props.selected_folder && folder.id == props.selected_folder.id
            ? "bg-blue-200"
            : "bg-gray-50"
        } hover:bg-gray-300 p-2 ${folder.is_open ? "cursor-pointer" : ""}`}
      >
        <div className="p-1">
          <HiFolder className="inline-block text-4xl" />
        </div>

        <div className="p-2">{folder.name}</div>

        <div className="flex w-full">
          <div className="p-1 mx-auto">
            <CustomButton
              label=""
              onClick={() => props.onDeleteItem(folder)}
              colors={[]}
              icon={<HiTrash />}
            />
          </div>
        </div>
      </div>
    </div>
  );

  function handleDownloadDocument(_document: DocumentRequest): void {
    // Create a temporary anchor element
    const link = document.createElement("a");
    link.href = `${process.env.REACT_APP_API_URL}/${_document.file}`;
    if (_document.name) {
      link.download = _document.name;
      link.click();
    } else {
      link.remove();
    }
  }

  return (
    <div>
      {/* <button onClick={handleDelete} disabled={!props.selected_document && !props.selected_folder}>
        Delete
      </button> */}
      <div className="overflow-y-auto border-r-2 p-4 flex flex-wrap">
        {props.folders.map(renderFolder)}
        {props.documents?.map((document) => (
          <div
            key={document.id}
            onClick={() => handleItemClick(document)}
            className={`relative w-32 flex flex-col items-center justify-center p-2`}
          >
            <div
              className={`flex flex-col rounded border-2 border-solid border-gray-300 bg-gray-50 ${
                props.selected_document === document ? "bg-blue-500" : ""
              }`}
            >
              <div className="p-1 m-auto">
                <HiDocument className="inline-block text-4xl" />
              </div>
              <div className="break-all overflow-x-auto p-1 w-full text-center">
                {document.name}
              </div>
              <div className="flex w-full">
                <div className="p-1 mx-auto">
                  <CustomButton
                    label=""
                    onClick={() => props.onDeleteItem(document)}
                    colors={[]}
                    icon={<HiTrash />}
                  />
                </div>
                <div className="p-1 mx-auto">
                  <DownloadButton
                    label=""
                    onClick={() => handleDownloadDocument(document)}
                    colors={[]}
                    icon={<HiDownload />}
                    file_path={
                      document.file
                        ? `${process.env.REACT_APP_API_URL}${document.file}`
                        : "#"
                    }
                    file_name={document.name}
                  />
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default FolderContent;
