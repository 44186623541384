import { Dispatch } from "redux";
import { TagActionTypes, TagRequest, TagState } from "../types/tag_types";
import axios from "axios";
axios.defaults.withCredentials = true;

export const postTag =
  (data: TagState) => (dispatch: Dispatch<TagActionTypes>) => {
    try {
      dispatch({
        type: "START_LOADING",
        payload: { ...data, error: null, message: "START_LOADING" },
      });

      dispatch({ type: "POST_TAG", payload: data });

      dispatch({
        type: "POST_SUCCESS_TAG",
        payload: { ...data, error: null, message: "POST_SUCCESS" },
      });
      dispatch({
        type: "END_LOADING",
        payload: { ...data, error: null, message: "END_LOADING" },
      });
    } catch (error) {
      dispatch({
        type: "POST_FAIL_TAG",
        payload: { ...data, error: error, message: "POST_FAIL" },
      });
      dispatch({
        type: "END_LOADING",
        payload: { ...data, error: null, message: "END_LOADING" },
      });
    }
  };

export const putTag =
  (data: TagState) => (dispatch: Dispatch<TagActionTypes>) => {
    try {
      dispatch({
        type: "START_LOADING",
        payload: { ...data, error: null, message: "START_LOADING" },
      });

      dispatch({ type: "PUT_TAG", payload: data });

      dispatch({
        type: "PUT_SUCCESS_TAG",
        payload: { ...data, error: null, message: "PUT_SUCCESS" },
      });
      dispatch({
        type: "END_LOADING",
        payload: { ...data, error: null, message: "END_LOADING" },
      });
    } catch (error) {
      dispatch({
        type: "PUT_FAIL_TAG",
        payload: { ...data, error: error, message: "PUT_FAIL" },
      });
      dispatch({
        type: "END_LOADING",
        payload: { ...data, error: null, message: "END_LOADING" },
      });
    }
  };

export const updateTag =
  (data: TagState) => (dispatch: Dispatch<TagActionTypes>) => {
    try {
      dispatch({
        type: "START_LOADING",
        payload: { ...data, error: null, message: "START_LOADING" },
      });

      dispatch({ type: "UPDATE_TAG", payload: data });

      dispatch({
        type: "UPDATE_SUCCESS_TAG",
        payload: { ...data, error: null, message: "UPDATE_SUCCESS" },
      });
      dispatch({
        type: "END_LOADING",
        payload: { ...data, error: null, message: "END_LOADING" },
      });
    } catch (error) {
      dispatch({
        type: "UPDATE_FAIL_TAG",
        payload: { ...data, error: error, message: "UPDATE_FAIL" },
      });
      dispatch({
        type: "END_LOADING",
        payload: { ...data, error: null, message: "END_LOADING" },
      });
    }
  };

export const deleteTag =
  (data: TagState) => (dispatch: Dispatch<TagActionTypes>) => {
    try {
      dispatch({
        type: "START_LOADING",
        payload: { ...data, error: null, message: "START_LOADING" },
      });

      dispatch({ type: "DELETE_TAG", payload: data });

      dispatch({
        type: "DELETE_SUCCESS_TAG",
        payload: { ...data, error: null, message: "DELETE_SUCCESS" },
      });
      dispatch({
        type: "END_LOADING",
        payload: { ...data, error: null, message: "END_LOADING" },
      });
    } catch (error) {
      dispatch({
        type: "DELETE_FAIL_TAG",
        payload: { ...data, error: error, message: "DELETE_FAIL" },
      });
      dispatch({
        type: "END_LOADING",
        payload: { ...data, error: null, message: "END_LOADING" },
      });
    }
  };

export const getTag =
  (data: TagRequest) => async (dispatch: Dispatch<TagActionTypes>) => {
    try {
      dispatch({
        type: "START_LOADING",
        payload: { ...data, error: null, message: "START_LOADING" },
      });

      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/api/lawyer/tag?id=0&name=''`
      );
      dispatch({ type: "GET_TAG", payload: response.data });

      dispatch({
        type: "GET_SUCCESS_TAG",
        payload: { ...response.data, error: null, message: "GET_SUCCESS" },
      });
      dispatch({
        type: "END_LOADING",
        payload: { ...data, error: null, message: "END_LOADING" },
      });
    } catch (error) {
      dispatch({
        type: "GET_FAIL_TAG",
        payload: {
          error: error,
          message: "GET_FAIL",
          id: 0,
          name: "",
          tags: [],
        },
      });
      dispatch({
        type: "END_LOADING",
        payload: { ...data, error: null, message: "END_LOADING" },
      });
    }
  };
// [ANCHOR_1]
