import { Dispatch } from "redux";
import {
  OutcomeTypeActionTypes,
  OutcomeTypeRequest,
  OutcomeTypeState,
} from "../types/outcome_type_types";
import axios from "axios";
axios.defaults.withCredentials = true;

export const postOutcomeType =
  (data: OutcomeTypeState) => (dispatch: Dispatch<OutcomeTypeActionTypes>) => {
    {
      try {
        dispatch({ type: "POST_OUTCOME_TYPE", payload: data });

        dispatch({
          type: "POST_SUCCESS_OUTCOME_TYPE",
          payload: { ...data, error: null, message: "POST_SUCCESS" },
        });
      } catch (error) {
        dispatch({
          type: "POST_FAIL_OUTCOME_TYPE",
          payload: { ...data, error: error, message: "POST_FAIL" },
        });
      }
    }
  };

export const putOutcomeType =
  (data: OutcomeTypeState) => (dispatch: Dispatch<OutcomeTypeActionTypes>) => {
    {
      try {
        dispatch({ type: "PUT_OUTCOME_TYPE", payload: data });

        dispatch({
          type: "PUT_SUCCESS_OUTCOME_TYPE",
          payload: { ...data, error: null, message: "PUT_SUCCESS" },
        });
      } catch (error) {
        dispatch({
          type: "PUT_FAIL_OUTCOME_TYPE",
          payload: { ...data, error: error, message: "PUT_FAIL" },
        });
      }
    }
  };

export const updateOutcomeType =
  (data: OutcomeTypeState) => (dispatch: Dispatch<OutcomeTypeActionTypes>) => {
    {
      try {
        dispatch({ type: "UPDATE_OUTCOME_TYPE", payload: data });

        dispatch({
          type: "UPDATE_SUCCESS_OUTCOME_TYPE",
          payload: { ...data, error: null, message: "UPDATE_SUCCESS" },
        });
      } catch (error) {
        dispatch({
          type: "UPDATE_FAIL_OUTCOME_TYPE",
          payload: { ...data, error: error, message: "UPDATE_FAIL" },
        });
      }
    }
  };

export const deleteOutcomeType =
  (data: OutcomeTypeState) => (dispatch: Dispatch<OutcomeTypeActionTypes>) => {
    {
      try {
        dispatch({ type: "DELETE_OUTCOME_TYPE", payload: data });

        dispatch({
          type: "DELETE_SUCCESS_OUTCOME_TYPE",
          payload: { ...data, error: null, message: "DELETE_SUCCESS" },
        });
      } catch (error) {
        dispatch({
          type: "DELETE_FAIL_OUTCOME_TYPE",
          payload: { ...data, error: error, message: "DELETE_FAIL" },
        });
      }
    }
  };

export const getOutcomeType =
  (data: OutcomeTypeRequest) =>
  async (dispatch: Dispatch<OutcomeTypeActionTypes>) => {
    {
      try {
        const response = await axios.post(
          `${process.env.REACT_APP_API_URL}/api/lawyer/treasury`,
          { id: 0, search: "outcome_type", name: "" }
        );
        dispatch({ type: "GET_OUTCOME_TYPE", payload: response.data });

        dispatch({
          type: "GET_SUCCESS_OUTCOME_TYPE",
          payload: { ...response.data, error: null, message: "GET_SUCCESS" },
        });
      } catch (error) {
        dispatch({
          type: "GET_FAIL_OUTCOME_TYPE",
          payload: {
            error: error,
            message: "GET_FAIL",
            id: 0,
            name: "",
            outcome_types: [],
          },
        });
      }
    }
  };
// [ANCHOR_1]
