import NoAuthFullWidthLayoutLegal from "../../layouts/no_auth_full_width_layout_legal";
import { ConnectedProps, connect } from "react-redux";
import { HiCheck, HiMail } from "react-icons/hi";
import LoadingFullWidth from "../../components/loaders/loading_full_width";
import { Link, Navigate } from "react-router-dom";
import { loginWeb3 } from "../../redux/actions/web3";
import { postGoogle } from "../../core/redux/actions/google_action";
import { RootState } from "../../store";
import { TextField } from "@mui/material";
import { UserAccount } from "./redux/types/user_account_types";
import { useState } from "react";
import {
  registerUserAccount,
  resendUserAccount,
} from "./redux/actions/user_account_action";
import { CustomButton } from "../../core/components/buttons/custom_button";

const Registration: React.FC<PropsFromRedux> = ({
  loading,
  registerUserAccount,
  resendUserAccount,
  user_account_state,
}: PropsFromRedux) => {
  const initialRequestData: UserAccount = {
    email: "",
    password1: "",
    password2: "",
  };

  const [formData, setFormData] = useState<UserAccount>(initialRequestData);

  if (user_account_state?.isAuthenticated && localStorage.getItem("access")) {
    return <Navigate to="/home" />;
  }

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    try {
      event.preventDefault();
      registerUserAccount(formData);
    } catch (error) {
      console.log("Error new case modal : ", error);
    }
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  return (
    <NoAuthFullWidthLayoutLegal>
      <div className="text-center">
        <p className="mt-8 text-xl font-gilroy-bold dark:text-stone-50 text-slate-900 sm:text-3xl sm:tracking-tight lg:text-4xl">
          Bienvenido a Acid Jelly
        </p>
        <p className="max-w-xl my-5 mx-auto text-xl dark:text-stone-50 text-slate-500">
          Por favor ingresa tu correo y una contraseña
        </p>
      </div>

      {!loading ? (
        <div className="shadow overflow-hidden sm:rounded-md">
          <div className="divide-y divide-gray-200">
            <form
              onSubmit={handleSubmit}
              className="flex flex-wrap items-left p-2 bg-slate-50 dark:bg-slate-300"
            >
              <div className="p-1 w-full">
                <TextField
                  type="text"
                  name="email"
                  id="email"
                  label="email"
                  variant="outlined"
                  onChange={handleInputChange}
                  value={formData.email}
                  className="w-full"
                />
              </div>

              <div className="p-1 w-full md:w-1/2">
                <TextField
                  type="password"
                  name="password1"
                  id="password1"
                  label="password"
                  variant="outlined"
                  onChange={handleInputChange}
                  value={formData.password1}
                  className="w-full"
                />
              </div>

              <div className="p-1 w-full md:w-1/2">
                <TextField
                  type="password"
                  name="password2"
                  id="password2"
                  label="repeat password"
                  variant="outlined"
                  onChange={handleInputChange}
                  value={formData.password2}
                  className="w-full"
                />
              </div>

              <div className="w-full pt-2 flex justify-end space-x-2">
                <div>
                  {user_account_state?.error && (
                    <div className="p-1 w-full text-red-500">
                      {Object.keys(user_account_state.error).map((key) => (
                        <p key={key}>
                          {key.includes("password") ? "password" : key}:{" "}
                          {user_account_state.error[key]}
                        </p>
                      ))}
                    </div>
                  )}

                  {user_account_state?.detail &&
                    user_account_state?.detail !== "" && (
                      <div className="p-1 w-full text-green-500">
                        <p>
                          {user_account_state?.detail.toLowerCase() === "ok"
                            ? "Se ha vuelto a enviar el correo de confirmación. Revisa tu bandeja de entrada o spam."
                            : user_account_state?.detail}
                        </p>
                      </div>
                    )}
                </div>
                <div>
                  <CustomButton
                    label="Enviar datos"
                    onClick={() => {}}
                    colors={["blue", "blue"]}
                    icon={<HiCheck />}
                    type="submit"
                  />
                </div>
              </div>
              <div className="p-1 flex items-center">
                {user_account_state?.detail &&
                  user_account_state?.detail !== "" && (
                    <div className="flex items-center">
                      <CustomButton
                        colors={[]}
                        icon={<HiMail />}
                        label={"Reenviar correo de confirmación"}
                        onClick={function (): void {
                          resendUserAccount(formData);
                        }}
                      />
                      <span className="mx-2">|</span>
                    </div>
                  )}

                <div>
                  <Link to="/login" className="text-blue-500 hover:underline">
                    Login
                  </Link>
                </div>
                <span className="mx-2">|</span>
                <div>
                  <Link
                    to="/password/reset"
                    className="text-blue-500 hover:underline"
                  >
                    Recuperar contraseña
                  </Link>
                </div>
              </div>
            </form>
          </div>
        </div>
      ) : (
        <LoadingFullWidth />
      )}
      <br />
    </NoAuthFullWidthLayoutLegal>
  );
};

const mapStateToProps = (state: RootState) => ({
  loading: state.web3.loading,
  account: state.web3.account,
  nextwork: state.web3.network,
  google_state: state.google_state,
  user_account_state: state.user_account_state,
  //my_user: state.user.my_user,
});

const mapDispatchToProps = {
  loginWeb3,
  postGoogle,
  registerUserAccount,
  resendUserAccount,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(Registration);
