
import { IncomeTypes, IncomeState } from '../types/income_types';
import axios from 'axios'
axios.defaults.withCredentials = true;

const initialState: IncomeState = {
    id: 0,
    amount: 0,
    description: '',
    hours: 0,
    case: null,

    incomes: [],
    error: null,
    message: '',

    tags: [],
    photo: '',
    start_at: null,
    end_at: null,
    income_type: null,
    validated: false,
    created_by: {
        access: '',
        refresh: '',
        id: 1,
        name: ''
    },
    validated_by: null,
    new_one: null
};

const IncomeReducer = (state = initialState, action: IncomeTypes) => {
    switch (action.type) {
        
        case 'POST_INCOME':
            // const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/`, {});
            // console.log(response)
            return {...state, data: []};
        case 'POST_SUCCESS_INCOME':
            return {...state, error: null, message: action.payload.message};
        case 'POST_FAIL_INCOME':
            return {...state, error: action.payload.error, message: action.payload.message};
        
        case 'PUT_INCOME':
            // const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/`, {});
            // console.log(response)
            return {...state, data: []};
        case 'PUT_SUCCESS_INCOME':
            return {...state, error: null, message: action.payload.message};
        case 'PUT_FAIL_INCOME':
            return {...state, error: action.payload.error, message: action.payload.message};
        
        case 'UPDATE_INCOME':
            // const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/`, {});
            // console.log(response)
            return {...state, data: []};
        case 'UPDATE_SUCCESS_INCOME':
            return {...state, error: null, message: action.payload.message};
        case 'UPDATE_FAIL_INCOME':
            return {...state, error: action.payload.error, message: action.payload.message};
            
        case 'DELETE_INCOME':
            // const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/`, {});
            // console.log(response)
            return {...state, data: []};
        case 'DELETE_SUCCESS_INCOME':
            return {...state, error: null, message: action.payload.message};
        case 'DELETE_FAIL_INCOME':
            return {...state, error: action.payload.error, message: action.payload.message};
        
        case 'GET_INCOME':
            // const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/`, {});
            // console.log(response)
            return {...state, incomes: action.payload.incomes};
        case 'GET_SUCCESS_INCOME':
            return {...state, error: null, message: action.payload.message};
        case 'GET_FAIL_INCOME':
            return {...state, error: action.payload.error, message: action.payload.message};
// [ANCHOR_1]
        default:
            return state;
    }
};

export default IncomeReducer;
    