
import { IncomeTypeActionTypes, IncomeTypeState } from '../types/income_type_types';
import axios from 'axios'
axios.defaults.withCredentials = true;

const initialState: IncomeTypeState = {
    id: 0,
    name: '',
    income_types: [],
    error: null,
    message: ''
};

const IncomeTypeReducer = (state = initialState, action: IncomeTypeActionTypes) => {
    switch (action.type) {
        
        case 'POST_INCOME_TYPE':
            // const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/`, {});
            // console.log(response)
            return {...state, data: []};
        case 'POST_SUCCESS_INCOME_TYPE':
            return {...state, error: null, message: action.payload.message};
        case 'POST_FAIL_INCOME_TYPE':
            return {...state, error: action.payload.error, message: action.payload.message};
        
        case 'PUT_INCOME_TYPE':
            // const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/`, {});
            // console.log(response)
            return {...state, data: []};
        case 'PUT_SUCCESS_INCOME_TYPE':
            return {...state, error: null, message: action.payload.message};
        case 'PUT_FAIL_INCOME_TYPE':
            return {...state, error: action.payload.error, message: action.payload.message};
        
        case 'UPDATE_INCOME_TYPE':
            // const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/`, {});
            // console.log(response)
            return {...state, data: []};
        case 'UPDATE_SUCCESS_INCOME_TYPE':
            return {...state, error: null, message: action.payload.message};
        case 'UPDATE_FAIL_INCOME_TYPE':
            return {...state, error: action.payload.error, message: action.payload.message};
            
        case 'DELETE_INCOME_TYPE':
            // const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/`, {});
            // console.log(response)
            return {...state, data: []};
        case 'DELETE_SUCCESS_INCOME_TYPE':
            return {...state, error: null, message: action.payload.message};
        case 'DELETE_FAIL_INCOME_TYPE':
            return {...state, error: action.payload.error, message: action.payload.message};
        
        case 'GET_INCOME_TYPE':
            // const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/`, {});
            // console.log(response)
            return {...state, income_types: action.payload.income_types};
        case 'GET_SUCCESS_INCOME_TYPE':
            return {...state, error: null, message: action.payload.message};
        case 'GET_FAIL_INCOME_TYPE':
            return {...state, error: action.payload.error, message: action.payload.message};
// [ANCHOR_1]
        case 'START_LOADING':
            return {...state, loading: true};
        case 'END_LOADING':
            return {...state, loading: false};
        default:
            return state;
    }
};

export default IncomeTypeReducer;
    