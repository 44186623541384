import React from "react";
import { Popover } from "@headlessui/react";
import { NavLink } from "react-router-dom";
import { NavBarDesktopProps } from "../types";
import NavBarPopOver from "../navbar_popover";
import { HiDatabase, HiHeart, HiLockOpen } from "react-icons/hi";
import DarkModeSwitch from "../../util/dark_mode_switch";

type Props = {
  logOut: () => void;
};

const GoodsNavBarDesktop: React.FC<Props> = (props: Props) => {
  return (
    <Popover.Group className="hidden xl:flex lg:gap-x-12">
      <NavLink
        to="/goods/profile"
        className="px-2 text-sm font-semibold leading-6 text-gray-900 dark:bg-slate-700 dark:text-slate-50"
      >
        PROFILE
      </NavLink>

      <NavBarPopOver
        name={"CATEGORIES"}
        links={[
          {
            name: "DASHBOARD",
            href: "/goods/categories",
            icon: HiDatabase,
            description: "",
          },
          {
            name: "VALIDATION",
            href: "/goods/categories/validator",
            icon: HiLockOpen,
            description: "",
          },
          {
            name: "MY CATEGORIES",
            href: "/goods/categories/my",
            icon: HiHeart,
            description: "",
          },
        ]}
      />

      <NavBarPopOver
        name={"SUB CATEGORIES"}
        links={[
          {
            name: "DASHBOARD",
            href: "/goods/sub-categories",
            icon: HiDatabase,
            description: "",
          },
          {
            name: "VALIDATOR",
            href: "/goods/sub-categories/validator",
            icon: HiLockOpen,
            description: "",
          },
          {
            name: "MY SUB CATEGORIES",
            href: "/goods/sub-categories/my",
            icon: HiHeart,
            description: "",
          },
        ]}
      />

      <NavBarPopOver
        name={"MERCHANDISE"}
        links={[
          {
            name: "DASHBOARD",
            href: "/goods/merchandise",
            icon: HiDatabase,
            description: "",
          },
          {
            name: "VALIDATOR",
            href: "/goods/merchandise/validator",
            icon: HiLockOpen,
            description: "",
          },
          {
            name: "MY SUB CATEGORIES",
            href: "/goods/merchandise/my",
            icon: HiHeart,
            description: "",
          },
        ]}
      />

      <NavBarPopOver
        name={"PRODUCTS"}
        links={[
          {
            name: "DASHBOARD",
            href: "/goods/products",
            icon: HiDatabase,
            description: "",
          },
          {
            name: "VALIDATOR",
            href: "/goods/products/validator",
            icon: HiLockOpen,
            description: "",
          },
          {
            name: "MY SUB CATEGORIES",
            href: "/goods/products/my",
            icon: HiHeart,
            description: "",
          },
        ]}
      />

      <a
        href={`${process.env.REACT_APP_API_URL}/admin`}
        target="_blank"
        className="text-sm font-semibold leading-6 text-gray-900 dark:bg-slate-700 dark:text-slate-50"
        rel="noreferrer"
      >
        Ir al admin
      </a>

      <div
        onClick={() => props.logOut()}
        className="hidden lg:block px-2 text-sm font-semibold leading-6 text-gray-900 dark:bg-slate-700 dark:text-slate-50"
      >
        Log out <span aria-hidden="true">&rarr;</span>
      </div>
    </Popover.Group>
  );
};

export default GoodsNavBarDesktop;
