import FullWidthLayout from "../../../../layouts/full_width_layout";
import { useEffect, useState } from "react";
import { RootState } from "../../../../store";
import { ConnectedProps, connect } from "react-redux";
import Dropzone from "./components/drop_zone";
import { HiUpload } from "react-icons/hi";
import { GoodsRequest } from "../../redux/types/goods_types";
import {
  GoodsCategoryRequest,
  GoodsSubCategoryRequest,
} from "../../redux/types/merchant_types";
import { GoodsUploadProduct } from "../../redux/actions/goods_action";

const GoodsMerchandise: React.FC<PropsFromRedux> = ({
  goods_state,
  GoodsUploadProduct,
}: PropsFromRedux) => {
  const [selected_category, set_selected_category] =
    useState<GoodsCategoryRequest | null>(null);
  const [selected_sub_categories, set_selected_sub_categories] = useState<
    GoodsSubCategoryRequest[]
  >([]);

  const initialRequestData: GoodsRequest = {
    id: "",
    name: "",
  };

  const [formData, setFormData] = useState<GoodsRequest>(initialRequestData);

  // useEffect(() => {
  //   if (!goods_state) return;
  //   console.log("goods_state:", goods_state);
  //   set_folders(goods_state?.folders);
  //   set_documents(goods_state?.documents);
  // }, [goods_state?.folders, goods_state?.documents]);

  useEffect(() => {
    const onInit = async () => {
      try {
        // await GoodsGetGoods({});
        // await GoodsGetGoods({});

        console.log("Documents");
      } catch (error) {
        console.error("Error fetching data: ", error);
      } finally {
        // setLoading(false);
      }
    };

    onInit();
  }, []);

  const handleOnDrop = async () => {
    // if (goods_state?.new_ones && goods_state?.new_ones.length > 0) {
    // }
  };

  const handlePostProducts = async (document: GoodsRequest, files: File[]) => {
    document.fk_id = selected_category?.id!;
    await GoodsUploadProduct(document, files);
  };

  const handleUpload = async () => {
    console.log("handleUpload");
  };

  return (
    <FullWidthLayout>
      <div className="text-slate-700 dark:text-stone-50 fixed w-full bottom-0 left-0 flex flex-row dark:bg-slate-700 bg-stone-50 z-10 items-center justify-center px-3">
        {selected_category && selected_category.id !== "" ? (
          <div className="p-1">
            <Dropzone
              onDrop={handleOnDrop}
              colors={[]}
              icon={<HiUpload />}
              label={""}
              folder_id={selected_category ? selected_category.id : ""}
              handleUpload={handleUpload}
              the_photos={goods_state?.the_photos || []}
              postDocument={handlePostProducts}
            />
          </div>
        ) : (
          <></>
        )}
      </div>

      <div className="w-full p-2"></div>
    </FullWidthLayout>
  );
};

const mapStateToProps = (state: RootState) => ({
  goods_state: state.goods_state,
  // document_new_ones: document_new_ones(state),
});

const mapDispatchToProps = {
  GoodsUploadProduct,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(GoodsMerchandise);
