
import { Dispatch } from 'redux';
import { OutcomeTypes, OutcomeState } from '../types/outcome_types';

export const postOutcome = (data: OutcomeState) => (dispatch: Dispatch<OutcomeTypes>) => {{
    try {
        dispatch({ type: 'POST_OUTCOME', payload: data });
        
        dispatch({ type: 'POST_SUCCESS_OUTCOME', payload: {...data, error: null, message: "POST_SUCCESS"} });
    } catch (error) {
        dispatch({ type: 'POST_FAIL_OUTCOME', payload: {...data, error: error, message: "POST_FAIL"} });
    }
}};

export const putOutcome = (data: OutcomeState) => (dispatch: Dispatch<OutcomeTypes>) => {{
    try {
        dispatch({ type: 'PUT_OUTCOME', payload: data });
        
        dispatch({ type: 'PUT_SUCCESS_OUTCOME', payload: {...data, error: null, message: "PUT_SUCCESS"} });
    } catch (error) {
        dispatch({ type: 'PUT_FAIL_OUTCOME', payload: {...data, error: error, message: "PUT_FAIL"} });
    }
}};

export const updateOutcome = (data: OutcomeState) => (dispatch: Dispatch<OutcomeTypes>) => {{
    try {
        dispatch({ type: 'UPDATE_OUTCOME', payload: data });
        
        dispatch({ type: 'UPDATE_SUCCESS_OUTCOME', payload: {...data, error: null, message: "UPDATE_SUCCESS"} });
    } catch (error) {
        dispatch({ type: 'UPDATE_FAIL_OUTCOME', payload: {...data, error: error, message: "UPDATE_FAIL"} });
    }
}};

export const deleteOutcome = (data: OutcomeState) => (dispatch: Dispatch<OutcomeTypes>) => {{
    try {
        dispatch({ type: 'DELETE_OUTCOME', payload: data });
        
        dispatch({ type: 'DELETE_SUCCESS_OUTCOME', payload: {...data, error: null, message: "DELETE_SUCCESS"} });
    } catch (error) {
        dispatch({ type: 'DELETE_FAIL_OUTCOME', payload: {...data, error: error, message: "DELETE_FAIL"} });
    }
}};

export const getOutcome = (data: OutcomeState) => async (dispatch: Dispatch<OutcomeTypes>) => {{
    try {
        dispatch({ type: 'GET_OUTCOME', payload: data });
        
        dispatch({ type: 'GET_SUCCESS_OUTCOME', payload: {...data, error: null, message: "GET_SUCCESS"} });
    } catch (error) {
        dispatch({ type: 'GET_FAIL_OUTCOME', payload: {...data, error: error, message: "GET_FAIL"} });
    }
}};
// [ANCHOR_1]
    