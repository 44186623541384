import { SET_ALERT, REMOVE_ALERT } from "../../../redux/actions/types";

const initialState = {
    alert: null
}

export default function alert(state=initialState, action:any) {
    const { type, payload } = action;

    switch (type) {
        case SET_ALERT:
            return {
                ...state,
                alert: payload
            }
        case REMOVE_ALERT:
            return {
                ...state,
                alert: null
            }
        default:
            return state;
    }
}