
import { act } from 'react-dom/test-utils';
import { GroupActionTypes, GroupState } from '../types/group_types';
import axios from 'axios'
axios.defaults.withCredentials = true;

const initialState: GroupState = {
    id: 0,
    name: '',
    groups: [],
    data: [],
    error: null,
    message: ''
};

const GroupReducer = (state = initialState, action: GroupActionTypes) => {
    switch (action.type) {
        
        case 'POST_GROUP':
            // const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/`, {});
            // console.log(response)
            return {...state, data: []};
        case 'POST_SUCCESS_GROUP':
            return {...state, error: null, message: action.payload.message};
        case 'POST_FAIL_GROUP':
            return {...state, error: action.payload.error, message: action.payload.message};
        
        case 'PUT_GROUP':
            // const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/`, {});
            // console.log(response)
            return {...state, data: []};
        case 'PUT_SUCCESS_GROUP':
            return {...state, error: null, message: action.payload.message};
        case 'PUT_FAIL_GROUP':
            return {...state, error: action.payload.error, message: action.payload.message};
        
        case 'UPDATE_GROUP':
            // const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/`, {});
            // console.log(response)
            return {...state, data: []};
        case 'UPDATE_SUCCESS_GROUP':
            return {...state, error: null, message: action.payload.message};
        case 'UPDATE_FAIL_GROUP':
            return {...state, error: action.payload.error, message: action.payload.message};
            
        case 'DELETE_GROUP':
            // const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/`, {});
            // console.log(response)
            return {...state, data: []};
        case 'DELETE_SUCCESS_GROUP':
            return {...state, error: null, message: action.payload.message};
        case 'DELETE_FAIL_GROUP':
            return {...state, error: action.payload.error, message: action.payload.message};
        
        case 'GET_GROUP':
            // const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/`, {});
            // console.log(response)
            return {...state, groups: action.payload.groups};
        case 'GET_SUCCESS_GROUP':
            return {...state, error: null, message: action.payload.message};
        case 'GET_FAIL_GROUP':
            return {...state, error: action.payload.error, message: action.payload.message};
// [ANCHOR_1]
        case 'START_LOADING':
            return {...state, loading: true};
        case 'END_LOADING':
            return {...state, loading: false};
        default:
            return state;
    }
};

export default GroupReducer;
    