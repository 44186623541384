import { Dispatch } from "redux";
import {
  InstitutionActionTypes,
  InstitutionState,
} from "../types/institution_types";
import axios from "axios";
axios.defaults.withCredentials = true;

export const postInstitution =
  (data: InstitutionState) => (dispatch: Dispatch<InstitutionActionTypes>) => {
    {
      try {
        dispatch({
          type: "START_LOADING",
          payload: { ...data, error: null, message: "START_LOADING" },
        });
        dispatch({ type: "POST_INSTITUTION", payload: data });

        dispatch({
          type: "POST_SUCCESS_INSTITUTION",
          payload: {
            ...data,
            error: null,
            message: "POST_INSTITUTION_SUCCESS",
          },
        });
        dispatch({
          type: "END_LOADING",
          payload: { ...data, error: null, message: "END_LOADING" },
        });
      } catch (error) {
        dispatch({
          type: "POST_FAIL_INSTITUTION",
          payload: { ...data, error: error, message: "POST_INSTITUTION_FAIL" },
        });
        dispatch({
          type: "END_LOADING",
          payload: { ...data, error: null, message: "END_LOADING" },
        });
      }
    }
  };

export const putInstitution =
  (data: InstitutionState) => (dispatch: Dispatch<InstitutionActionTypes>) => {
    {
      try {
        dispatch({
          type: "START_LOADING",
          payload: { ...data, error: null, message: "START_LOADING" },
        });

        dispatch({ type: "PUT_INSTITUTION", payload: data });

        dispatch({
          type: "PUT_SUCCESS_INSTITUTION",
          payload: { ...data, error: null, message: "PUT_INSTITUTION_SUCCESS" },
        });
        dispatch({
          type: "END_LOADING",
          payload: { ...data, error: null, message: "END_LOADING" },
        });
      } catch (error) {
        dispatch({
          type: "PUT_FAIL_INSTITUTION",
          payload: { ...data, error: error, message: "PUT_INSTITUTION_FAIL" },
        });
        dispatch({
          type: "END_LOADING",
          payload: { ...data, error: null, message: "END_LOADING" },
        });
      }
    }
  };

export const updateInstitution =
  (data: InstitutionState) => (dispatch: Dispatch<InstitutionActionTypes>) => {
    {
      try {
        dispatch({
          type: "START_LOADING",
          payload: { ...data, error: null, message: "START_LOADING" },
        });

        dispatch({ type: "UPDATE_INSTITUTION", payload: data });

        dispatch({
          type: "UPDATE_SUCCESS_INSTITUTION",
          payload: {
            ...data,
            error: null,
            message: "UPDATE_INSTITUTION_SUCCESS",
          },
        });
        dispatch({
          type: "END_LOADING",
          payload: { ...data, error: null, message: "END_LOADING" },
        });
      } catch (error) {
        dispatch({
          type: "UPDATE_FAIL_INSTITUTION",
          payload: {
            ...data,
            error: error,
            message: "UPDATE_INSTITUTION_FAIL",
          },
        });
        dispatch({
          type: "END_LOADING",
          payload: { ...data, error: null, message: "END_LOADING" },
        });
      }
    }
  };

export const deleteInstitution =
  (data: InstitutionState) => (dispatch: Dispatch<InstitutionActionTypes>) => {
    {
      try {
        dispatch({
          type: "START_LOADING",
          payload: { ...data, error: null, message: "START_LOADING" },
        });

        dispatch({ type: "DELETE_INSTITUTION", payload: data });

        dispatch({
          type: "DELETE_SUCCESS_INSTITUTION",
          payload: {
            ...data,
            error: null,
            message: "DELETE_INSTITUTION_SUCCESS",
          },
        });
        dispatch({
          type: "END_LOADING",
          payload: { ...data, error: null, message: "END_LOADING" },
        });
      } catch (error) {
        dispatch({
          type: "DELETE_FAIL_INSTITUTION",
          payload: {
            ...data,
            error: error,
            message: "DELETE_INSTITUTION_FAIL",
          },
        });
        dispatch({
          type: "END_LOADING",
          payload: { ...data, error: null, message: "END_LOADING" },
        });
      }
    }
  };

export const getInstitution =
  (data: InstitutionState) =>
  async (dispatch: Dispatch<InstitutionActionTypes>) => {
    {
      try {
        dispatch({
          type: "START_LOADING",
          payload: { ...data, error: null, message: "START_LOADING" },
        });

        const institutions = await axios.post(
          `${process.env.REACT_APP_API_URL}/api/lawyer/general-data`,
          { id: data.id, search: "institution" }
        );
        dispatch({ type: "GET_INSTITUTION", payload: institutions.data });

        dispatch({
          type: "GET_SUCCESS_INSTITUTION",
          payload: { ...data, error: null, message: "GET_INSTITUTION_SUCCESS" },
        });
        dispatch({
          type: "END_LOADING",
          payload: { ...data, error: null, message: "END_LOADING" },
        });
      } catch (error) {
        dispatch({
          type: "GET_FAIL_INSTITUTION",
          payload: { ...data, error: error, message: "GET_INSTITUTION_FAIL" },
        });
        dispatch({
          type: "END_LOADING",
          payload: { ...data, error: null, message: "END_LOADING" },
        });
      }
    }
  };
// [ANCHOR_1]
