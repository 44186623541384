
import { SecondStatusActionTypes, SecondStatusState } from '../types/second_status_types';
import axios from 'axios'
axios.defaults.withCredentials = true;

const initialState: SecondStatusState = {
    id: 0,
    name: '',
    data: [],
    error: null,
    message: '',
    second_status_list: [],
    second_status: []
};

const SecondStatusReducer = (state = initialState, action: SecondStatusActionTypes) => {
    switch (action.type) {
        
        case 'POST_SECOND_STATUS':
            // const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/`, {});
            // console.log(response)
            return {...state, data: []};
        case 'POST_SUCCESS_SECOND_STATUS':
            return {...state, error: null, message: action.payload.message};
        case 'POST_FAIL_SECOND_STATUS':
            return {...state, error: action.payload.error, message: action.payload.message};
        
        case 'PUT_SECOND_STATUS':
            // const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/`, {});
            // console.log(response)
            return {...state, data: []};
        case 'PUT_SUCCESS_SECOND_STATUS':
            return {...state, error: null, message: action.payload.message};
        case 'PUT_FAIL_SECOND_STATUS':
            return {...state, error: action.payload.error, message: action.payload.message};
        
        case 'UPDATE_SECOND_STATUS':
            // const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/`, {});
            // console.log(response)
            return {...state, data: []};
        case 'UPDATE_SUCCESS_SECOND_STATUS':
            return {...state, error: null, message: action.payload.message};
        case 'UPDATE_FAIL_SECOND_STATUS':
            return {...state, error: action.payload.error, message: action.payload.message};
            
        case 'DELETE_SECOND_STATUS':
            // const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/`, {});
            // console.log(response)
            return {...state, data: []};
        case 'DELETE_SUCCESS_SECOND_STATUS':
            return {...state, error: null, message: action.payload.message};
        case 'DELETE_FAIL_SECOND_STATUS':
            return {...state, error: action.payload.error, message: action.payload.message};
        
        case 'GET_SECOND_STATUS':
            // const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/`, {});
            // console.log(response)
            return {...state, second_status: action.payload.second_status};
        case 'GET_SUCCESS_SECOND_STATUS':
            return {...state, error: null, message: action.payload.message};
        case 'GET_FAIL_SECOND_STATUS':
            return {...state, error: action.payload.error, message: action.payload.message};
// [ANCHOR_1]
        case 'START_LOADING':
            return {...state, loading: true};
        case 'END_LOADING':
            return {...state, loading: false};
        default:
            return state;
    }
};

export default SecondStatusReducer;
    