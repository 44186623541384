import React, { useEffect } from 'react'
import { Bar, Line } from 'react-chartjs-2';

type Props = {
    incomesByMonth: number[], 
    outcomesByMonth: number[]
}

export default function MonthlyLinearChart(props: Props) {

    const data = {
        labels: ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'],
        datasets: [
            {
            label: 'Ingresos',
            data: props.incomesByMonth,
            backgroundColor: 'rgba(75, 192, 192, 0.6)',  // Color de fondo para ingresos
            borderColor: 'rgba(75, 192, 192, 1)',        // Color del borde para ingresos
            borderWidth: 1
            },
            {
            label: 'Gastos',
            data: props.outcomesByMonth,
            backgroundColor: 'rgba(255, 99, 132, 0.6)',  // Color de fondo para gastos
            borderColor: 'rgba(255, 99, 132, 1)',        // Color del borde para gastos
            borderWidth: 1
            }
        ]
    };

    return props.incomesByMonth.find(x => x > 0) && props.incomesByMonth.find(x => x > 0) ? <Line data={data} /> : (<></>);
    
}