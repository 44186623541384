
import { StatusActionTypes, StatusState } from '../types/status_types';
import axios from 'axios'
axios.defaults.withCredentials = true;

const initialState: StatusState = {
    id: 0,
    name: '',
    status: [],
    error: null,
    message: '',
    searched: {
        id: 0,
        name: ''
    },
    status_list: []
};

const StatusReducer = (state = initialState, action: StatusActionTypes) => {
    switch (action.type) {
        
        case 'POST_STATUS':
            // const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/`, {});
            // console.log(response)
            debugger
            return {...state, status_list: action.payload.status_list};
        case 'POST_SUCCESS_STATUS':
            return {...state, error: null, message: action.payload.message};
        case 'POST_FAIL_STATUS':
            return {...state, error: action.payload.error, message: action.payload.message};
        
        case 'PUT_STATUS':
            // const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/`, {});
            // console.log(response)
            return {...state, data: []};
        case 'PUT_SUCCESS_STATUS':
            return {...state, error: null, message: action.payload.message};
        case 'PUT_FAIL_STATUS':
            return {...state, error: action.payload.error, message: action.payload.message};
        
        case 'UPDATE_STATUS':
            // const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/`, {});
            // console.log(response)
            return {...state, data: []};
        case 'UPDATE_SUCCESS_STATUS':
            return {...state, error: null, message: action.payload.message};
        case 'UPDATE_FAIL_STATUS':
            return {...state, error: action.payload.error, message: action.payload.message};
            
        case 'DELETE_STATUS':
            // const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/`, {});
            // console.log(response)
            return {...state, data: []};
        case 'DELETE_SUCCESS_STATUS':
            return {...state, error: null, message: action.payload.message};
        case 'DELETE_FAIL_STATUS':
            return {...state, error: action.payload.error, message: action.payload.message};
        
        case 'GET_STATUS':
            // const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/`, {});
            // console.log(response)
            return {...state, status: action.payload.status_list};
        case 'GET_SUCCESS_STATUS':
            return {...state, error: null, message: action.payload.message};
        case 'GET_FAIL_STATUS':
            return {...state, error: action.payload.error, message: action.payload.message};
// [ANCHOR_1]
        case 'START_LOADING':
            return {...state, loading: true};
        case 'END_LOADING':
            return {...state, loading: false};
        default:
            return state;
    }
};

export default StatusReducer;
    