import { ConnectedProps, connect } from "react-redux";
import FullWidthLayout from "../../layouts/full_width_layout";
import { RootState } from "../../store";
import { getOfficeObject, postOfficeObject, selectOfficeObject } from "./redux/actions/office_object_action";
import { get_office_objects, selected_office_object, selected_office_object_id } from "./redux/selectors";
import { useEffect, useState } from "react";
import { CustomButton } from "../../core/components/buttons/custom_button";
import { HiCloudDownload, HiCloudUpload } from "react-icons/hi";
import TrackingDropzone from "./components/tracking_drop_zone";
import { OfficeObjectRequest } from "./redux/types/office_object_types";
import { DocumentRequest } from "../documents/redux/types/document_types";
import { getSeat } from "./redux/actions/seat_action";
import Select from "react-select";
import { SeatRequest } from "./redux/types/seat_types";

const Tracking: React.FC<PropsFromRedux> = ({
  getOfficeObject,
  selectOfficeObject,
  postOfficeObject,
  getSeat,
  _selected_office_object_id,
  loading,
  // Map your state to props if needed
  _office_objects,
  _selected_office_object,
  _seats
}: PropsFromRedux) => {
  useEffect(() => {
    const init = async () => {
      await getOfficeObject({
        id: 0,
        name: ""
      });
    }
    init();
  }, []);

  function handleDownloadDocument(_document: DocumentRequest): void {
    // Create a temporary anchor element
    const link = document.createElement('a');
    link.href = `${process.env.REACT_APP_API_URL}/${_document.file}`;
    if (_document.name) {
      link.download = _document.name;
      link.click();
    } else {
      link.remove();
    }
  }

  const getOptionValue = (option:any) => option.id;
  const getOptionLabel = (option:any) => option.name;

  const initialRequestData: SeatRequest = {
    id: 0,
    name: ""
  }

  const [formData, setFormData] = useState<SeatRequest>(initialRequestData);

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setFormData(prevState => ({
      ...prevState,
      [name]: value
    }));
  };

  const handleSelectChange = (fieldName: keyof SeatRequest) => (selectedOptions: any) => {
    setFormData({
      ...formData,
      [fieldName]: selectedOptions,
    });
  };

  return (
    <FullWidthLayout>
      <div className='bg-stone-200 dark:bg-slate-600 rounded p-3 flex flex-wrap item text-slate-700 dark:text-stone-50'>
        <div className="w-full">
          <p className='text-2xl'>Tracking</p>
        </div>
        <div className="w-full flex items-center flex-wrap">
          <div className="w-1/3 p-1">
            <p className='text-sm'>Mis Objetos de la oficina</p>
            <div className='p-1 w-1/2'>
              <label>Sede</label>
              <Select 
                id='seat'
                placeholder="Sede"
                key={_seats?.length}
                options={_seats} 
                formatOptionLabel={(data:SeatRequest)=>{return data.name}} 
                onChange={handleSelectChange("id")} 
                value={formData} 
                getOptionValue={getOptionValue}
                getOptionLabel={getOptionLabel}
                className="w-full" 
              />
            </div>
            <div className="w-full max-h-40 md:max-h-96 overflow-auto">
            {
              _office_objects?.map((office_object, index) => (
              <div key={index} onClick={() => selectOfficeObject(office_object)} className='hover:bg-stone-300 flex flex-col items-center justify-between border border-gray-300 rounded-md p-2 mt-2'>
                <p className='text-sm'>{office_object.name}</p>
                <p className='text-sm'>{office_object.description}</p>
                <p className='text-sm'>{office_object.verified}</p>
                <p className='text-sm font-bold w-full text-left p-2 border-gray-400 border-2'>SEDE : {office_object.seat?.name}</p>
              </div>
              ))
            }
            </div>
          </div>
          <div className="w-2/3 p-1">
            {_selected_office_object && (
              <p className="text-sm"><strong>Status: </strong>{_selected_office_object?.verified ? 'verificado':'falta verificar'}</p>
            )}
            <div className="flex flex-wrap items-center justify-center w-full max-h-40 md:max-h-96 overflow-auto">
              <div className="w-full">
              {
                (_selected_office_object && !_selected_office_object?.verified) && (
                  <>
                    <TrackingDropzone 
                    office_object_id={_selected_office_object_id!} 
                    colors={[]} 
                    icon={<HiCloudUpload/>} 
                    label={"Subir evidencia"} 
                    onDrop={function (): void {
                      console.log('tracking onDrop')
                    } } 
                    handleUpload={function (): void {
                      console.log('tracking handleUpload')
                    } } 
                    onDeleteItem={function (item: OfficeObjectRequest): void {
                      console.log('tracking onDeleteItem')
                    } } 
                    postOfficeObject={postOfficeObject} 
                    the_documents={[]}/>
                  </>
                )
              }
              </div>
              {
                !_selected_office_object && (
                  <>Seleccione uno de sus equipos para comenzar.</>
                )
              }
              <div className="w-full">
              Evidencia subida :
              </div>
              {(_selected_office_object && _selected_office_object.documents?.length || 0 > 0)? _selected_office_object?.documents?.map((document, index) => (
                <div key={index} className='flex items-center justify-between border border-gray-300 rounded-md p-2 mt-2'>
                  <p className='text-sm p-2'>{document.name}</p>
                  <p className='text-sm'>{document.description}</p>
                  <CustomButton
                    onClick={() => { handleDownloadDocument(document) } } colors={[]} icon={<HiCloudDownload/>} label={""}                />
                </div>
              )) : _selected_office_object? <>Aun no tienes fotos o archivos debes subirlas antes de las 24h de recibir tus equipos o te llegara una memorando.</>:<></>}
            </div>
          </div>
          <div className="w-full mt-3">
            <p>Tenga cuidado ya que no podra borrar las fotos despues de agregarlas</p>
          </div>
        </div>
      </div>
    </FullWidthLayout>
  )
}

const mapStateToProps = (state: RootState) => ({
  loading: state.office_object_state?.loading,
  // Map your state to props if needed
  _seats: state.seat_state?.seats,
  _office_objects: get_office_objects(state),
  _selected_office_object: selected_office_object(state),
  _selected_office_object_id: selected_office_object_id(state)
});

const mapDispatchToProps = {
  getOfficeObject,
  selectOfficeObject,
  postOfficeObject,
  getSeat
  // Other actions...
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(Tracking);