import { Dispatch } from "redux";
import {
  GeneralDataActionTypes,
  GeneralDataRequest,
  GeneralDataState,
} from "../types/general_data_types";
import { TagActionTypes } from "../types/tag_types";
import { CategoryActionTypes } from "../types/category_types";
import { TargetActionTypes } from "../types/target_types";
import { SubjectActionTypes } from "../types/subject_types";
import { StatusActionTypes } from "../types/status_types";
import { OutcomeTypeActionTypes } from "../../../pages/treasury/redux/types/outcome_type_types";
import { IncomeTypeActionTypes } from "../../../pages/treasury/redux/types/income_type_types";
import { ContactActionTypes } from "../../../pages/directory/redux/types/contact_types";
import { InstitutionActionTypes } from "../types/institution_types";
import { SecondStatusActionTypes } from "../types/second_status_types";
import { UserAccountOfficeGroupActionTypes } from "../../../pages/auth/redux/types/user_account_office_group_types";
import axios from "axios";
axios.defaults.withCredentials = true;

export const postGeneralData =
  (data: GeneralDataRequest) =>
  async (dispatch: Dispatch<GeneralDataActionTypes>) => {
    {
      try {
        dispatch({
          type: "START_LOADING",
          payload: { ...data, error: null, message: "START_LOADING" },
        });
        const response = await axios.post(
          `${process.env.REACT_APP_API_URL}/api/lawyer/general-data`,
          data
        );
        dispatch({ type: "POST_GENERAL_DATA", payload: response.data });
        dispatch({
          type: "END_LOADING",
          payload: { ...data, error: null, message: "END_LOADING" },
        });
        dispatch({
          type: "POST_SUCCESS_GENERAL_DATA",
          payload: {
            ...response.data,
            error: null,
            message: "POST_GENERAL_DATA_SUCCESS",
          },
        });
      } catch (error) {
        dispatch({
          type: "END_LOADING",
          payload: { ...data, error: null, message: "END_LOADING" },
        });
        dispatch({
          type: "POST_FAIL_GENERAL_DATA",
          payload: { ...data, error: error, message: "POST_GENERAL_DATA_FAIL" },
        });
      }
    }
  };

export const putGeneralData =
  (data: GeneralDataRequest) =>
  async (dispatch: Dispatch<GeneralDataActionTypes>) => {
    {
      try {
        dispatch({
          type: "START_LOADING",
          payload: { ...data, error: null, message: "START_LOADING" },
        });
        const response = await axios.put(
          `${process.env.REACT_APP_API_URL}/api/lawyer/general-data?id=${data.id}&name=${data.name}`
        );
        dispatch({ type: "PUT_GENERAL_DATA", payload: response.data });
        dispatch({
          type: "END_LOADING",
          payload: { ...data, error: null, message: "END_LOADING" },
        });
        dispatch({
          type: "PUT_SUCCESS_GENERAL_DATA",
          payload: {
            ...response.data,
            error: null,
            message: "PUT_GENERAL_DATA_SUCCESS",
          },
        });
      } catch (error) {
        dispatch({
          type: "END_LOADING",
          payload: { ...data, error: null, message: "END_LOADING" },
        });
        dispatch({
          type: "PUT_FAIL_GENERAL_DATA",
          payload: { ...data, error: error, message: "PUT_GENERAL_DATA_FAIL" },
        });
      }
    }
  };

export const updateGeneralData =
  (data: GeneralDataRequest) =>
  async (dispatch: Dispatch<GeneralDataActionTypes>) => {
    {
      try {
        dispatch({
          type: "START_LOADING",
          payload: { ...data, error: null, message: "START_LOADING" },
        });
        const response = await axios.put(
          `${process.env.REACT_APP_API_URL}/api/lawyer/general-data?id=${data.id}&name=${data.name}`
        );
        dispatch({ type: "UPDATE_GENERAL_DATA", payload: response.data });
        dispatch({
          type: "END_LOADING",
          payload: { ...data, error: null, message: "END_LOADING" },
        });
        dispatch({
          type: "UPDATE_SUCCESS_GENERAL_DATA",
          payload: {
            ...response.data,
            error: null,
            message: "UPDATE_GENERAL_DATA_SUCCESS",
          },
        });
      } catch (error) {
        dispatch({
          type: "END_LOADING",
          payload: { ...data, error: null, message: "END_LOADING" },
        });
        dispatch({
          type: "UPDATE_FAIL_GENERAL_DATA",
          payload: {
            ...data,
            error: error,
            message: "UPDATE_GENERAL_DATA_FAIL",
          },
        });
      }
    }
  };

export const deleteGeneralData =
  (data: GeneralDataRequest) =>
  async (dispatch: Dispatch<GeneralDataActionTypes>) => {
    {
      try {
        dispatch({
          type: "START_LOADING",
          payload: { ...data, error: null, message: "START_LOADING" },
        });
        const response = await axios.delete(
          `${process.env.REACT_APP_API_URL}/api/lawyer/general-data?id=${data.id}&name=${data.name}`
        );
        dispatch({ type: "DELETE_GENERAL_DATA", payload: response.data });
        dispatch({
          type: "END_LOADING",
          payload: { ...data, error: null, message: "END_LOADING" },
        });
        dispatch({
          type: "DELETE_SUCCESS_GENERAL_DATA",
          payload: {
            ...response.data,
            error: null,
            message: "DELETE_GENERAL_DATA_SUCCESS",
          },
        });
      } catch (error) {
        dispatch({
          type: "END_LOADING",
          payload: { ...data, error: null, message: "END_LOADING" },
        });
        dispatch({
          type: "DELETE_FAIL_GENERAL_DATA",
          payload: {
            ...data,
            error: error,
            message: "DELETE_GENERAL_DATA_FAIL",
          },
        });
      }
    }
  };

export const getGeneralData =
  (data: GeneralDataRequest) =>
  async (
    dispatch: Dispatch<
      | GeneralDataActionTypes
      | TagActionTypes
      | CategoryActionTypes
      | TargetActionTypes
      | SubjectActionTypes
      | StatusActionTypes
      | IncomeTypeActionTypes
      | OutcomeTypeActionTypes
      | ContactActionTypes
      | InstitutionActionTypes
      | SecondStatusActionTypes
      | UserAccountOfficeGroupActionTypes
    >
  ) => {
    {
      try {
        dispatch({
          type: "START_LOADING",
          payload: { ...data, error: null, message: "START_LOADING" },
        });
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/api/lawyer/general-data?id=${data.id}&name=${data.name}`
        );
        dispatch({
          type: "GET_TAG",
          payload: { id: 0, name: "", tags: response.data.tags },
        });
        dispatch({
          type: "GET_CATEGORY",
          payload: { id: 0, name: "", categories: response.data.categories },
        });
        dispatch({
          type: "GET_TARGET",
          payload: { id: 0, name: "", targets: response.data.targets },
        });
        dispatch({
          type: "GET_SUBJECT",
          payload: { id: 0, name: "", subjects: response.data.subjects },
        });
        dispatch({
          type: "GET_STATUS",
          payload: {
            id: 0,
            name: "",
            status_list: response.data.status,
            status: response.data.status,
            searched: null,
          },
        });
        dispatch({
          type: "GET_INCOME_TYPE",
          payload: {
            id: 0,
            name: "",
            income_types: response.data.income_types,
          },
        });
        dispatch({
          type: "GET_OUTCOME_TYPE",
          payload: {
            id: 0,
            name: "",
            outcome_types: response.data.outcome_types,
          },
        });
        dispatch({
          type: "GET_CONTACT",
          payload: { id: 0, name: "", contacts: response.data.contacts },
        });
        dispatch({
          type: "GET_INSTITUTION",
          payload: {
            id: 0,
            name: "",
            data: [],
            institutions: response.data.institutions,
          },
        });
        dispatch({
          type: "GET_SECOND_STATUS",
          payload: {
            id: 0,
            name: "",
            data: [],
            second_status_list: response.data.second_status,
            second_status: response.data.second_status,
          },
        });
        dispatch({
          type: "GET_USER_ACCOUNT_OFFICE_GROUP",
          payload: {
            id: 0,
            name: "",
            data: [],
            user_account_office_groups:
              response.data.user_account_office_groups,
          },
        });
        dispatch({ type: "GET_GENERAL_DATA", payload: response.data });

        dispatch({
          type: "END_LOADING",
          payload: { ...data, error: null, message: "END_LOADING" },
        });
        dispatch({
          type: "GET_SUCCESS_GENERAL_DATA",
          payload: {
            ...response.data,
            error: null,
            message: "GET_GENERAL_DATA_SUCCESS",
          },
        });
      } catch (error) {
        dispatch({
          type: "END_LOADING",
          payload: { ...data, error: null, message: "END_LOADING" },
        });
        dispatch({
          type: "GET_FAIL_GENERAL_DATA",
          payload: { ...data, error: error, message: "GET_GENERAL_DATA_FAIL" },
        });
      }
    }
  };
// [ANCHOR_1]
