import { Dispatch } from "redux";
import {
  SecondStatusActionTypes,
  SecondStatusState,
} from "../types/second_status_types";
import axios from "axios";
axios.defaults.withCredentials = true;

export const postSecondStatus =
  (data: SecondStatusState) =>
  (dispatch: Dispatch<SecondStatusActionTypes>) => {
    {
      try {
        dispatch({
          type: "START_LOADING",
          payload: { ...data, error: null, message: "START_LOADING" },
        });

        dispatch({ type: "POST_SECOND_STATUS", payload: data });

        dispatch({
          type: "POST_SUCCESS_SECOND_STATUS",
          payload: {
            ...data,
            error: null,
            message: "POST_SECOND_STATUS_SUCCESS",
          },
        });
        dispatch({
          type: "END_LOADING",
          payload: { ...data, error: null, message: "END_LOADING" },
        });
      } catch (error) {
        dispatch({
          type: "POST_FAIL_SECOND_STATUS",
          payload: {
            ...data,
            error: error,
            message: "POST_SECOND_STATUS_FAIL",
          },
        });
        dispatch({
          type: "END_LOADING",
          payload: { ...data, error: null, message: "END_LOADING" },
        });
      }
    }
  };

export const putSecondStatus =
  (data: SecondStatusState) =>
  (dispatch: Dispatch<SecondStatusActionTypes>) => {
    {
      try {
        dispatch({
          type: "START_LOADING",
          payload: { ...data, error: null, message: "START_LOADING" },
        });

        dispatch({ type: "PUT_SECOND_STATUS", payload: data });

        dispatch({
          type: "PUT_SUCCESS_SECOND_STATUS",
          payload: {
            ...data,
            error: null,
            message: "PUT_SECOND_STATUS_SUCCESS",
          },
        });
        dispatch({
          type: "END_LOADING",
          payload: { ...data, error: null, message: "END_LOADING" },
        });
      } catch (error) {
        dispatch({
          type: "PUT_FAIL_SECOND_STATUS",
          payload: { ...data, error: error, message: "PUT_SECOND_STATUS_FAIL" },
        });
        dispatch({
          type: "END_LOADING",
          payload: { ...data, error: null, message: "END_LOADING" },
        });
      }
    }
  };

export const updateSecondStatus =
  (data: SecondStatusState) =>
  (dispatch: Dispatch<SecondStatusActionTypes>) => {
    {
      try {
        dispatch({
          type: "START_LOADING",
          payload: { ...data, error: null, message: "START_LOADING" },
        });

        dispatch({ type: "UPDATE_SECOND_STATUS", payload: data });

        dispatch({
          type: "UPDATE_SUCCESS_SECOND_STATUS",
          payload: {
            ...data,
            error: null,
            message: "UPDATE_SECOND_STATUS_SUCCESS",
          },
        });
        dispatch({
          type: "END_LOADING",
          payload: { ...data, error: null, message: "END_LOADING" },
        });
      } catch (error) {
        dispatch({
          type: "UPDATE_FAIL_SECOND_STATUS",
          payload: {
            ...data,
            error: error,
            message: "UPDATE_SECOND_STATUS_FAIL",
          },
        });
        dispatch({
          type: "END_LOADING",
          payload: { ...data, error: null, message: "END_LOADING" },
        });
      }
    }
  };

export const deleteSecondStatus =
  (data: SecondStatusState) =>
  (dispatch: Dispatch<SecondStatusActionTypes>) => {
    {
      try {
        dispatch({
          type: "START_LOADING",
          payload: { ...data, error: null, message: "START_LOADING" },
        });

        dispatch({ type: "DELETE_SECOND_STATUS", payload: data });

        dispatch({
          type: "DELETE_SUCCESS_SECOND_STATUS",
          payload: {
            ...data,
            error: null,
            message: "DELETE_SECOND_STATUS_SUCCESS",
          },
        });
        dispatch({
          type: "END_LOADING",
          payload: { ...data, error: null, message: "END_LOADING" },
        });
      } catch (error) {
        dispatch({
          type: "DELETE_FAIL_SECOND_STATUS",
          payload: {
            ...data,
            error: error,
            message: "DELETE_SECOND_STATUS_FAIL",
          },
        });
        dispatch({
          type: "END_LOADING",
          payload: { ...data, error: null, message: "END_LOADING" },
        });
      }
    }
  };

export const getSecondStatus =
  (data: SecondStatusState) =>
  async (dispatch: Dispatch<SecondStatusActionTypes>) => {
    {
      try {
        dispatch({
          type: "START_LOADING",
          payload: { ...data, error: null, message: "START_LOADING" },
        });

        const second_status = await axios.post(
          `${process.env.REACT_APP_API_URL}/api/lawyer/general-data`,
          { id: data.id, search: "second_status" }
        );
        dispatch({ type: "GET_SECOND_STATUS", payload: second_status.data });

        dispatch({
          type: "GET_SUCCESS_SECOND_STATUS",
          payload: {
            ...data,
            error: null,
            message: "GET_SECOND_STATUS_SUCCESS",
          },
        });
        dispatch({
          type: "END_LOADING",
          payload: { ...data, error: null, message: "END_LOADING" },
        });
      } catch (error) {
        dispatch({
          type: "GET_FAIL_SECOND_STATUS",
          payload: { ...data, error: error, message: "GET_SECOND_STATUS_FAIL" },
        });
        dispatch({
          type: "END_LOADING",
          payload: { ...data, error: null, message: "END_LOADING" },
        });
      }
    }
  };
// [ANCHOR_1]
