
import { SubjectActionTypes, SubjectState } from '../types/subject_types';
import axios from 'axios'
axios.defaults.withCredentials = true;

const initialState: SubjectState = {
    id: 0,
    name: '',
    subjects: [],
    error: null,
    message: ''
};

const SubjectReducer = (state = initialState, action: SubjectActionTypes) => {
    switch (action.type) {
        
        case 'POST_SUBJECT':
            // const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/`, {});
            // console.log(response)
            return {...state, subjects: action.payload.subjects};
        case 'POST_SUCCESS_SUBJECT':
            return {...state, error: null, message: action.payload.message};
        case 'POST_FAIL_SUBJECT':
            return {...state, error: action.payload.error, message: action.payload.message};
        
        case 'PUT_SUBJECT':
            // const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/`, {});
            // console.log(response)
            return {...state, data: []};
        case 'PUT_SUCCESS_SUBJECT':
            return {...state, error: null, message: action.payload.message};
        case 'PUT_FAIL_SUBJECT':
            return {...state, error: action.payload.error, message: action.payload.message};
        
        case 'UPDATE_SUBJECT':
            // const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/`, {});
            // console.log(response)
            return {...state, data: []};
        case 'UPDATE_SUCCESS_SUBJECT':
            return {...state, error: null, message: action.payload.message};
        case 'UPDATE_FAIL_SUBJECT':
            return {...state, error: action.payload.error, message: action.payload.message};
            
        case 'DELETE_SUBJECT':
            // const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/`, {});
            // console.log(response)
            return {...state, data: []};
        case 'DELETE_SUCCESS_SUBJECT':
            return {...state, error: null, message: action.payload.message};
        case 'DELETE_FAIL_SUBJECT':
            return {...state, error: action.payload.error, message: action.payload.message};
        
        case 'GET_SUBJECT':
            // const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/`, {});
            // console.log(response)
            return {...state, subjects: action.payload.subjects};
        case 'GET_SUCCESS_SUBJECT':
            return {...state, error: null, message: action.payload.message};
        case 'GET_FAIL_SUBJECT':
            return {...state, error: action.payload.error, message: action.payload.message};
// [ANCHOR_1]
        case 'START_LOADING':
            return {...state, loading: true};
        case 'END_LOADING':
            return {...state, loading: false};
        default:
            return state;
    }
};

export default SubjectReducer;
    