import FullWidthLayout from "../../layouts/full_width_layout";
import { useEffect, useState } from "react";
import { connect, ConnectedProps } from 'react-redux';
import { RootState } from "../../store";
import { getTest, postTest } from "../../core/redux/actions/test_action";
import { CustomButton } from "../../core/components/buttons/custom_button";
import { HiAnnotation } from "react-icons/hi";

const Survey: React.FC<PropsFromRedux> = ({
  postTest,
  getTest
}: PropsFromRedux) => {

  const [loading, setLoading] = useState<boolean>(false)

  useEffect(() => {
    const onInit = async () => {
      try {
        await getTest({
          id: 0,
          name: ""
        });
      } catch (error) {
          console.error('Error fetching data: ', error);
      } finally {
          setLoading(false);
      }
    };
    
    onInit();
  }, []);


  const handleOnclinck = () => {
    postTest({
      id: 1,
      name: "test"
    })
  }



  return (
    <FullWidthLayout>
        <div className="p-3">
          <h1 className="text-slate-700 dark:text-stone-50">Survey</h1>
          <CustomButton
          onClick={handleOnclinck} colors={[]} icon={<HiAnnotation/>} label={""}          />
        </div>
    </FullWidthLayout>
  )
}

const mapStateToProps = (state: RootState) => ({
  test_state: state.test_state,
});

const mapDispatchToProps = {
  getTest,
  postTest
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(Survey);