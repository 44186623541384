import React, { useEffect, useState } from "react";
import { HiCheck, HiPencil } from "react-icons/hi";
import { TagRequest } from "../../../core/redux/types/tag_types";
import {
  IncomeRequest,
  IncomeState,
} from "../../treasury/redux/types/income_types";
import { CaseRequest, CaseState } from "../../cases/redux/types/case_types";
import Select from "react-select";
import { CustomButton } from "../../../core/components/buttons/custom_button";
import { OutcomeRequest } from "../../treasury/redux/types/outcome_types";
import { OutcomeTypeRequest } from "../../treasury/redux/types/outcome_type_types";
import { IncomeTypeRequest } from "../../treasury/redux/types/income_type_types";
import MonthlyChart from "./monthly_chart";
import MonthlyLinearChart from "./monthly_linear_chart";
import axios from "axios";
import { TreasuryRequest } from "../../treasury/redux/types/treasury_types";
import NewIncomeModal from "../../treasury/components/new_income_modal";
import NewOutcomeModal from "../../treasury/components/new_outcome_modal";
import { updateIncome } from "../../treasury/redux/actions/income_action";
import { updateOutcome } from "../../treasury/redux/actions/outcome_action";
axios.defaults.withCredentials = true;

type Props = {
  outcome_types: OutcomeTypeRequest[];
  income_types: IncomeTypeRequest[];
  tags: TagRequest[];
  selectedRow: number;
  case: CaseRequest | null;
  incomes: IncomeRequest[];
  outcomes: OutcomeRequest[];
  cases: CaseRequest[];
  getTreasury: (data: TreasuryRequest) => void;
  setCase: (data: CaseRequest) => void;
  updateIncome: (data: IncomeRequest) => void;
  updateOutcome: (data: OutcomeRequest) => void;
};

export const FeesDataTable = (props: Props) => {
  const initialRequestData: TreasuryRequest = {
    id: 0,
    amount: 0,
    description: "",
    hours: 0,
    case: props.case,
    tags: [],
    photo: "",
    start_at: null,
    end_at: null,
    income_type: null,
    validated: false,
    created_by: {
      id: 1,
      name: "",
    },
    validated_by: null,
  };

  const [formData, setFormData] = useState<TreasuryRequest>(initialRequestData);

  const [isOpen, setIsOpen] = useState(false);

  const handleOptionClick = (row: IncomeRequest) => {
    setIsOpen(false);
  };
  const handleOutcomeOptionClick = (row: OutcomeRequest) => {
    setIsOpen(false);
  };

  const [monthlyIncomes, setMonthlyIncomes] = useState<number[]>([]);
  const [monthlyOutcomes, setMonthlyOutcomes] = useState<number[]>([]);

  useEffect(() => {
    const handleGetFees = async (data: CaseState) => {
      console.log("income data table props: ", props);

      try {
        props.getTreasury(formData);

        const pi = aggregateIncomesByMonth(props.incomes);
        const po = aggregateOutcomesByMonth(props.outcomes);
        setMonthlyIncomes(pi);
        setMonthlyOutcomes(po);
      } catch (error) {
        console.error("Error fetching data: ", error);
      } finally {
        // setLoading(false);
      }
    };

    handleGetFees({
      id: 0,
      name: "",
      new_one: null,
      cases: [],
      selected: null,
    });

    console.log("IncomeDataTable", [props.incomes, props.outcomes]);
  }, []);

  useEffect(() => {
    const pi = aggregateIncomesByMonth(props.incomes);
    const po = aggregateOutcomesByMonth(props.outcomes);
    setMonthlyIncomes(pi);
    setMonthlyOutcomes(po);
  }, [props.incomes, props.outcomes]);

  type MonthlyIncomeArray = number[];
  type MonthlyOutcomeArray = number[];

  const getMonthFromDateString = (dateString: string): number => {
    const date = new Date(dateString);
    return date.getMonth();
  };

  const aggregateIncomesByMonth = (
    incomes: IncomeRequest[]
  ): MonthlyIncomeArray => {
    const result: MonthlyIncomeArray = Array(12).fill(0); // Inicializa un array con 12 meses.

    for (const income of incomes) {
      const month = getMonthFromDateString(income.start_at!.toString());
      result[month] += parseFloat(
        income.amount ? income.amount.toString() : "0.0"
      );
    }

    return result;
  };

  const aggregateOutcomesByMonth = (
    outcomes: OutcomeRequest[]
  ): MonthlyOutcomeArray => {
    const result: MonthlyOutcomeArray = Array(12).fill(0); // Inicializa un array con 12 meses.

    for (const outcome of outcomes) {
      const month = getMonthFromDateString(outcome.start_at?.toString()!);
      result[month] += parseFloat(
        outcome.amount ? outcome.amount.toString() : "0.0"
      );
    }

    return result;
  };

  const handleSelectChange =
    (fieldName: keyof IncomeRequest) => async (selectedOptions: any) => {
      if (fieldName === "case") {
        props.setCase(selectedOptions);
        console.log("case selected: ", selectedOptions);
      }
      setFormData({
        ...formData,
        [fieldName]: selectedOptions,
      });
    };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    console.log("submit formData: ", formData);
    props.getTreasury(formData);
  };

  useEffect(() => {
    setFormData({
      ...formData,
      case: props.case,
    });
  }, [props.case]);

  const getOptionValue = (option: any) => option.id;
  const getOptionLabel = (option: any) => option.name;

  return (
    <>
      <form onSubmit={handleSubmit} className="flex flex-wrap items-left py-3">
        <div className="w-full">
          <Select
            placeholder="Caso"
            key={
              [
                {
                  id: 0,
                  name: "Sin Caso",
                  new_one: null,
                },
                ...props.cases,
              ]?.length
            }
            options={[
              {
                id: 0,
                name: "Sin Caso",
                new_one: null,
              },
              ...props.cases,
            ]}
            formatOptionLabel={(data) => {
              return data.name;
            }}
            onChange={handleSelectChange("case")}
            value={formData.case}
            getOptionValue={getOptionValue}
            getOptionLabel={getOptionLabel}
            className="w-full"
          />
        </div>
        <div className="w-full pt-2">
          <p className="text-sm text-gray-500 text-right">
            Quieres generar el reporte ?
          </p>
        </div>
        <div className="w-full pt-2 flex justify-end space-x-2">
          <CustomButton
            type="button"
            label={"Cancelar"}
            colors={["indigo", "indigo"]}
            icon={<HiCheck />}
            onClick={
              formData.case?.id! > 0
                ? () => setFormData(initialRequestData)
                : () => setIsOpen(false)
            }
          />

          <CustomButton
            type="submit"
            label={"Si"}
            colors={["indigo", "indigo"]}
            icon={<HiCheck />}
            onClick={() => setIsOpen(true)}
          />
        </div>
      </form>

      <div className="flex flex-wrap items-left py-3">
        {props.incomes ? (
          <div className="p-5 w-full md:w-1/2">
            <div className="overflow-x-scroll">
              <table className="min-w-full divide-y divide-gray-200">
                <thead className="bg-gray-50">
                  <tr>
                    <th
                      scope="col"
                      className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    ></th>
                    <th
                      scope="col"
                      className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      Monto
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      Descripcion
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      Tipo
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      Fecha de Creacion
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      Vencimiento
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      Status
                    </th>
                  </tr>
                </thead>
                <tbody className="bg-white divide-y divide-gray-200">
                  {props.incomes?.map((income) => (
                    <tr
                      key={income.id}
                      onClick={() => handleOptionClick(income)}
                    >
                      <td className="gap-2 px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                        <div className="p-1 w-1/3">
                          <NewIncomeModal
                            tags={props.tags}
                            cases={props.cases}
                            case={income.case}
                            handleFormSubmit={updateIncome}
                            income={income}
                            colors={["indigo", "indigo"]}
                            icon={<HiPencil />}
                            label={""}
                            income_types={[]}
                            setCase={function (data: CaseRequest | null): void {
                              throw new Error("Function not implemented.");
                            }}
                          />
                        </div>
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">{`${income.amount}`}</td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">{`${income.description}`}</td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">{`${income.income_type?.name}`}</td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">{`${income.start_at}`}</td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">{`${income.end_at}`}</td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">{`${income.validated}`}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        ) : (
          <p>No tienes Ingresos aun</p>
        )}

        {props.outcomes ? (
          <div className="p-5 w-full md:w-1/2">
            <div className="overflow-x-scroll">
              <table className="min-w-full divide-y divide-gray-200">
                <thead className="bg-gray-50">
                  <tr>
                    <th
                      scope="col"
                      className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    ></th>
                    <th
                      scope="col"
                      className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      Monto
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      Descripcion
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      Tipo
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      Fecha de Creacion
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      Vencimiento
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      Status
                    </th>
                  </tr>
                </thead>
                <tbody className="bg-white divide-y divide-gray-200">
                  {props.outcomes?.map((outcome) => (
                    <tr
                      key={outcome.id}
                      onClick={() => handleOutcomeOptionClick(outcome)}
                    >
                      <td className="gap-2 px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                        <div className="p-1 w-1/3">
                          <NewOutcomeModal
                            tags={props.tags}
                            cases={props.cases}
                            case={outcome.case!}
                            handleFormSubmit={updateOutcome}
                            outcome={outcome}
                            outcome_types={props.outcome_types}
                            colors={["indigo", "indigo"]}
                            icon={<HiPencil />}
                            label={""}
                            setCase={function (data: CaseRequest | null): void {
                              throw new Error("Function not implemented.");
                            }}
                          />
                        </div>
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">{`${outcome.amount}`}</td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">{`${outcome.description}`}</td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">{`${outcome.outcome_type?.name}`}</td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">{`${outcome.start_at}`}</td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">{`${outcome.end_at}`}</td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">{`${outcome.validated}`}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        ) : (
          <p>No tienes Egresos aun</p>
        )}
      </div>

      {props.incomes && props.outcomes ? (
        <div className="flex flex-wrap items-left py-3">
          <div className="w-full p-3 md:w-1/2">
            <MonthlyChart
              incomesByMonth={monthlyIncomes}
              outcomesByMonth={monthlyOutcomes}
            />
          </div>
          <div className="w-full p-3 md:w-1/2">
            <MonthlyLinearChart
              incomesByMonth={monthlyIncomes}
              outcomesByMonth={monthlyOutcomes}
            />
          </div>
        </div>
      ) : (
        <></>
      )}
    </>
  );
};
