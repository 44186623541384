import { useState } from "react";
import { NavLink } from "react-router-dom";
import DarkModeSwitch from "../util/dark_mode_switch";

interface Props {
  logOut: () => void;
}

export default function NoAuthNavBar(props: Props) {
  return (
    <header className="dark:bg-slate-700 bg-stone-50 fixed w-full z-40 text-slate-700 dark:text-stone-50">
      <nav
        className="mx-auto flex max-w-7xl items-center justify-between lg:px-8"
        aria-label="Global"
      >
        <div className="flex lg:flex-1 items-center">
          <NavLink to="/" className="">
            <img
              loading="lazy"
              className="h-20 w-auto"
              src="/images/logos/jelly.png"
              alt="Logo"
              title="Acid Jelly Logo"
            />
            <span className="sr-only">Acid Jelly</span>
          </NavLink>
          <h1 className="hidden md:flex text-3xl px-3">
            <strong className="text-acidjelly-blue-700">ACID</strong>{" "}
            <strong className="text-acidjelly-red">JELLY</strong>
          </h1>
        </div>
        <div className="flex-1"></div>
        <div className="flex flex-row">
          <NavLink
            to="/login"
            className="px-3 py-1 text-base font-medium text-slate-700 dark:text-stone-50 hover:text-slate-900 dark:hover:text-stone-50"
          >
            Login
          </NavLink>
          <NavLink
            to="/registration"
            className="px-3 py-1 text-base font-medium text-slate-700 dark:text-stone-50 hover:text-slate-900 dark:hover:text-stone-50"
          >
            Sing In
          </NavLink>
        </div>
        <div className="px-2 flex items-center">
          <DarkModeSwitch />
        </div>
      </nav>
    </header>
  );
}
