
import { SeatActionTypes, SeatState, SeatRequest } from '../types/seat_types';
import axios from 'axios'
axios.defaults.withCredentials = true;

const initialState: SeatState = {
    id: 0,
    name: '',
    data: [],
    seats: [],
    error: null,
    message: '',
    loading: false,
    selected: null,
    deleted: null,
    new_one: null,
    new_ones: [],
};

const SeatReducer = (state = initialState, action: SeatActionTypes) => {
    switch (action.type) {
        
        case 'POST_SEAT':
            return {...state, seats: action.payload.seats, error: null, message: action.payload.message};
        case 'POST_SUCCESS_SEAT':
            return {...state, error: null, message: action.payload.message};
        case 'POST_FAIL_SEAT':
            return {...state, error: action.payload.error, message: action.payload.message};
        
        case 'PUT_SEAT':
            return {...state, data: []};
        case 'PUT_SUCCESS_SEAT':
            return {...state, error: null, message: action.payload.message};
        case 'PUT_FAIL_SEAT':
            return {...state, error: action.payload.error, message: action.payload.message};
        
        case 'UPDATE_SEAT':
            return {...state, data: []};
        case 'UPDATE_SUCCESS_SEAT':
            return {...state, error: null, message: action.payload.message};
        case 'UPDATE_FAIL_SEAT':
            return {...state, error: action.payload.error, message: action.payload.message};
            
        case 'DELETE_SEAT':
            return {...state, seats: action.payload.seats?.filter((x:SeatRequest) => x.id !== action.payload.deleted?.id), error: null, message: action.payload.message};
        case 'DELETE_SUCCESS_SEAT':
            return {...state, error: null, message: action.payload.message};
        case 'DELETE_FAIL_SEAT':
            return {...state, error: action.payload.error, message: action.payload.message};
        
        case 'GET_SEAT':
            return {...state, seats: action.payload.seats};
        case 'GET_SUCCESS_SEAT':
            return {...state, error: null, message: action.payload.message};
        case 'GET_FAIL_SEAT':
            return {...state, error: action.payload.error, message: action.payload.message};
// [ANCHOR_1]

        case 'SELECT_SEAT':
            return {...state, selected: action.payload};
        case 'SELECT_SUCCESS_SEAT':
            return {...state, error: null, message: action.payload.message};
        case 'SELECT_FAIL_SEAT':
            return {...state, error: action.payload.error, message: action.payload.message};

        case 'START_LOADING':
            return {...state, loading: true};
        case 'END_LOADING':
            return {...state, loading: false};
        default:
            return state;
    }
};

export default SeatReducer;
    