import React, { useState } from "react";
import { FolderRequest } from "../redux/types/folder_types";
import { DocumentRequest } from "../redux/types/document_types";
import {
  HiArrowDown,
  HiArrowRight,
  HiDownload,
  HiFolder,
  HiTrash,
} from "react-icons/hi";
import { CustomButton } from "../../../core/components/buttons/custom_button";

interface Props {
  selected_document: DocumentRequest | null;
  selected_folder: FolderRequest | null;
  folders: FolderRequest[];
  documents: DocumentRequest[];
  onDeleteItem: (item: FolderRequest | DocumentRequest) => void;
  selectFolder: (folder: FolderRequest) => void;
  selectDocument: (document: DocumentRequest) => void;
  onUpdateDocument: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onUpdateFolder: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

const FolderExplorer: React.FC<Props> = (props) => {
  const [selectedItem, setSelectedItem] = useState<
    FolderRequest | DocumentRequest | null
  >(null);

  const [open_folders_ids, setOpenFoldersIds] = useState<string[]>([]);
  function toggleItemId(id: string) {
    const index = open_folders_ids.indexOf(id);
    if (index === -1) {
      // If the id is not found, add it
      setOpenFoldersIds([...open_folders_ids, id]);
    } else {
      // If the id is found, remove it
      const newIds = open_folders_ids.filter((x) => x !== id);
      setOpenFoldersIds(newIds);
    }
  }

  const handleItemClick = async (item: FolderRequest | DocumentRequest) => {
    if (item && !("sub_folders" in item)) {
      props.selectDocument(item);
    } else if (item && "sub_folders" in item) {
      toggleItemId(item.id);
      props.selectFolder(item);
    }
  };

  const isOpen = (id: string) => {
    return open_folders_ids.find((x) => x === id);
  };

  function handleDownloadDocument(_document: DocumentRequest): void {
    const link = document.createElement("a");
    link.href = `${process.env.REACT_APP_API_URL}${_document.file}`;
    if (_document.name) {
      link.target = "_blank";
      link.download = _document.name;
      link.click();
    } else {
      link.remove();
    }
  }

  function get_documents(documents: string[]): DocumentRequest[] {
    if (!documents) return []; // Handle case where documents is undefined or null

    return props.documents.filter(
      (x: DocumentRequest) =>
        x.id === documents?.find((y: string) => y === x.id)
    );
  }

  function get_sub_folders(sub_folders: string[]): FolderRequest[] {
    return props.folders.filter((x) => sub_folders?.find((y) => y === x.id));
  }

  const renderFolder = (folder: FolderRequest) => (
    <div key={folder.id} className="p-2">
      <div
        onClick={() => {
          handleItemClick(folder);
        }}
        className={`flex items-center ${
          props.selected_folder && folder.id === props.selected_folder.id
            ? "bg-blue-200 cursor-pointer"
            : "bg-gray-50"
        } hover:bg-gray-300 p-2 cursor-pointer`}
      >
        <div className="p-1">
          {isOpen(folder.id) ? <HiArrowDown /> : <HiArrowRight />}
        </div>

        <div className="p-1">
          <HiFolder className="inline-block" />
        </div>

        <div className="p-1">
          <input
            type="text"
            className="border-2"
            onChange={props.onUpdateDocument}
            value={folder.name}
          />
        </div>

        <div className="p-1">
          {folder === props.selected_folder && "(Selected)"}
        </div>
      </div>
      {isOpen(folder.id) && (
        <>
          {folder.sub_folders &&
            get_sub_folders(folder.sub_folders).map(renderFolder)}
          {folder.documents &&
            get_documents(folder.documents).map((document) => (
              <div
                key={document.id}
                onClick={() => handleItemClick(document)}
                className={`flex items-center bg-gray-200 ${
                  selectedItem === document ? "bg-blue-100" : ""
                }`}
              >
                <div className="p-1 w-full">
                  <input
                    type="text"
                    className="border-2 w-full"
                    onChange={props.onUpdateDocument}
                    value={document.name}
                  />
                </div>
                <div className="p-1">
                  {document === props.selected_document && "(Selected)"}
                </div>
                <div className="flex">
                  <div className="p-1 mx-auto">
                    <CustomButton
                      label=""
                      onClick={() => props.onDeleteItem(document)}
                      colors={[]}
                      icon={<HiTrash />}
                    />
                  </div>
                  <div className="p-1 mx-auto">
                    <CustomButton
                      label=""
                      onClick={() => handleDownloadDocument(document)}
                      colors={[]}
                      icon={<HiDownload />}
                    />
                  </div>
                </div>
              </div>
            ))}
        </>
      )}
    </div>
  );

  return (
    <div>
      <div className="overflow-y-auto h-52 md:h-72 border-r-2 p-4">
        {props.folders
          .filter(
            (x) =>
              x.parent_folder === null ||
              x.parent_folder === "" ||
              x.parent_folder === undefined
          )
          .map(renderFolder)}
      </div>
    </div>
  );
};

export default FolderExplorer;
