
import { OfficeObjectActionTypes, OfficeObjectState, OfficeObjectRequest } from '../types/office_object_types';
import axios from 'axios'
axios.defaults.withCredentials = true;

const initialState: OfficeObjectState = {
    id: 0,
    name: '',
    data: [],
    office_objects: [],
    error: null,
    message: '',
    loading: false,
    selected: null,
    deleted: null,
    new_one: null,
    new_ones: [],
};

const OfficeObjectReducer = (state = initialState, action: OfficeObjectActionTypes) => {
    switch (action.type) {
        
        case 'POST_OFFICE_OBJECT':
            return {...state, selected: action.payload.selected!, error: null, message: action.payload.message};
        case 'POST_SUCCESS_OFFICE_OBJECT':
            return {...state, error: null, message: action.payload.message};
        case 'POST_FAIL_OFFICE_OBJECT':
            return {...state, error: action.payload.error, message: action.payload.message};
        
        case 'PUT_OFFICE_OBJECT':
            return {...state, data: []};
        case 'PUT_SUCCESS_OFFICE_OBJECT':
            return {...state, error: null, message: action.payload.message};
        case 'PUT_FAIL_OFFICE_OBJECT':
            return {...state, error: action.payload.error, message: action.payload.message};
        
        case 'UPDATE_OFFICE_OBJECT':
            return {...state, data: []};
        case 'UPDATE_SUCCESS_OFFICE_OBJECT':
            return {...state, error: null, message: action.payload.message};
        case 'UPDATE_FAIL_OFFICE_OBJECT':
            return {...state, error: action.payload.error, message: action.payload.message};
            
        case 'DELETE_OFFICE_OBJECT':
            return {...state, office_objects: action.payload.office_objects?.filter((x:OfficeObjectRequest) => x.id !== action.payload.deleted?.id), error: null, message: action.payload.message};
        case 'DELETE_SUCCESS_OFFICE_OBJECT':
            return {...state, error: null, message: action.payload.message};
        case 'DELETE_FAIL_OFFICE_OBJECT':
            return {...state, error: action.payload.error, message: action.payload.message};
        
        case 'GET_OFFICE_OBJECT':
            return {...state, data: [], office_objects: action.payload.office_objects!, error: null, message: action.payload.message};
        case 'GET_SUCCESS_OFFICE_OBJECT':
            return {...state, error: null, message: action.payload.message};
        case 'GET_FAIL_OFFICE_OBJECT':
            return {...state, error: action.payload.error, message: action.payload.message};

// [ANCHOR_1]
        

        case 'SELECT_OFFICE_OBJECT':
            return {...state, selected: action.payload};
        case 'SELECT_SUCCESS_OFFICE_OBJECT':
            return {...state, error: null, message: action.payload.message};
        case 'SELECT_FAIL_OFFICE_OBJECT':
            return {...state, error: action.payload.error, message: action.payload.message};

        case 'START_LOADING':
            return {...state, loading: true};
        case 'END_LOADING':
            return {...state, loading: false};
        default:
            return state;
    }
};

export default OfficeObjectReducer;
    