
import { Dispatch } from 'redux';
import { GoogleActionTypes, GoogleState } from '../types/google_types';
import axios from 'axios'
axios.defaults.withCredentials = true;;

export const postGoogle = (data: GoogleState) => async (dispatch: Dispatch<GoogleActionTypes>) => {{
    try {
        
        if ( !localStorage.getItem('access') ) {
            const config = {
                headers: {
                    "Content-Type": "application/json"
                }
            };
            const body = JSON.stringify({code:data.code})
            try {
                const res = await axios.post(`${process.env.REACT_APP_API_URL}/dj-rest-auth/google/`, body, config)
                dispatch ({
                    type: 'POST_SUCCESS_GOOGLE',
                    payload: res.data
                })
            } catch (error) {
                dispatch({ type: 'POST_FAIL_GOOGLE', payload: {...data, error: error, message: "POST_GOOGLE_FAIL"} });
            }
        } else {
            dispatch({ type: 'VERIFY_GOOGLE', payload: data });
            dispatch({ type: 'USER_GOOGLE', payload: data});
        }
    } catch (error) {
        dispatch({ type: 'POST_FAIL_GOOGLE', payload: {...data, error: error, message: "POST_GOOGLE_FAIL"} });
    }
}};

export const putGoogle = (data: GoogleState) => (dispatch: Dispatch<GoogleActionTypes>) => {{
    try {
        dispatch({ type: 'PUT_GOOGLE', payload: data });
        
        dispatch({ type: 'PUT_SUCCESS_GOOGLE', payload: {...data, error: null, message: "PUT_GOOGLE_SUCCESS"} });
    } catch (error) {
        dispatch({ type: 'PUT_FAIL_GOOGLE', payload: {...data, error: error, message: "PUT_GOOGLE_FAIL"} });
    }
}};

export const updateGoogle = (data: GoogleState) => (dispatch: Dispatch<GoogleActionTypes>) => {{
    try {
        dispatch({ type: 'UPDATE_GOOGLE', payload: data });
        
        dispatch({ type: 'UPDATE_SUCCESS_GOOGLE', payload: {...data, error: null, message: "UPDATE_GOOGLE_SUCCESS"} });
    } catch (error) {
        dispatch({ type: 'UPDATE_FAIL_GOOGLE', payload: {...data, error: error, message: "UPDATE_GOOGLE_FAIL"} });
    }
}};

export const deleteGoogle = (data: GoogleState) => (dispatch: Dispatch<GoogleActionTypes>) => {{
    try {
        dispatch({ type: 'DELETE_GOOGLE', payload: data });
        
        dispatch({ type: 'DELETE_SUCCESS_GOOGLE', payload: {...data, error: null, message: "DELETE_GOOGLE_SUCCESS"} });
    } catch (error) {
        dispatch({ type: 'DELETE_FAIL_GOOGLE', payload: {...data, error: error, message: "DELETE_GOOGLE_FAIL"} });
    }
}};

export const getGoogle = (data: GoogleState) => (dispatch: Dispatch<GoogleActionTypes>) => {{
    try {
        dispatch({ type: 'GET_GOOGLE', payload: data });
        
        dispatch({ type: 'GET_SUCCESS_GOOGLE', payload: {...data, error: null, message: "GET_GOOGLE_SUCCESS"} });
    } catch (error) {
        dispatch({ type: 'GET_FAIL_GOOGLE', payload: {...data, error: error, message: "GET_GOOGLE_FAIL"} });
    }
}};


export const verifyGoogle = (data: GoogleState) => async (dispatch: Dispatch<GoogleActionTypes>) => {{
    if ( localStorage.getItem('access') ) {
        const config = {
            headers: {
                "Content-Type": "application/json"
            }
        };
        const body = JSON.stringify({ "token": localStorage.getItem('access') });
        try {
            await axios.post(`${process.env.REACT_APP_API_URL}/dj-rest-auth/token/verify/`, body, config);
            dispatch({ type: 'VERIFY_GOOGLE', payload: data });
            dispatch({ type: 'VERIFY_SUCCESS_GOOGLE', payload: {...data, error: null, message: "VERIFY_SUCCESS_GOOGLE"} });
        } catch (error) {
            dispatch({ type: 'VERIFY_FAIL_GOOGLE', payload: {...data, error: error, message: "VERIFY_FAIL"} });
            dispatch({ type: 'REFRESH_GOOGLE', payload: data });
        }
    } else {
        // dispatch ({
        //     type: TYPE.GUEST_VIEW
        // });
    }
}};


export const userGoogle = (data: GoogleState) => async (dispatch: Dispatch<GoogleActionTypes>) => {{

    if ( localStorage.getItem('access') ) {
        const config = {
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${ localStorage.getItem('access') }`
            }
        };
        try {
            const res = await axios.get(`${process.env.REACT_APP_API_URL}/dj-rest-auth/user/`, config);
            
            dispatch({ type: 'USER_GOOGLE', payload: data });
            
            dispatch({ type: 'USER_SUCCESS_GOOGLE', payload: {...data, error: null, message: "USER_SUCCESS"} });
        } catch (error) {
            dispatch({ type: 'USER_FAIL_GOOGLE', payload: {...data, error: error, message: "USER_FAIL"} });
        }
    } else {
        // dispatch ({
        //     type: TYPE.GUEST_VIEW
        // });
    }
}};


export const refreshGoogle = (data: GoogleState) => async (dispatch: Dispatch<GoogleActionTypes>) => {{

    if ( localStorage.getItem('access') ) {
        const config = {
            headers: {
                "Content-Type": "application/json"
            }
        };
        try {
            const res = await axios.post(`${process.env.REACT_APP_API_URL}/dj-rest-auth/token/refresh/`, config);
            console.log(res.data);
            dispatch({ type: 'REFRESH_GOOGLE', payload: data });
        
            dispatch({ type: 'REFRESH_SUCCESS_GOOGLE', payload: {...data, error: null, message: "REFRESH_SUCCESS_GOOGLE"} });
        } catch (error) {
            dispatch({ type: 'REFRESH_FAIL_GOOGLE', payload: {...data, error: error, message: "REFRESH_FAIL_GOOGLE"} });
        }
    } else {
        // dispatch ({
        //     type: TYPE.GUEST_VIEW
        // })
    }

}};


export const logoutGoogle = (data: GoogleState) => async (dispatch: Dispatch<GoogleActionTypes>) => {{
    const config = {
        headers: {
            "Content-Type": "application/json"
        }
    };
    try {
        await axios.post(`${process.env.REACT_APP_API_URL}/dj-rest-auth/logout/`, config);
        dispatch({ type: 'LOGOUT_GOOGLE', payload: data });
        
        dispatch({ type: 'LOGOUT_SUCCESS_GOOGLE', payload: {...data, error: null, message: "LOGOUT_SUCCESS"} });
    } catch (err) {
        // dispatch ({
        //     type: TYPE.LOGOUT
        // });
    }
}};


export const guestGoogle = (data: GoogleState) => (dispatch: Dispatch<GoogleActionTypes>) => {{
    try {
        dispatch({ type: 'GUEST_GOOGLE', payload: data });
        
        dispatch({ type: 'GUEST_SUCCESS_GOOGLE', payload: {...data, error: null, message: "GUEST_SUCCESS"} });
    } catch (error) {
        dispatch({ type: 'GUEST_FAIL_GOOGLE', payload: {...data, error: error, message: "GUEST_FAIL"} });
    }
}};


export const failGoogle = (data: GoogleState) => (dispatch: Dispatch<GoogleActionTypes>) => {{
    try {
        dispatch({ type: 'FAIL_GOOGLE', payload: data });
        
        dispatch({ type: 'FAIL_SUCCESS_GOOGLE', payload: {...data, error: null, message: "FAIL_SUCCESS"} });
    } catch (error) {
        dispatch({ type: 'FAIL_FAIL_GOOGLE', payload: {...data, error: error, message: "FAIL_FAIL"} });
    }
}};


export const loginGoogle = (data: GoogleState) => async (dispatch: Dispatch<GoogleActionTypes>) => {{
    try {
        if ( !localStorage.getItem('access') ) {
            const config = {
                headers: {
                    "Content-Type": "application/json"
                }
            };
            const body = JSON.stringify({
                code: data.code,
                authuser: data.authuser, 
                prompt: data.prompt,
                grant_type: data.grant_type,
                client_id: process.env.REACT_APP_GOOGLE_CLIENT_ID,
                client_secret: process.env.REACT_APP_GOOGLE_CLIENT_SECRET,
            })
            try {
                const res = await axios.post(`${process.env.REACT_APP_API_URL}/dj-rest-auth/google/`, {code:data.code}, config)
                dispatch ({
                    type: 'LOGIN_SUCCESS_GOOGLE',
                    payload: res.data
                })
            } catch (error) {
                dispatch({ type: 'LOGIN_FAIL_GOOGLE', payload: {...data, error: error, message: "LOGIN_FAIL_GOOGLE"} });
            }
        } else {
            dispatch({ type: 'VERIFY_GOOGLE', payload: data });
            dispatch({ type: 'USER_GOOGLE', payload: data});
        }
    } catch (error) {
        dispatch({ type: 'LOGIN_FAIL_GOOGLE', payload: {...data, error: error, message: "POST_GOOGLE_FAIL"} });
    }
}};
// [ANCHOR_1]
        
        
        
        
        
        
        
    