import { Dispatch } from "redux";
import {
  GoodsActionTypes,
  GoodsRequest,
  GoodsResponse,
  GoodsError,
} from "../types/goods_types";
import axios, { AxiosError, AxiosResponse } from "axios";
axios.defaults.withCredentials = true;

export const GoodsUploadCategory =
  (data: GoodsRequest, files: File[]) =>
  async (dispatch: Dispatch<GoodsActionTypes>) => {
    dispatch({
      type: "START_LOADING",
      payload: { ...data, error: null, message: "START_LOADING" },
    });

    const formData = new FormData();
    formData.append("fk_id", data.fk_id ? data.fk_id.toString() : "");
    files.forEach((file) => {
      formData.append("name", file.name);
      formData.append("files", file);
    });

    axios
      .post(
        `${process.env.REACT_APP_API_URL}{prefix}{enpoint_name}/{model_name_for_url}/`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      )
      .then((response: AxiosResponse<GoodsResponse>) => {
        dispatch({ type: "GOODS_UPLOAD_CATEGORY", payload: response.data });

        setTimeout(() => {
          dispatch({
            type: "GOODS_UPLOAD_CATEGORY_SUCCESS",
            payload: {
              ...response.data,
              error: null,
              message: "UPLOAD_CATEGORY_SUCCESS",
            },
          });
        }, 3000);

        dispatch({
          type: "END_LOADING",
          payload: { ...data, error: null, message: "END_LOADING" },
        });
      })
      .catch((error: AxiosError<GoodsError>) => {
        if (error.response) {
          dispatch({
            type: "GOODS_UPLOAD_CATEGORY_FAIL",
            payload: error.response.data,
          });
          dispatch({
            type: "END_LOADING",
            payload: { ...data, error: null, message: "END_LOADING" },
          });
        } else if (error.request) {
          console.log("No response received:", error.request);
        } else {
          console.log("Error:", error.message);
        }
      });
  };

export const GoodsGetCategory =
  (data: GoodsRequest) => async (dispatch: Dispatch<GoodsActionTypes>) => {
    dispatch({
      type: "START_LOADING",
      payload: { ...data, error: null, message: "START_LOADING" },
    });

    axios
      .get(
        `${process.env.REACT_APP_API_URL}/api/goods/category?id=${data.id}&name=${data.name}`
      )
      .then((response: AxiosResponse<GoodsResponse>) => {
        dispatch({ type: "GOODS_GET_CATEGORY", payload: response.data });

        dispatch({
          type: "GOODS_GET_CATEGORY_SUCCESS",
          payload: {
            ...response.data,
            error: null,
            message: "GET_CATEGORY_SUCCESS",
          },
        });

        dispatch({
          type: "END_LOADING",
          payload: { ...data, error: null, message: "END_LOADING" },
        });
      })
      .catch((error: AxiosError<GoodsError>) => {
        if (error.response) {
          dispatch({
            type: "GOODS_GET_CATEGORY_FAIL",
            payload: error.response.data,
          });
          dispatch({
            type: "END_LOADING",
            payload: { ...data, error: null, message: "END_LOADING" },
          });
        } else if (error.request) {
          console.log("No response received:", error.request);
        } else {
          console.log("Error:", error.message);
        }
      });
  };

export const GoodsUploadSubCategory =
  (data: GoodsRequest, files: File[]) =>
  async (dispatch: Dispatch<GoodsActionTypes>) => {
    dispatch({
      type: "START_LOADING",
      payload: { ...data, error: null, message: "START_LOADING" },
    });

    const formData = new FormData();
    formData.append("fk_id", data.fk_id ? data.fk_id.toString() : "");
    files.forEach((file) => {
      formData.append("name", file.name);
      formData.append("files", file);
    });

    axios
      .post(
        `${process.env.REACT_APP_API_URL}{prefix}{enpoint_name}/{model_name_for_url}/`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      )
      .then((response: AxiosResponse<GoodsResponse>) => {
        dispatch({
          type: "GOODS_UPLOAD_SUB_CATEGORY",
          payload: response.data,
        });

        setTimeout(() => {
          dispatch({
            type: "GOODS_UPLOAD_SUB_CATEGORY_SUCCESS",
            payload: {
              ...response.data,
              error: null,
              message: "UPLOAD_SUB_CATEGORY_SUCCESS",
            },
          });
        }, 3000);

        dispatch({
          type: "END_LOADING",
          payload: { ...data, error: null, message: "END_LOADING" },
        });
      })
      .catch((error: AxiosError<GoodsError>) => {
        if (error.response) {
          dispatch({
            type: "GOODS_UPLOAD_SUB_CATEGORY_FAIL",
            payload: error.response.data,
          });
          dispatch({
            type: "END_LOADING",
            payload: { ...data, error: null, message: "END_LOADING" },
          });
        } else if (error.request) {
          console.log("No response received:", error.request);
        } else {
          console.log("Error:", error.message);
        }
      });
  };

export const GoodsGetSubCategory =
  (data: GoodsRequest) => async (dispatch: Dispatch<GoodsActionTypes>) => {
    dispatch({
      type: "START_LOADING",
      payload: { ...data, error: null, message: "START_LOADING" },
    });

    axios
      .get(
        `${process.env.REACT_APP_API_URL}{prefix}{api_name}/{model_name_url}?id=${data.fk_id}&name=${data.name}`
      )
      .then((response: AxiosResponse<GoodsResponse>) => {
        dispatch({ type: "GOODS_GET_SUB_CATEGORY", payload: response.data });

        dispatch({
          type: "GOODS_GET_SUB_CATEGORY_SUCCESS",
          payload: {
            ...response.data,
            error: null,
            message: "GET_SUB_CATEGORY_SUCCESS",
          },
        });

        dispatch({
          type: "END_LOADING",
          payload: { ...data, error: null, message: "END_LOADING" },
        });
      })
      .catch((error: AxiosError<GoodsError>) => {
        if (error.response) {
          dispatch({
            type: "GOODS_GET_SUB_CATEGORY_FAIL",
            payload: error.response.data,
          });
          dispatch({
            type: "END_LOADING",
            payload: { ...data, error: null, message: "END_LOADING" },
          });
        } else if (error.request) {
          console.log("No response received:", error.request);
        } else {
          console.log("Error:", error.message);
        }
      });
  };

export const GoodsUploadProduct =
  (data: GoodsRequest, files: File[]) =>
  async (dispatch: Dispatch<GoodsActionTypes>) => {
    dispatch({
      type: "START_LOADING",
      payload: { ...data, error: null, message: "START_LOADING" },
    });

    const formData = new FormData();
    formData.append("fk_id", data.fk_id ? data.fk_id.toString() : "");
    files.forEach((file) => {
      formData.append("name", file.name);
      formData.append("files", file);
    });

    axios
      .post(
        `${process.env.REACT_APP_API_URL}{prefix}{enpoint_name}/{model_name_for_url}/`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      )
      .then((response: AxiosResponse<GoodsResponse>) => {
        dispatch({
          type: "GOODS_UPLOAD_PRODUCT",
          payload: response.data,
        });

        setTimeout(() => {
          dispatch({
            type: "GOODS_UPLOAD_PRODUCT_SUCCESS",
            payload: {
              ...response.data,
              error: null,
              message: "UPLOAD_PRODUCT_SUCCESS",
            },
          });
        }, 3000);

        dispatch({
          type: "END_LOADING",
          payload: { ...data, error: null, message: "END_LOADING" },
        });
      })
      .catch((error: AxiosError<GoodsError>) => {
        if (error.response) {
          dispatch({
            type: "GOODS_UPLOAD_PRODUCT_FAIL",
            payload: error.response.data,
          });
          dispatch({
            type: "END_LOADING",
            payload: { ...data, error: null, message: "END_LOADING" },
          });
        } else if (error.request) {
          console.log("No response received:", error.request);
        } else {
          console.log("Error:", error.message);
        }
      });
  };

export const GoodsGetProduct =
  (data: GoodsRequest) => async (dispatch: Dispatch<GoodsActionTypes>) => {
    dispatch({
      type: "START_LOADING",
      payload: { ...data, error: null, message: "START_LOADING" },
    });

    axios
      .get(
        `${process.env.REACT_APP_API_URL}{prefix}{api_name}/{model_name_url}?id=${data.fk_id}&name=${data.name}`
      )
      .then((response: AxiosResponse<GoodsResponse>) => {
        dispatch({ type: "GOODS_GET_PRODUCT", payload: response.data });

        dispatch({
          type: "GOODS_GET_PRODUCT_SUCCESS",
          payload: {
            ...response.data,
            error: null,
            message: "GET_PRODUCT_SUCCESS",
          },
        });

        dispatch({
          type: "END_LOADING",
          payload: { ...data, error: null, message: "END_LOADING" },
        });
      })
      .catch((error: AxiosError<GoodsError>) => {
        if (error.response) {
          dispatch({
            type: "GOODS_GET_PRODUCT_FAIL",
            payload: error.response.data,
          });
          dispatch({
            type: "END_LOADING",
            payload: { ...data, error: null, message: "END_LOADING" },
          });
        } else if (error.request) {
          console.log("No response received:", error.request);
        } else {
          console.log("Error:", error.message);
        }
      });
  };

export const GoodsStoreCategory =
  (data: GoodsRequest) => async (dispatch: Dispatch<GoodsActionTypes>) => {
    dispatch({
      type: "START_LOADING",
      payload: { ...data, error: null, message: "START_LOADING" },
    });

    axios
      .post(
        `${process.env.REACT_APP_API_URL}{prefix}{api_name}/{model_name_url}/`,
        data
      )
      .then((response: AxiosResponse<GoodsResponse>) => {
        dispatch({ type: "GOODS_STORE_CATEGORY", payload: response.data });

        dispatch({
          type: "GOODS_STORE_CATEGORY_SUCCESS",
          payload: {
            ...response.data,
            error: null,
            message: "STORE_CATEGORY_SUCCESS",
          },
        });

        dispatch({
          type: "END_LOADING",
          payload: { ...data, error: null, message: "END_LOADING" },
        });
      })
      .catch((error: AxiosError<GoodsError>) => {
        if (error.response) {
          dispatch({
            type: "GOODS_STORE_CATEGORY_FAIL",
            payload: error.response.data,
          });
          dispatch({
            type: "END_LOADING",
            payload: { ...data, error: null, message: "END_LOADING" },
          });
        } else if (error.request) {
          console.log("No response received:", error.request);
        } else {
          console.log("Error:", error.message);
        }
      });
  };

export const GoodsStoreSubCategory =
  (data: GoodsRequest) => async (dispatch: Dispatch<GoodsActionTypes>) => {
    dispatch({
      type: "START_LOADING",
      payload: { ...data, error: null, message: "START_LOADING" },
    });

    axios
      .post(
        `${process.env.REACT_APP_API_URL}{prefix}{api_name}/{model_name_url}/`,
        data
      )
      .then((response: AxiosResponse<GoodsResponse>) => {
        dispatch({ type: "GOODS_STORE_SUB_CATEGORY", payload: response.data });

        dispatch({
          type: "GOODS_STORE_SUB_CATEGORY_SUCCESS",
          payload: {
            ...response.data,
            error: null,
            message: "STORE_SUB_CATEGORY_SUCCESS",
          },
        });

        dispatch({
          type: "END_LOADING",
          payload: { ...data, error: null, message: "END_LOADING" },
        });
      })
      .catch((error: AxiosError<GoodsError>) => {
        if (error.response) {
          dispatch({
            type: "GOODS_STORE_SUB_CATEGORY_FAIL",
            payload: error.response.data,
          });
          dispatch({
            type: "END_LOADING",
            payload: { ...data, error: null, message: "END_LOADING" },
          });
        } else if (error.request) {
          console.log("No response received:", error.request);
        } else {
          console.log("Error:", error.message);
        }
      });
  };

export const GoodsStoreProduct =
  (data: GoodsRequest) => async (dispatch: Dispatch<GoodsActionTypes>) => {
    dispatch({
      type: "START_LOADING",
      payload: { ...data, error: null, message: "START_LOADING" },
    });

    axios
      .post(
        `${process.env.REACT_APP_API_URL}{prefix}{api_name}/{model_name_url}/`,
        data
      )
      .then((response: AxiosResponse<GoodsResponse>) => {
        dispatch({ type: "GOODS_STORE_PRODUCT", payload: response.data });

        dispatch({
          type: "GOODS_STORE_PRODUCT_SUCCESS",
          payload: {
            ...response.data,
            error: null,
            message: "STORE_PRODUCT_SUCCESS",
          },
        });

        dispatch({
          type: "END_LOADING",
          payload: { ...data, error: null, message: "END_LOADING" },
        });
      })
      .catch((error: AxiosError<GoodsError>) => {
        if (error.response) {
          dispatch({
            type: "GOODS_STORE_PRODUCT_FAIL",
            payload: error.response.data,
          });
          dispatch({
            type: "END_LOADING",
            payload: { ...data, error: null, message: "END_LOADING" },
          });
        } else if (error.request) {
          console.log("No response received:", error.request);
        } else {
          console.log("Error:", error.message);
        }
      });
  };
