import { Dispatch } from "redux";
import {
  TargetActionTypes,
  TargetRequest,
  TargetState,
} from "../types/target_types";
import axios from "axios";
axios.defaults.withCredentials = true;

export const postTarget =
  (data: TargetState) => (dispatch: Dispatch<TargetActionTypes>) => {
    try {
      dispatch({
        type: "START_LOADING",
        payload: { ...data, error: null, message: "START_LOADING" },
      });

      dispatch({ type: "POST_TARGET", payload: data });

      dispatch({
        type: "POST_SUCCESS_TARGET",
        payload: { ...data, error: null, message: "POST_SUCCESS" },
      });
      dispatch({
        type: "END_LOADING",
        payload: { ...data, error: null, message: "END_LOADING" },
      });
    } catch (error) {
      dispatch({
        type: "POST_FAIL_TARGET",
        payload: { ...data, error: error, message: "POST_FAIL" },
      });
      dispatch({
        type: "END_LOADING",
        payload: { ...data, error: null, message: "END_LOADING" },
      });
    }
  };

export const putTarget =
  (data: TargetState) => (dispatch: Dispatch<TargetActionTypes>) => {
    try {
      dispatch({
        type: "START_LOADING",
        payload: { ...data, error: null, message: "START_LOADING" },
      });

      dispatch({ type: "PUT_TARGET", payload: data });

      dispatch({
        type: "PUT_SUCCESS_TARGET",
        payload: { ...data, error: null, message: "PUT_SUCCESS" },
      });
      dispatch({
        type: "END_LOADING",
        payload: { ...data, error: null, message: "END_LOADING" },
      });
    } catch (error) {
      dispatch({
        type: "PUT_FAIL_TARGET",
        payload: { ...data, error: error, message: "PUT_FAIL" },
      });
      dispatch({
        type: "END_LOADING",
        payload: { ...data, error: null, message: "END_LOADING" },
      });
    }
  };

export const updateTarget =
  (data: TargetState) => (dispatch: Dispatch<TargetActionTypes>) => {
    try {
      dispatch({
        type: "START_LOADING",
        payload: { ...data, error: null, message: "START_LOADING" },
      });

      dispatch({ type: "UPDATE_TARGET", payload: data });

      dispatch({
        type: "UPDATE_SUCCESS_TARGET",
        payload: { ...data, error: null, message: "UPDATE_SUCCESS" },
      });
      dispatch({
        type: "END_LOADING",
        payload: { ...data, error: null, message: "END_LOADING" },
      });
    } catch (error) {
      dispatch({
        type: "UPDATE_FAIL_TARGET",
        payload: { ...data, error: error, message: "UPDATE_FAIL" },
      });
      dispatch({
        type: "END_LOADING",
        payload: { ...data, error: null, message: "END_LOADING" },
      });
    }
  };

export const deleteTarget =
  (data: TargetState) => (dispatch: Dispatch<TargetActionTypes>) => {
    try {
      dispatch({
        type: "START_LOADING",
        payload: { ...data, error: null, message: "START_LOADING" },
      });

      dispatch({ type: "DELETE_TARGET", payload: data });

      dispatch({
        type: "DELETE_SUCCESS_TARGET",
        payload: { ...data, error: null, message: "DELETE_SUCCESS" },
      });
      dispatch({
        type: "END_LOADING",
        payload: { ...data, error: null, message: "END_LOADING" },
      });
    } catch (error) {
      dispatch({
        type: "DELETE_FAIL_TARGET",
        payload: { ...data, error: error, message: "DELETE_FAIL" },
      });
      dispatch({
        type: "END_LOADING",
        payload: { ...data, error: null, message: "END_LOADING" },
      });
    }
  };

export const getTarget =
  (data: TargetRequest) => async (dispatch: Dispatch<TargetActionTypes>) => {
    try {
      dispatch({
        type: "START_LOADING",
        payload: { ...data, error: null, message: "START_LOADING" },
      });

      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/api/lawyer/target?id=0&name=''`
      );
      dispatch({ type: "GET_TARGET", payload: response.data });

      dispatch({
        type: "GET_SUCCESS_TARGET",
        payload: { ...response.data, error: null, message: "GET_SUCCESS" },
      });
      dispatch({
        type: "END_LOADING",
        payload: { ...data, error: null, message: "END_LOADING" },
      });
    } catch (error) {
      dispatch({
        type: "GET_FAIL_TARGET",
        payload: {
          error: error,
          message: "GET_FAIL",
          id: 0,
          name: "",
          targets: [],
        },
      });
      dispatch({
        type: "END_LOADING",
        payload: { ...data, error: null, message: "END_LOADING" },
      });
    }
  };
// [ANCHOR_1]
