import { Dispatch } from "redux";
import {
  TrashActionTypes,
  TrashRequest,
  TrashResponse,
  TrashError,
} from "../types/trash_types";
import axios, { AxiosError, AxiosResponse } from "axios";
axios.defaults.withCredentials = true;

export const TrashGetTrash =
  (data: TrashRequest) => async (dispatch: Dispatch<TrashActionTypes>) => {
    dispatch({
      type: "TRASH_START_LOADING",
      payload: { ...data, error: null, message: "START_LOADING" },
    });

    axios
      .get(
        `${process.env.REACT_APP_API_URL}/api/trash/folder?id=${data.id}&name=${data.name}&case_id=${data.case?.id}`
      )
      .then((response: AxiosResponse<TrashResponse>) => {
        dispatch({ type: "TRASH_GET_TRASH", payload: response.data });

        dispatch({
          type: "TRASH_GET_TRASH_SUCCESS",
          payload: {
            ...response.data,
            error: null,
            message: "GET_TRASH_SUCCESS",
          },
        });

        dispatch({
          type: "TRASH_END_LOADING",
          payload: { ...data, error: null, message: "END_LOADING" },
        });
      })
      .catch((error: AxiosError<TrashError>) => {
        if (error.response) {
          dispatch({
            type: "TRASH_GET_TRASH_FAIL",
            payload: error.response.data,
          });
          dispatch({
            type: "TRASH_END_LOADING",
            payload: { ...data, error: null, message: "END_LOADING" },
          });
        } else if (error.request) {
          console.log("No response received:", error.request);
        } else {
          console.log("Error:", error.message);
        }
      });
  };

export const TrashDeleteDocument =
  (data: TrashRequest) => async (dispatch: Dispatch<TrashActionTypes>) => {
    dispatch({
      type: "TRASH_START_LOADING",
      payload: { ...data, error: null, message: "START_LOADING" },
    });

    axios
      .delete(`${process.env.REACT_APP_API_URL}/api/trash/document/${data.id}`)
      .then((response: AxiosResponse<TrashResponse>) => {
        dispatch({
          type: "TRASH_DELETE_DOCUMENT",
          payload: { ...response.data, id: data.id },
        });

        dispatch({
          type: "TRASH_DELETE_DOCUMENT_SUCCESS",
          payload: {
            ...response.data,
            error: null,
            message: "DELETE_DOCUMENT_SUCCESS",
          },
        });

        dispatch({
          type: "TRASH_END_LOADING",
          payload: { ...data, error: null, message: "END_LOADING" },
        });
      })
      .catch((error: AxiosError<TrashError>) => {
        if (error.response) {
          dispatch({
            type: "TRASH_DELETE_DOCUMENT_FAIL",
            payload: error.response.data,
          });
          dispatch({
            type: "TRASH_END_LOADING",
            payload: { ...data, error: null, message: "END_LOADING" },
          });
        } else if (error.request) {
          console.log("No response received:", error.request);
        } else {
          console.log("Error:", error.message);
        }
      });
  };

export const TrashPostFolder =
  (data: TrashRequest) => async (dispatch: Dispatch<TrashActionTypes>) => {
    dispatch({
      type: "TRASH_START_LOADING",
      payload: { ...data, error: null, message: "START_LOADING" },
    });

    axios
      .post(`${process.env.REACT_APP_API_URL}/api/trash/folder`, data)
      .then((response) => {
        dispatch({ type: "TRASH_POST_FOLDER", payload: response.data });

        dispatch({
          type: "TRASH_END_LOADING",
          payload: { ...data, error: null, message: "END_LOADING" },
        });

        dispatch({
          type: "TRASH_POST_FOLDER_SUCCESS",
          payload: {
            ...response.data,
            error: null,
            message: "POST_FOLDER_SUCCESS",
          },
        });
      })
      .catch((error: AxiosError<TrashError>) => {
        if (error.response) {
          dispatch({
            type: "TRASH_POST_FOLDER_FAIL",
            payload: error.response.data,
          });
          dispatch({
            type: "TRASH_END_LOADING",
            payload: { ...data, error: null, message: "END_LOADING" },
          });
        } else if (error.request) {
          console.log("No response received:", error.request);
        } else {
          console.log("Error:", error.message);
        }
      });
  };

export const TrashDeleteFolder =
  (data: TrashRequest) => async (dispatch: Dispatch<TrashActionTypes>) => {
    dispatch({
      type: "TRASH_START_LOADING",
      payload: { ...data, error: null, message: "START_LOADING" },
    });

    axios
      .delete(
        `${process.env.REACT_APP_API_URL}/api/trash/folder?id=${data.id}&name=${data.name}`
      )
      .then((response: AxiosResponse<TrashResponse>) => {
        dispatch({ type: "TRASH_DELETE_FOLDER", payload: response.data });

        dispatch({
          type: "TRASH_DELETE_FOLDER_SUCCESS",
          payload: {
            ...response.data,
            error: null,
            message: "DELETE_FOLDER_SUCCESS",
          },
        });

        dispatch({
          type: "TRASH_END_LOADING",
          payload: { ...data, error: null, message: "END_LOADING" },
        });
      })
      .catch((error: AxiosError<TrashError>) => {
        if (error.response) {
          dispatch({
            type: "TRASH_DELETE_FOLDER_FAIL",
            payload: error.response.data,
          });
          dispatch({
            type: "TRASH_END_LOADING",
            payload: { ...data, error: null, message: "END_LOADING" },
          });
        } else if (error.request) {
          console.log("No response received:", error.request);
        } else {
          console.log("Error:", error.message);
        }
      });
  };

export const TrashPostDocument =
  (data: TrashRequest, files: File[]) =>
  async (dispatch: Dispatch<TrashActionTypes>) => {
    dispatch({
      type: "TRASH_START_LOADING",
      payload: { ...data, error: null, message: "START_LOADING" },
    });

    const formData = new FormData();
    formData.append(
      "folder_id",
      data.folder_id ? data.folder_id.toString() : ""
    );
    formData.append("case_id", data.case_id ? data.case_id.toString() : "");
    files.forEach((file) => {
      formData.append("name", file.name);
      formData.append("files", file);
    });

    axios
      .post(`${process.env.REACT_APP_API_URL}/api/trash/document/`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((response: AxiosResponse<TrashResponse>) => {
        dispatch({ type: "TRASH_POST_DOCUMENT", payload: response.data });

        setTimeout(() => {
          dispatch({
            type: "TRASH_POST_DOCUMENT_SUCCESS",
            payload: {
              ...response.data,
              error: null,
              message: "POST_DOCUMENT_SUCCESS",
            },
          });
        }, 3000);

        dispatch({
          type: "TRASH_END_LOADING",
          payload: { ...data, error: null, message: "END_LOADING" },
        });
      })
      .catch((error: AxiosError<TrashError>) => {
        if (error.response) {
          dispatch({
            type: "TRASH_POST_DOCUMENT_FAIL",
            payload: error.response.data,
          });
          dispatch({
            type: "TRASH_END_LOADING",
            payload: { ...data, error: error, message: "END_LOADING" },
          });
        } else if (error.request) {
          console.log("No response received:", error.request);
        } else {
          console.log("Error:", error.message);
        }
      });
  };

export const TrashUpdateFolder =
  (data: TrashRequest) => async (dispatch: Dispatch<TrashActionTypes>) => {
    dispatch({
      type: "TRASH_START_LOADING",
      payload: { ...data, error: null, message: "START_LOADING" },
    });

    axios
      .put(
        `${process.env.REACT_APP_API_URL}{prefix}{api_name}/{model_name_url}/`,
        data
      )
      .then((response: AxiosResponse<TrashResponse>) => {
        dispatch({ type: "TRASH_UPDATE_FOLDER", payload: response.data });

        dispatch({
          type: "TRASH_UPDATE_FOLDER_SUCCESS",
          payload: {
            ...response.data,
            error: null,
            message: "UPDATE_FOLDER_SUCCESS",
          },
        });

        dispatch({
          type: "TRASH_END_LOADING",
          payload: { ...data, error: null, message: "END_LOADING" },
        });
      })
      .catch((error: AxiosError<TrashError>) => {
        if (error.response) {
          dispatch({
            type: "TRASH_UPDATE_FOLDER_FAIL",
            payload: error.response.data,
          });
          dispatch({
            type: "TRASH_END_LOADING",
            payload: { ...data, error: null, message: "END_LOADING" },
          });
        } else if (error.request) {
          console.log("No response received:", error.request);
        } else {
          console.log("Error:", error.message);
        }
      });
  };

export const TrashUpdateDocument =
  (data: TrashRequest) => async (dispatch: Dispatch<TrashActionTypes>) => {
    dispatch({
      type: "TRASH_START_LOADING",
      payload: { ...data, error: null, message: "START_LOADING" },
    });

    axios
      .put(
        `${process.env.REACT_APP_API_URL}{prefix}{api_name}/{model_name_url}/`,
        data
      )
      .then((response: AxiosResponse<TrashResponse>) => {
        dispatch({ type: "TRASH_UPDATE_DOCUMENT", payload: response.data });

        dispatch({
          type: "TRASH_UPDATE_DOCUMENT_SUCCESS",
          payload: {
            ...response.data,
            error: null,
            message: "UPDATE_DOCUMENT_SUCCESS",
          },
        });

        dispatch({
          type: "TRASH_END_LOADING",
          payload: { ...data, error: null, message: "END_LOADING" },
        });
      })
      .catch((error: AxiosError<TrashError>) => {
        if (error.response) {
          dispatch({
            type: "TRASH_UPDATE_DOCUMENT_FAIL",
            payload: error.response.data,
          });
          dispatch({
            type: "TRASH_END_LOADING",
            payload: { ...data, error: null, message: "END_LOADING" },
          });
        } else if (error.request) {
          console.log("No response received:", error.request);
        } else {
          console.log("Error:", error.message);
        }
      });
  };
// [ANCHOR_1]
