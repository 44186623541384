
import { OutcomeTypeActionTypes, OutcomeTypeState } from '../types/outcome_type_types';
import axios from 'axios'
axios.defaults.withCredentials = true;

const initialState: OutcomeTypeState = {
    id: 0,
    name: '',
    outcome_types: [],
    error: null,
    message: ''
};

const OutcomeTypeReducer = (state = initialState, action: OutcomeTypeActionTypes) => {
    switch (action.type) {
        
        case 'POST_OUTCOME_TYPE':
            // const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/`, {});
            // console.log(response)
            return {...state, data: []};
        case 'POST_SUCCESS_OUTCOME_TYPE':
            return {...state, error: null, message: action.payload.message};
        case 'POST_FAIL_OUTCOME_TYPE':
            return {...state, error: action.payload.error, message: action.payload.message};
        
        case 'PUT_OUTCOME_TYPE':
            // const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/`, {});
            // console.log(response)
            return {...state, data: []};
        case 'PUT_SUCCESS_OUTCOME_TYPE':
            return {...state, error: null, message: action.payload.message};
        case 'PUT_FAIL_OUTCOME_TYPE':
            return {...state, error: action.payload.error, message: action.payload.message};
        
        case 'UPDATE_OUTCOME_TYPE':
            // const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/`, {});
            // console.log(response)
            return {...state, data: []};
        case 'UPDATE_SUCCESS_OUTCOME_TYPE':
            return {...state, error: null, message: action.payload.message};
        case 'UPDATE_FAIL_OUTCOME_TYPE':
            return {...state, error: action.payload.error, message: action.payload.message};
            
        case 'DELETE_OUTCOME_TYPE':
            // const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/`, {});
            // console.log(response)
            return {...state, data: []};
        case 'DELETE_SUCCESS_OUTCOME_TYPE':
            return {...state, error: null, message: action.payload.message};
        case 'DELETE_FAIL_OUTCOME_TYPE':
            return {...state, error: action.payload.error, message: action.payload.message};
        
        case 'GET_OUTCOME_TYPE':
            // const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/`, {});
            // console.log(response)
            return {...state, outcome_types: action.payload.outcome_types};
        case 'GET_SUCCESS_OUTCOME_TYPE':
            return {...state, error: null, message: action.payload.message};
        case 'GET_FAIL_OUTCOME_TYPE':
            return {...state, error: action.payload.error, message: action.payload.message};
// [ANCHOR_1]
        case 'START_LOADING':
            return {...state, loading: true};
        case 'END_LOADING':
            return {...state, loading: false};
        default:
            return state;
    }
};

export default OutcomeTypeReducer;
    