import { connect, ConnectedProps } from "react-redux";
import { RootState } from "../../store";
import NoAuthFullWidthLayout from "../../layouts/no_auth_full_width_layout";
import { NavLink } from "react-router-dom";
import { useState } from "react";
import ThreeJSComponent from "./components/cool-circles";
import Part1 from "./components/part_1";
import { FaWhatsapp, FaPhone, FaUser } from "react-icons/fa";

const Landing: React.FC<PropsFromRedux> = ({}: PropsFromRedux) => {
  const [dark, setDark] = useState(false);
  const [feel_of_view, setFeelOfView] = useState<number>(70);

  const handleMouseMove = (event: React.MouseEvent<HTMLDivElement>) => {
    const clientX = event.clientX;
    const clientY = event.clientY;
    // Calculate the feel_of_view based on the client cursor position
    const newFeelOfView = Math.floor(clientX / clientY);
    console.log(newFeelOfView);
    //setFeelOfView(newFeelOfView);
  };

  return (
    <NoAuthFullWidthLayout>
      {/* <div className="absolute z-10">
        <ThreeJSComponent />
      </div> */}
      <div className="relative min-h-screen items-center flex flex-wrap w-full py-12 md:py-16 text-slate-700 dark:text-stone-50">
        <div className="flex flex-col flex-wrap w-full">
          <h2 className="flex md:hidden text-4xl px-3 mx-auto">
            <strong className="text-acidjelly-blue-700">ACID</strong>{" "}
            <strong className="text-acidjelly-red">JELLY</strong>
          </h2>
          <h2 className="z-20 pt-2 text-2xl md:text-4xl text-center w-full font-bold">
            Somos tu mejor opción para esa actualización que quieres
          </h2>
          <p className="mx-auto p-1">Entregamos exactamente lo que quieres</p>
          <div className="flex flex-wrap w-full md:w-1/2 mx-auto">
            {/* here we have the link to open a whatsapp convesation whit our number +51951762872 */}
            <a
              href="https://api.whatsapp.com/send?phone=51951762872&text=Hola!%20Quiero%20una%20cotizaci%C3%B3n%20para%20mi%20proyecto!"
              target="_blank"
              className="gap-3 flex items-center mx-auto mt-4 px-4 py-2 dark:bg-acidjelly-blue-700 bg-acidjelly-blue-800 rounded-lg font-bold text-white"
              rel="noreferrer"
            >
              <FaWhatsapp /> Whatsapp
            </a>
            {/* here one to call us */}
            <a
              href="tel:+51951762872"
              className="gap-3 flex items-center md:hidden mx-auto mt-4 px-4 py-2 dark:bg-acidjelly-blue-800 bg-acidjelly-blue-700 rounded-lg font-bold text-white"
            >
              <FaPhone />
              Llamanos
            </a>
            <a
              href="/login"
              target="_blank"
              className="gap-3 flex items-center mx-auto mt-4 px-4 py-2 dark:bg-acidjelly-blue-800 bg-acidjelly-blue-700 rounded-lg font-bold text-white"
              rel="noreferrer"
            >
              <FaUser /> Acceder
            </a>
          </div>
        </div>
      </div>

      <div className="w-full flex flex-wrap items-center justify-center relative min-h-screen p-2">
        <div className="w-full z-10 text-4xl font-bold text-gray-900 dark:text-gray-50">
          <h2>Legal App</h2>
          <h2>Version 1.0.0</h2>
          <small>
            Stack:
            <span className="p-1"></span>
            <em>{"React(Ts) + Django + Any Database"}</em>
          </small>
        </div>
        <div className="w-full flex">
          <div className="flex items-center w-full sm:w-full md:w-1/2 lg:w-1/2 p-2">
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Alias iusto
            minima voluptates, nemo aut a cum amet culpa rem voluptatem
            molestias corrupti quia, dicta explicabo. Quae nostrum ut repellat
            officia.
          </div>
          <div className="w-full sm:w-full md:w-1/2 lg:w-1/2">
            <img
              className="w-full mx-auto rounded-3xl"
              src="/images/services/legal_app_under_construction.jpeg"
              alt=""
            />
          </div>
        </div>

        <NavLink
          to="/login"
          className="relative flex flex-col items-center justify-center h-52 w-1/2 cursor-pointer"
        >
          <div className="text-4xl flex flex-col items-center justify-center z-10 h-full w-full text-center bg-stone-300/50 dark:bg-slate-900/60 rounded-3xl">
            <h2>Ir al sistema</h2>
          </div>
        </NavLink>
      </div>
      <Part1 />
    </NoAuthFullWidthLayout>
  );
};

const mapStateToProps = (state: RootState) => ({});

const mapDispatchToProps = {};

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(Landing);
