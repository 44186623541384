import React, { useEffect, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { HiCheck } from "react-icons/hi";
import { TextField } from "@mui/material";
import { CustomButton } from "../../../core/components/buttons/custom_button";
import { FolderRequest } from "../redux/types/folder_types";
import { CaseRequest } from "../../cases/redux/types/case_types";
import { TrashRequest } from "../redux/types/trash_types";

type Props = {
  colors: string[];
  icon: React.ReactElement;
  label: string;
  postFolder: (folder: TrashRequest) => void;
  parent_folder?: FolderRequest | null;
  case: CaseRequest | null;
};

export default function NewFolderModal(props: Props) {
  const [isOpen, setIsOpen] = useState(false);

  function closeModal() {
    setIsOpen(false);
  }

  function openModal() {
    setIsOpen(true);
  }

  const initialRequestData: FolderRequest = {
    id: "",
    name: "",
    created_by: {
      id: 1,
      name: "",
    },
    is_open: false,
    parent_folder_model: props.parent_folder,
    case: props.case,
    folder: "",
  };

  const [formData, setFormData] = useState<FolderRequest>(initialRequestData);

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    let data: TrashRequest = {
      ...formData,
      type: "folder",
    };
    props.postFolder(data);
    closeModal();
  };

  useEffect(() => {}, []);

  return (
    <>
      <CustomButton
        colors={props.colors}
        icon={props.icon}
        label={props.label}
        onClick={openModal}
      />

      <Transition onClose={closeModal} appear show={isOpen} as={Dialog}>
        <Dialog
          onClose={closeModal}
          className="fixed inset-0 z-50 overflow-y-auto"
          aria-labelledby="modal-title"
          open={isOpen}
        >
          <div className="flex items-center justify-center min-h-screen">
            <Transition.Child
              as={Dialog.Overlay}
              className="fixed inset-0 bg-black opacity-30"
            />
            <div className="bg-white rounded-lg shadow-lg p-4 w-full md:w-1/2 mx-auto z-10">
              <Dialog.Title
                id="modal-title"
                className="text-lg md:text-xl p-1 font-medium leading-6 text-gray-900"
              >
                <strong>Nuevo Folder</strong>
              </Dialog.Title>

              <div>
                {props.parent_folder && props.parent_folder?.id !== "" ? (
                  <div className="p-1">
                    <strong>Parent Folder:</strong> {props.parent_folder?.id} -{" "}
                    {props.parent_folder?.name}
                  </div>
                ) : (
                  <></>
                )}
              </div>
              <form
                onSubmit={handleSubmit}
                className="flex flex-wrap items-left"
              >
                <div className="p-1 w-full">
                  <TextField
                    inputProps={{ inputMode: "text" }}
                    name="name"
                    id="name"
                    label="Nombre"
                    variant="outlined"
                    onChange={handleInputChange}
                    value={formData.name}
                    className="w-full"
                  />
                </div>

                <div className="w-full pt-2">
                  <p className="text-sm text-gray-500 text-right">
                    Todo listo para enviar?
                  </p>
                </div>

                <div className="w-full pt-2 flex justify-end space-x-2">
                  <button
                    type="button"
                    className="inline-flex justify-center px-4 py-2 text-sm font-medium text-gray-700 bg-gray-100 border border-gray-200 rounded-md hover:bg-gray-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                    onClick={closeModal}
                  >
                    Cancelar
                  </button>
                  <button
                    type="submit"
                    className="inline-flex justify-center px-4 py-2 text-sm font-medium text-white bg-indigo-600 border border-transparent rounded-md hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                  >
                    Si
                    <HiCheck className="ml-2 -mr- h-5 w-5" aria-hidden="true" />
                  </button>
                </div>
              </form>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  );
}
