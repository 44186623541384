import { OutcomeTypes, OutcomeState } from "../types/outcome_types";
import axios from "axios";
axios.defaults.withCredentials = true;

const initialState: OutcomeState = {
  id: 0,
  amount: 0,
  description: "",
  case: null,

  outcomes: [],
  error: null,
  message: "",

  tags: [],
  photo: "",
  start_at: null,
  end_at: null,
  outcome_type: null,
  validated: false,
  created_by: {
    access: "",
    refresh: "",
    id: 1,
    name: "",
  },
  validated_by: null,
  new_one: null,
  hours: 0,
};

const OutcomeReducer = (state = initialState, action: OutcomeTypes) => {
  switch (action.type) {
    case "POST_OUTCOME":
      // const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/`, {});
      // console.log(response)
      return {
        ...state,
        outcomes: [...state.outcomes, action.payload?.new_one!],
      };
    case "POST_SUCCESS_OUTCOME":
      return { ...state, error: null, message: action.payload.message };
    case "POST_FAIL_OUTCOME":
      return {
        ...state,
        error: action.payload.error,
        message: action.payload.message,
      };

    case "PUT_OUTCOME":
      // const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/`, {});
      // console.log(response)
      return { ...state, data: [] };
    case "PUT_SUCCESS_OUTCOME":
      return { ...state, error: null, message: action.payload.message };
    case "PUT_FAIL_OUTCOME":
      return {
        ...state,
        error: action.payload.error,
        message: action.payload.message,
      };

    case "UPDATE_OUTCOME":
      // const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/`, {});
      // console.log(response)
      return { ...state, data: [] };
    case "UPDATE_SUCCESS_OUTCOME":
      return { ...state, error: null, message: action.payload.message };
    case "UPDATE_FAIL_OUTCOME":
      return {
        ...state,
        error: action.payload.error,
        message: action.payload.message,
      };

    case "DELETE_OUTCOME":
      // const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/`, {});
      // console.log(response)
      return { ...state, data: [] };
    case "DELETE_SUCCESS_OUTCOME":
      return { ...state, error: null, message: action.payload.message };
    case "DELETE_FAIL_OUTCOME":
      return {
        ...state,
        error: action.payload.error,
        message: action.payload.message,
      };

    case "GET_OUTCOME":
      // const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/`, {});
      // console.log(response)
      return { ...state, outcomes: action.payload.outcomes };
    case "GET_SUCCESS_OUTCOME":
      return { ...state, error: null, message: action.payload.message };
    case "GET_FAIL_OUTCOME":
      return {
        ...state,
        error: action.payload.error,
        message: action.payload.message,
      };
    // [ANCHOR_1]
    case "START_LOADING":
      return { ...state, loading: true };
    case "END_LOADING":
      return { ...state, loading: false };
    default:
      return state;
  }
};

export default OutcomeReducer;
