import { Dispatch } from "redux";
import {
  OfficeObjectActionTypes,
  OfficeObjectRequest,
  OfficeObjectState,
} from "../types/office_object_types";
import axios from "axios";
axios.defaults.withCredentials = true;

export const postOfficeObject =
  (data: OfficeObjectRequest, files: File[]) =>
  async (dispatch: Dispatch<OfficeObjectActionTypes>) => {
    {
      try {
        dispatch({
          type: "START_LOADING",
          payload: { ...data, error: null, message: "START_LOADING" },
        });
        const formData = new FormData();
        formData.append("office_object_id", data.id ? data.id.toString() : "");
        files.forEach((file) => {
          formData.append("name", file.name);
          formData.append("files", file);
        });

        const response = await axios.post(
          `${process.env.REACT_APP_API_URL}/api/lawyer/office-object`,
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );

        dispatch({ type: "POST_OFFICE_OBJECT", payload: response.data });
        dispatch({
          type: "END_LOADING",
          payload: { ...data, error: null, message: "END_LOADING" },
        });
        dispatch({
          type: "POST_SUCCESS_OFFICE_OBJECT",
          payload: {
            ...response.data,
            error: null,
            message: "POST_OFFICE_OBJECT_SUCCESS",
          },
        });
      } catch (error) {
        dispatch({
          type: "END_LOADING",
          payload: { ...data, error: null, message: "END_LOADING" },
        });
        dispatch({
          type: "POST_FAIL_OFFICE_OBJECT",
          payload: {
            ...data,
            error: error,
            message: "POST_OFFICE_OBJECT_FAIL",
          },
        });
      }
    }
  };

export const putOfficeObject =
  (data: OfficeObjectRequest) =>
  async (dispatch: Dispatch<OfficeObjectActionTypes>) => {
    {
      try {
        dispatch({
          type: "START_LOADING",
          payload: { ...data, error: null, message: "START_LOADING" },
        });
        const response = await axios.put(
          `${process.env.REACT_APP_API_URL}/api/lawyer/office-object?id=${data.id}&name=${data.name}`
        );
        dispatch({ type: "PUT_OFFICE_OBJECT", payload: response.data });
        dispatch({
          type: "END_LOADING",
          payload: { ...data, error: null, message: "END_LOADING" },
        });
        dispatch({
          type: "PUT_SUCCESS_OFFICE_OBJECT",
          payload: {
            ...response.data,
            error: null,
            message: "PUT_OFFICE_OBJECT_SUCCESS",
          },
        });
      } catch (error) {
        dispatch({
          type: "END_LOADING",
          payload: { ...data, error: null, message: "END_LOADING" },
        });
        dispatch({
          type: "PUT_FAIL_OFFICE_OBJECT",
          payload: { ...data, error: error, message: "PUT_OFFICE_OBJECT_FAIL" },
        });
      }
    }
  };

export const updateOfficeObject =
  (data: OfficeObjectRequest) =>
  async (dispatch: Dispatch<OfficeObjectActionTypes>) => {
    {
      try {
        dispatch({
          type: "START_LOADING",
          payload: { ...data, error: null, message: "START_LOADING" },
        });
        const response = await axios.put(
          `${process.env.REACT_APP_API_URL}/api/lawyer/office-object?id=${data.id}&name=${data.name}`
        );
        dispatch({ type: "UPDATE_OFFICE_OBJECT", payload: response.data });
        dispatch({
          type: "END_LOADING",
          payload: { ...data, error: null, message: "END_LOADING" },
        });
        dispatch({
          type: "UPDATE_SUCCESS_OFFICE_OBJECT",
          payload: {
            ...response.data,
            error: null,
            message: "UPDATE_OFFICE_OBJECT_SUCCESS",
          },
        });
      } catch (error) {
        dispatch({
          type: "END_LOADING",
          payload: { ...data, error: null, message: "END_LOADING" },
        });
        dispatch({
          type: "UPDATE_FAIL_OFFICE_OBJECT",
          payload: {
            ...data,
            error: error,
            message: "UPDATE_OFFICE_OBJECT_FAIL",
          },
        });
      }
    }
  };

export const deleteOfficeObject =
  (data: OfficeObjectRequest) =>
  async (dispatch: Dispatch<OfficeObjectActionTypes>) => {
    {
      try {
        dispatch({
          type: "START_LOADING",
          payload: { ...data, error: null, message: "START_LOADING" },
        });
        const response = await axios.delete(
          `${process.env.REACT_APP_API_URL}/api/lawyer/office-object?id=${data.id}&name=${data.name}`
        );
        dispatch({ type: "DELETE_OFFICE_OBJECT", payload: response.data });
        dispatch({
          type: "END_LOADING",
          payload: { ...data, error: null, message: "END_LOADING" },
        });
        dispatch({
          type: "DELETE_SUCCESS_OFFICE_OBJECT",
          payload: {
            ...response.data,
            error: null,
            message: "DELETE_OFFICE_OBJECT_SUCCESS",
          },
        });
      } catch (error) {
        dispatch({
          type: "END_LOADING",
          payload: { ...data, error: null, message: "END_LOADING" },
        });
        dispatch({
          type: "DELETE_FAIL_OFFICE_OBJECT",
          payload: {
            ...data,
            error: error,
            message: "DELETE_OFFICE_OBJECT_FAIL",
          },
        });
      }
    }
  };

export const getOfficeObject =
  (data: OfficeObjectRequest) =>
  async (dispatch: Dispatch<OfficeObjectActionTypes>) => {
    {
      try {
        dispatch({
          type: "START_LOADING",
          payload: { ...data, error: null, message: "START_LOADING" },
        });
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/api/lawyer/office-object?id=${data.id}&name=${data.name}`
        );

        dispatch({ type: "GET_OFFICE_OBJECT", payload: response.data });
        dispatch({
          type: "END_LOADING",
          payload: { ...data, error: null, message: "END_LOADING" },
        });
        dispatch({
          type: "GET_SUCCESS_OFFICE_OBJECT",
          payload: {
            ...response.data,
            error: null,
            message: "GET_OFFICE_OBJECT_SUCCESS",
          },
        });
      } catch (error) {
        dispatch({
          type: "END_LOADING",
          payload: { ...data, error: null, message: "END_LOADING" },
        });
        dispatch({
          type: "GET_FAIL_OFFICE_OBJECT",
          payload: { ...data, error: error, message: "GET_OFFICE_OBJECT_FAIL" },
        });
      }
    }
  };

export const selectOfficeObject =
  (data: OfficeObjectRequest) =>
  (dispatch: Dispatch<OfficeObjectActionTypes>) => {
    {
      try {
        dispatch({ type: "SELECT_OFFICE_OBJECT", payload: data });

        dispatch({
          type: "SELECT_SUCCESS_OFFICE_OBJECT",
          payload: { ...data, error: null, message: "SELECT_SUCCESS" },
        });
      } catch (error) {
        dispatch({
          type: "SELECT_FAIL_OFFICE_OBJECT",
          payload: { ...data, error: error, message: "SELECT_FAIL" },
        });
      }
    }
  };
// [ANCHOR_1]
