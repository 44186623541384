import { Dispatch } from "redux";
import {
  IncomeTypeActionTypes,
  IncomeTypeRequest,
  IncomeTypeState,
} from "../types/income_type_types";
import axios from "axios";
axios.defaults.withCredentials = true;

export const postIncomeType =
  (data: IncomeTypeState) => (dispatch: Dispatch<IncomeTypeActionTypes>) => {
    {
      try {
        dispatch({ type: "POST_INCOME_TYPE", payload: data });

        dispatch({
          type: "POST_SUCCESS_INCOME_TYPE",
          payload: { ...data, error: null, message: "POST_SUCCESS" },
        });
      } catch (error) {
        dispatch({
          type: "POST_FAIL_INCOME_TYPE",
          payload: { ...data, error: error, message: "POST_FAIL" },
        });
      }
    }
  };

export const putIncomeType =
  (data: IncomeTypeState) => (dispatch: Dispatch<IncomeTypeActionTypes>) => {
    {
      try {
        dispatch({ type: "PUT_INCOME_TYPE", payload: data });

        dispatch({
          type: "PUT_SUCCESS_INCOME_TYPE",
          payload: { ...data, error: null, message: "PUT_SUCCESS" },
        });
      } catch (error) {
        dispatch({
          type: "PUT_FAIL_INCOME_TYPE",
          payload: { ...data, error: error, message: "PUT_FAIL" },
        });
      }
    }
  };

export const updateIncomeType =
  (data: IncomeTypeState) => (dispatch: Dispatch<IncomeTypeActionTypes>) => {
    {
      try {
        dispatch({ type: "UPDATE_INCOME_TYPE", payload: data });

        dispatch({
          type: "UPDATE_SUCCESS_INCOME_TYPE",
          payload: { ...data, error: null, message: "UPDATE_SUCCESS" },
        });
      } catch (error) {
        dispatch({
          type: "UPDATE_FAIL_INCOME_TYPE",
          payload: { ...data, error: error, message: "UPDATE_FAIL" },
        });
      }
    }
  };

export const deleteIncomeType =
  (data: IncomeTypeRequest) => (dispatch: Dispatch<IncomeTypeActionTypes>) => {
    {
      try {
        dispatch({ type: "DELETE_INCOME_TYPE", payload: data });

        dispatch({
          type: "DELETE_SUCCESS_INCOME_TYPE",
          payload: {
            ...data,
            income_types: [],
            error: null,
            message: "DELETE_SUCCESS",
          },
        });
        dispatch({
          type: "END_LOADING",
          payload: { ...data, error: null, message: "END_LOADING" },
        });
      } catch (error) {
        dispatch({
          type: "END_LOADING",
          payload: { ...data, error: null, message: "END_LOADING" },
        });
        dispatch({
          type: "DELETE_FAIL_INCOME_TYPE",
          payload: { ...data, error: error, message: "DELETE_FAIL" },
        });
      }
    }
  };

export const getIncomeType =
  (data: IncomeTypeRequest) =>
  async (dispatch: Dispatch<IncomeTypeActionTypes>) => {
    {
      try {
        const response = await axios.post(
          `${process.env.REACT_APP_API_URL}/api/lawyer/treasury`,
          { id: 0, search: "income_type", name: "" }
        );
        dispatch({ type: "GET_INCOME_TYPE", payload: response.data });

        dispatch({
          type: "GET_SUCCESS_INCOME_TYPE",
          payload: { ...response.data, error: null, message: "GET_SUCCESS" },
        });
        dispatch({
          type: "END_LOADING",
          payload: { ...data, error: null, message: "END_LOADING" },
        });
      } catch (error) {
        dispatch({
          type: "GET_FAIL_INCOME_TYPE",
          payload: {
            error: error,
            message: "GET_FAIL",
            id: 0,
            name: "",
            income_types: [],
          },
        });
        dispatch({
          type: "END_LOADING",
          payload: { ...data, error: null, message: "END_LOADING" },
        });
      }
    }
  };
// [ANCHOR_1]
