import { Dispatch } from "redux";
import { IncomeTypes, IncomeState } from "../types/income_types";

export const postIncome =
  (data: IncomeState) => (dispatch: Dispatch<IncomeTypes>) => {
    try {
      dispatch({
        type: "START_LOADING",
        payload: { ...data, error: null, message: "END_LOADING" },
      });
      dispatch({ type: "POST_INCOME", payload: data });

      dispatch({
        type: "POST_SUCCESS_INCOME",
        payload: { ...data, error: null, message: "POST_SUCCESS" },
      });
      dispatch({
        type: "END_LOADING",
        payload: { ...data, error: null, message: "END_LOADING" },
      });
    } catch (error) {
      dispatch({
        type: "END_LOADING",
        payload: { ...data, error: null, message: "END_LOADING" },
      });
      dispatch({
        type: "POST_FAIL_INCOME",
        payload: { ...data, error: error, message: "POST_FAIL" },
      });
    }
  };

export const putIncome =
  (data: IncomeState) => (dispatch: Dispatch<IncomeTypes>) => {
    try {
      dispatch({
        type: "START_LOADING",
        payload: { ...data, error: null, message: "END_LOADING" },
      });
      dispatch({ type: "PUT_INCOME", payload: data });

      dispatch({
        type: "PUT_SUCCESS_INCOME",
        payload: { ...data, error: null, message: "PUT_SUCCESS" },
      });
      dispatch({
        type: "END_LOADING",
        payload: { ...data, error: null, message: "END_LOADING" },
      });
    } catch (error) {
      dispatch({
        type: "END_LOADING",
        payload: { ...data, error: null, message: "END_LOADING" },
      });
      dispatch({
        type: "PUT_FAIL_INCOME",
        payload: { ...data, error: error, message: "PUT_FAIL" },
      });
    }
  };

export const updateIncome =
  (data: IncomeState) => (dispatch: Dispatch<IncomeTypes>) => {
    try {
      dispatch({
        type: "START_LOADING",
        payload: { ...data, error: null, message: "END_LOADING" },
      });
      dispatch({ type: "UPDATE_INCOME", payload: data });

      dispatch({
        type: "UPDATE_SUCCESS_INCOME",
        payload: { ...data, error: null, message: "UPDATE_SUCCESS" },
      });
      dispatch({
        type: "END_LOADING",
        payload: { ...data, error: null, message: "END_LOADING" },
      });
    } catch (error) {
      dispatch({
        type: "END_LOADING",
        payload: { ...data, error: null, message: "END_LOADING" },
      });
      dispatch({
        type: "UPDATE_FAIL_INCOME",
        payload: { ...data, error: error, message: "UPDATE_FAIL" },
      });
    }
  };

export const deleteIncome =
  (data: IncomeState) => (dispatch: Dispatch<IncomeTypes>) => {
    try {
      dispatch({
        type: "START_LOADING",
        payload: { ...data, error: null, message: "END_LOADING" },
      });
      dispatch({ type: "DELETE_INCOME", payload: data });

      dispatch({
        type: "DELETE_SUCCESS_INCOME",
        payload: { ...data, error: null, message: "DELETE_SUCCESS" },
      });
      dispatch({
        type: "END_LOADING",
        payload: { ...data, error: null, message: "END_LOADING" },
      });
    } catch (error) {
      dispatch({
        type: "END_LOADING",
        payload: { ...data, error: null, message: "END_LOADING" },
      });
      dispatch({
        type: "DELETE_FAIL_INCOME",
        payload: { ...data, error: error, message: "DELETE_FAIL" },
      });
    }
  };

export const getIncome =
  (data: IncomeState) => (dispatch: Dispatch<IncomeTypes>) => {
    try {
      dispatch({
        type: "START_LOADING",
        payload: { ...data, error: null, message: "END_LOADING" },
      });
      dispatch({ type: "GET_INCOME", payload: data });

      dispatch({
        type: "GET_SUCCESS_INCOME",
        payload: { ...data, error: null, message: "GET_SUCCESS" },
      });
      dispatch({
        type: "END_LOADING",
        payload: { ...data, error: null, message: "END_LOADING" },
      });
    } catch (error) {
      dispatch({
        type: "END_LOADING",
        payload: { ...data, error: null, message: "END_LOADING" },
      });
      dispatch({
        type: "GET_FAIL_INCOME",
        payload: { ...data, error: error, message: "GET_FAIL" },
      });
    }
  };
// [ANCHOR_1]
