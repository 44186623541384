
import { CategoryActionTypes, CategoryState } from '../types/category_types';

const initialState: CategoryState = {
    id: 0,
    name: '',
    categories: [],
    error: null,
    message: ''
};

const CategoryReducer = (state = initialState, action: CategoryActionTypes) => {
    switch (action.type) {
        
        case 'POST_CATEGORY':
            // const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/`, {});
            // console.log(response)
            return {...state, categories: action.payload.categories};
        case 'POST_SUCCESS_CATEGORY':
            return {...state, error: null, message: action.payload.message};
        case 'POST_FAIL_CATEGORY':
            return {...state, error: action.payload.error, message: action.payload.message};
        
        case 'PUT_CATEGORY':
            // const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/`, {});
            // console.log(response)
            return {...state, data: []};
        case 'PUT_SUCCESS_CATEGORY':
            return {...state, error: null, message: action.payload.message};
        case 'PUT_FAIL_CATEGORY':
            return {...state, error: action.payload.error, message: action.payload.message};
        
        case 'UPDATE_CATEGORY':
            // const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/`, {});
            // console.log(response)
            return {...state, data: []};
        case 'UPDATE_SUCCESS_CATEGORY':
            return {...state, error: null, message: action.payload.message};
        case 'UPDATE_FAIL_CATEGORY':
            return {...state, error: action.payload.error, message: action.payload.message};
            
        case 'DELETE_CATEGORY':
            // const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/`, {});
            // console.log(response)
            return {...state, data: []};
        case 'DELETE_SUCCESS_CATEGORY':
            return {...state, error: null, message: action.payload.message};
        case 'DELETE_FAIL_CATEGORY':
            return {...state, error: action.payload.error, message: action.payload.message};
        
        case 'GET_CATEGORY':
            // const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/`, {});
            // console.log(response)
            return {...state, categories: action.payload.categories};
        case 'GET_SUCCESS_CATEGORY':
            return {...state, error: null, message: action.payload.message};
        case 'GET_FAIL_CATEGORY':
            return {...state, error: action.payload.error, message: action.payload.message};
// [ANCHOR_1]

        case 'START_LOADING':
            return {...state, loading: true};
        case 'END_LOADING':
            return {...state, loading: false};

        default:
            return state;
    }
};

export default CategoryReducer;
    