import FullWidthLayout from "../../layouts/full_width_layout";
import { ChangeEvent, useEffect, useState } from "react";
import { RootState } from "../../store";
import { ConnectedProps, connect } from "react-redux";
import Dropzone from "./components/drop_zone";
import FolderExplorer from "./components/folder_explorer";
import NewFolderModal from "./components/new_folder_modal";
import { HiCheck, HiPlusCircle, HiUpload } from "react-icons/hi";
import FolderContent from "./components/folder_content";
import { get_cases } from "../cases/selector";
import Select from "react-select";
import { CustomButton } from "../../core/components/buttons/custom_button";
import { getCase, selectCase } from "../cases/redux/actions/case_action";
import {
  TrashGetTrash,
  TrashPostDocument,
  TrashDeleteDocument,
  TrashPostFolder,
  TrashDeleteFolder,
  TrashUpdateDocument,
  TrashUpdateFolder,
} from "../documents/redux/actions/trash_action";
import { CaseRequest } from "../cases/redux/types/case_types";
import { TrashRequest } from "./redux/types/trash_types";
import { FolderRequest } from "./redux/types/folder_types";
import { DocumentRequest } from "./redux/types/document_types";

const Documents: React.FC<PropsFromRedux> = ({
  trash_state,
  // document_new_ones,
  cases,
  getCase,
  selectCase,

  TrashGetTrash,
  TrashPostDocument,
  TrashDeleteDocument,
  TrashPostFolder,
  TrashDeleteFolder,
}: PropsFromRedux) => {
  const [folders, set_folders] = useState<FolderRequest[]>([]);
  const [documents, set_documents] = useState<DocumentRequest[]>([]);
  const [selected_folder, set_selected_folder] = useState<FolderRequest | null>(
    null
  );
  const [selected_document, set_selected_document] =
    useState<DocumentRequest | null>(null);
  const [selected_case, set_selected_case] = useState<CaseRequest | null>(null);

  const initialRequestData: TrashRequest = {
    id: "",
    name: "",
    created_by: {
      id: 1,
      name: "",
    },
    is_open: false,
    type: "folder",
  };

  const [formData, setFormData] = useState<TrashRequest>(initialRequestData);

  useEffect(() => {
    if (!trash_state) return;
    console.log("trash_state:", trash_state);
    set_folders(trash_state?.folders);
    set_documents(trash_state?.documents);
  }, [trash_state?.folders, trash_state?.documents]);

  useEffect(() => {
    const onInit = async () => {
      try {
        await getCase({
          id: 0,
          new_one: null,
        });
        const url = window.location.search;
        const params = new URLSearchParams(url);
        var case_id = 0;
        if (params.has("case_id")) {
          case_id = parseInt(params.get("case_id") || "0");
          console.log("case_id:", case_id);
          const case_ = cases?.find((c) => c.id === case_id!);
          console.log("case:", cases);
          if (case_) {
            selectCase(case_);
            setFormData({
              ...formData,
              case: case_,
            });
            console.log("case_id:", case_id);
            await TrashGetTrash({
              id: "",
              name: "",
              created_by: {
                id: 0,
                name: "",
              },
              is_open: false,
              documents: [],
              case: {
                id: case_id,
                name: "",
                new_one: null,
              },
              type: "folder",
            });
            await TrashGetTrash({
              id: "",
              name: "",
              folder_id: "",
              documents: undefined,
              created_by: null,
              is_open: false,
              type: "folder",
            });
          }
        } else {
          console.log("case_id not found in the URL");
        }

        console.log("Documents");
      } catch (error) {
        console.error("Error fetching data: ", error);
      } finally {
        // setLoading(false);
      }
    };

    onInit();
  }, []);

  const handleOnDrop = async () => {
    // if (trash_state?.new_ones && trash_state?.new_ones.length > 0) {
    // }
  };

  const handleSelectFolder = async (folder: FolderRequest | null) => {
    console.log("folder:", folder);
    set_selected_folder(folder);
  };

  const handleSelectDocument = async (document: DocumentRequest | null) => {
    set_selected_document(document);
  };

  const handlePostDocument = async (document: TrashRequest, files: File[]) => {
    document.case_id = selected_case?.id!;
    await TrashPostDocument(document, files);
  };

  const onDeleteItem = async (item: DocumentRequest | FolderRequest) => {
    console.log("entro");
    let data: TrashRequest = {
      id: "",
      name: "",
      created_by: {
        id: 1,
        name: "",
      },
      is_open: false,
      type: "folder",
    };
    if ("sub_folders" in item) {
      data.type = "folder";
      await TrashDeleteFolder(data);
    } else {
      data.type = "document";
      await TrashDeleteDocument(data);
    }
  };

  const onUpdateDocument = async (event: ChangeEvent<HTMLInputElement>) => {
    var text = event.target.value;
    await TrashUpdateDocument({
      id: selected_document!.id,
      name: text,
      is_open: false,
      type: "document",
    });
  };

  const onUpdateFolder = async (event: ChangeEvent<HTMLInputElement>) => {
    var text = event.target.value;
    await TrashUpdateFolder({
      id: selected_folder!.id,
      name: text,
      type: "folder",
      is_open: false,
    });
  };

  const handleUpload = async () => {};

  const handleDelete = async (item: DocumentRequest | FolderRequest) => {
    console.log("entro");
    let data: TrashRequest = {
      id: item.id,
      name: item.name,
      created_by: {
        id: 1,
        name: "",
      },
      is_open: false,
      type: "folder",
    };
    if ("sub_folders" in data) {
      data.type = "folder";
      await TrashDeleteFolder(data);
    } else {
      data.type = "document";
      await TrashDeleteDocument(data);
    }
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (formData.case?.id! > 0) {
      await TrashGetTrash(formData);
    }
  };

  const handleSelectChange =
    (fieldName: keyof TrashRequest) => (selectedOptions: any) => {
      if (fieldName === "case") {
        if (selectedOptions.id === 0) {
          setFormData({
            ...formData,
            [fieldName]: null,
          });

          set_selected_case(null);
        } else {
          setFormData({
            ...formData,
            [fieldName]: selectedOptions,
          });

          set_selected_case(selectedOptions);
        }
      } else {
        setFormData({
          ...formData,
          [fieldName]: selectedOptions,
        });
      }
    };

  const getOptionValue = (option: any) => option.id;
  const getOptionLabel = (option: any) => option.name;

  function get_documents(folder: FolderRequest): DocumentRequest[] {
    return documents.filter((x) => folder.documents?.find((y) => y === x.id));
  }

  function get_sub_folders(folder: FolderRequest): FolderRequest[] {
    return folders.filter((x) => folder.sub_folders?.find((y) => y === x.id));
  }

  return (
    <FullWidthLayout>
      <div className="text-slate-700 dark:text-stone-50 fixed w-full bottom-0 left-0 flex flex-row dark:bg-slate-700 bg-stone-50 z-10 items-center justify-center px-3">
        {selected_folder && selected_folder.id !== "" ? (
          <div className="w-full">
            Folder: {selected_folder.id} - {selected_folder.name}
          </div>
        ) : (
          <></>
        )}
        {selected_document && selected_document?.id !== "" ? (
          <div className="w-full">
            Document: {selected_document?.id} - {selected_document?.name}
          </div>
        ) : (
          <></>
        )}

        {formData.case ? (
          <div className="p-2">
            <NewFolderModal
              parent_folder={selected_folder || null}
              colors={[]}
              icon={<HiPlusCircle />}
              label={""}
              case={selected_case || null}
              postFolder={TrashPostFolder}
            />
          </div>
        ) : (
          <p className="text-slate-700 dark:text-stone-50">
            Selecciona un Caso
          </p>
        )}

        {selected_folder && selected_folder.id !== "" ? (
          <div className="p-1">
            <Dropzone
              onDrop={handleOnDrop}
              colors={[]}
              icon={<HiUpload />}
              label={""}
              folder_id={selected_folder ? selected_folder.id : ""}
              handleUpload={handleUpload}
              the_documents={trash_state?.the_documents || []}
              postDocument={handlePostDocument}
            />
          </div>
        ) : (
          <></>
        )}
      </div>

      <form onSubmit={handleSubmit} className="flex flex-wrap items-left py-3">
        <div className="w-full">
          <Select
            placeholder="Caso"
            key={
              [
                {
                  id: 0,
                  name: "Sin Caso",
                  new_one: null,
                },
                ...(cases ?? []),
              ]?.length
            }
            options={[
              {
                id: 0,
                name: "Sin Caso",
                new_one: null,
              },
              ...(cases ?? []),
            ]}
            formatOptionLabel={(data) => {
              return data.name;
            }}
            onChange={handleSelectChange("case")}
            value={formData.case}
            getOptionValue={getOptionValue}
            getOptionLabel={getOptionLabel}
            className="w-full"
          />
        </div>
        <div className="w-full pt-2">
          <p className="text-sm text-gray-500 text-right">
            Quieres generar el reporte ?
          </p>
        </div>
        <div className="w-full pt-2 flex justify-end space-x-2">
          <CustomButton
            type="button"
            label={"Cancelar"}
            colors={["indigo", "indigo"]}
            icon={<HiCheck />}
            onClick={function (): void {
              setFormData(initialRequestData);
            }}
          />

          <CustomButton
            type="submit"
            label={"Si"}
            colors={["indigo", "indigo"]}
            icon={<HiCheck />}
            onClick={function (): void {}}
          />
        </div>
      </form>

      <div className="w-full flex flex-wrap">
        <div className="w-full md:w-1/2 p-2">
          <div className="w-full p-2 text-slate-700 dark:text-stone-50">
            Folders :
          </div>
          <FolderExplorer
            selected_document={selected_document || null}
            onUpdateFolder={onUpdateFolder}
            folders={folders ?? []}
            documents={documents ?? []}
            onDeleteItem={onDeleteItem}
            selectFolder={handleSelectFolder}
            selectDocument={handleSelectDocument}
            selected_folder={selected_folder || null}
            onUpdateDocument={onUpdateDocument}
          />
        </div>

        <div className="w-full md:w-1/2 p-2 bg-gray-200">
          <div className="w-full p-2">Contenido :</div>
          <FolderContent
            selected_document={selected_document || null}
            onUpdateFolder={onUpdateFolder}
            folders={selected_folder ? get_sub_folders(selected_folder) : []}
            onDeleteItem={onDeleteItem}
            selectFolder={handleSelectFolder}
            selectDocument={handleSelectDocument}
            onDeleteDocument={onDeleteItem}
            selected_folder={selected_folder || null}
            onUpdateDocument={onUpdateDocument}
            documents={selected_folder ? get_documents(selected_folder) : []}
          />
        </div>

        <div className="w-full md:w-1/2 p-2">
          {trash_state?.documents.map((file, index) => (
            <div key={index} className="border p-3 m-1 bg-white">
              <p>{file.name}</p>
              <button onClick={() => handleDelete(file)}>Delete</button>
            </div>
          ))}
        </div>
      </div>

      <div className="w-full p-2"></div>
    </FullWidthLayout>
  );
};

const mapStateToProps = (state: RootState) => ({
  cases: get_cases(state),
  trash_state: state.trash_state,
  // document_new_ones: document_new_ones(state),
});

const mapDispatchToProps = {
  TrashGetTrash,
  TrashPostDocument,
  TrashPostFolder,
  TrashDeleteFolder,
  TrashDeleteDocument,
  TrashUpdateDocument,
  TrashUpdateFolder,
  getCase,
  selectCase,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(Documents);
