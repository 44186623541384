import { TreasuryActionTypes, TreasuryState } from "../types/treasury_types";
import axios from "axios";
axios.defaults.withCredentials = true;

const initialState: TreasuryState = {
  id: 0,
  search: "",
  new_one: null,
  incomes: [],
  income_types: [],
  outcomes: [],
  outcome_type: [],

  data: [],
  error: null,
  message: "",
};

const TreasuryReducer = (state = initialState, action: TreasuryActionTypes) => {
  switch (action.type) {
    case "POST_TREASURY":
      // const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/`, {});
      // console.log(response)
      return { ...state, data: [] };
    case "POST_SUCCESS_TREASURY":
      return { ...state, error: null, message: action.payload.message };
    case "POST_FAIL_TREASURY":
      return {
        ...state,
        error: action.payload.error,
        message: action.payload.message,
      };

    case "PUT_TREASURY":
      // const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/`, {});
      // console.log(response)
      return { ...state, data: [] };
    case "PUT_SUCCESS_TREASURY":
      return { ...state, error: null, message: action.payload.message };
    case "PUT_FAIL_TREASURY":
      return {
        ...state,
        error: action.payload.error,
        message: action.payload.message,
      };

    case "UPDATE_TREASURY":
      // const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/`, {});
      // console.log(response)
      return { ...state, data: [] };
    case "UPDATE_SUCCESS_TREASURY":
      return { ...state, error: null, message: action.payload.message };
    case "UPDATE_FAIL_TREASURY":
      return {
        ...state,
        error: action.payload.error,
        message: action.payload.message,
      };

    case "DELETE_TREASURY":
      // const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/`, {});
      // console.log(response)
      return { ...state, data: [] };
    case "DELETE_SUCCESS_TREASURY":
      return { ...state, error: null, message: action.payload.message };
    case "DELETE_FAIL_TREASURY":
      return {
        ...state,
        error: action.payload.error,
        message: action.payload.message,
      };

    case "GET_TREASURY":
      // const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/`, {});
      // console.log(response)
      return {
        ...state,
        incomes: action.payload.incomes,
        outcomes: action.payload.outcomes,
      };
    case "GET_SUCCESS_TREASURY":
      return { ...state, error: null, message: action.payload.message };
    case "GET_FAIL_TREASURY":
      return {
        ...state,
        error: action.payload.error,
        message: action.payload.message,
      };
    // [ANCHOR_1]
    case "START_LOADING":
      return { ...state, loading: true };
    case "END_LOADING":
      return { ...state, loading: false };
    default:
      return state;
  }
};

export default TreasuryReducer;
