import { Dispatch } from "redux";
import { GroupActionTypes, GroupState } from "../types/group_types";
import axios from "axios";
axios.defaults.withCredentials = true;

export const postGroup =
  (data: GroupState) => (dispatch: Dispatch<GroupActionTypes>) => {
    {
      try {
        dispatch({ type: "POST_GROUP", payload: data });

        dispatch({
          type: "POST_SUCCESS_GROUP",
          payload: { ...data, error: null, message: "POST_GROUP_SUCCESS" },
        });
      } catch (error) {
        dispatch({
          type: "POST_FAIL_GROUP",
          payload: { ...data, error: error, message: "POST_GROUP_FAIL" },
        });
      }
    }
  };

export const putGroup =
  (data: GroupState) => (dispatch: Dispatch<GroupActionTypes>) => {
    {
      try {
        dispatch({ type: "PUT_GROUP", payload: data });

        dispatch({
          type: "PUT_SUCCESS_GROUP",
          payload: { ...data, error: null, message: "PUT_GROUP_SUCCESS" },
        });
      } catch (error) {
        dispatch({
          type: "PUT_FAIL_GROUP",
          payload: { ...data, error: error, message: "PUT_GROUP_FAIL" },
        });
      }
    }
  };

export const updateGroup =
  (data: GroupState) => (dispatch: Dispatch<GroupActionTypes>) => {
    {
      try {
        dispatch({ type: "UPDATE_GROUP", payload: data });

        dispatch({
          type: "UPDATE_SUCCESS_GROUP",
          payload: { ...data, error: null, message: "UPDATE_GROUP_SUCCESS" },
        });
      } catch (error) {
        dispatch({
          type: "UPDATE_FAIL_GROUP",
          payload: { ...data, error: error, message: "UPDATE_GROUP_FAIL" },
        });
      }
    }
  };

export const deleteGroup =
  (data: GroupState) => (dispatch: Dispatch<GroupActionTypes>) => {
    {
      try {
        dispatch({ type: "DELETE_GROUP", payload: data });

        dispatch({
          type: "DELETE_SUCCESS_GROUP",
          payload: { ...data, error: null, message: "DELETE_GROUP_SUCCESS" },
        });
      } catch (error) {
        dispatch({
          type: "DELETE_FAIL_GROUP",
          payload: { ...data, error: error, message: "DELETE_GROUP_FAIL" },
        });
      }
    }
  };

export const getGroup =
  (data: GroupState) => async (dispatch: Dispatch<GroupActionTypes>) => {
    {
      try {
        const groups = await axios.post(
          `${process.env.REACT_APP_API_URL}/api/lawyer/general-data`,
          { id: data.id, search: "group" }
        );
        dispatch({ type: "GET_GROUP", payload: groups.data });

        dispatch({
          type: "GET_SUCCESS_GROUP",
          payload: { ...data, error: null, message: "GET_GROUP_SUCCESS" },
        });
      } catch (error) {
        dispatch({
          type: "GET_FAIL_GROUP",
          payload: { ...data, error: error, message: "GET_GROUP_FAIL" },
        });
      }
    }
  };
// [ANCHOR_1]
