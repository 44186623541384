
import { GoodsActionTypes, GoodsState } from '../types/goods_types';

const initialState: GoodsState = {
    id: "",
    name: '',
    data: [],
    goodses: [],
    error: null,
    message: '',
    loading: false,
    deleted: null,
    new_one: null,
    new_ones: [],
};

const GoodsReducer = (state = initialState, action: GoodsActionTypes) => {
    switch (action.type) {

        case 'GOODS_UPLOAD_CATEGORY':
            return {...state, goodses: action.payload.goodses, error: null, message: action.payload.message};
        case 'GOODS_UPLOAD_CATEGORY_SUCCESS':
            return {...state, error: null, message: action.payload.message};
        case 'GOODS_UPLOAD_CATEGORY_FAIL':
            return {...state, error: action.payload, message: 'UPLOAD_CATEGORY_FAIL'};

        case 'GOODS_GET_CATEGORY':
            return {...state, goodses: action.payload.goodses, error: null, message: action.payload.message};
        case 'GOODS_GET_CATEGORY_SUCCESS':
            return {...state, error: null, message: action.payload.message};
        case 'GOODS_GET_CATEGORY_FAIL':
            return {...state, error: action.payload, message: 'GET_CATEGORY_FAIL'};

        case 'GOODS_UPLOAD_SUB_CATEGORY':
            return {...state, goodses: action.payload.goodses, error: null, message: action.payload.message};
        case 'GOODS_UPLOAD_SUB_CATEGORY_SUCCESS':
            return {...state, error: null, message: action.payload.message};
        case 'GOODS_UPLOAD_SUB_CATEGORY_FAIL':
            return {...state, error: action.payload, message: 'UPLOAD_SUB_CATEGORY_FAIL'};

        case 'GOODS_GET_SUB_CATEGORY':
            return {...state, goodses: action.payload.goodses, error: null, message: action.payload.message};
        case 'GOODS_GET_SUB_CATEGORY_SUCCESS':
            return {...state, error: null, message: action.payload.message};
        case 'GOODS_GET_SUB_CATEGORY_FAIL':
            return {...state, error: action.payload, message: 'GET_SUB_CATEGORY_FAIL'};

        case 'GOODS_UPLOAD_PRODUCT':
            return {...state, goodses: action.payload.goodses, error: null, message: action.payload.message};
        case 'GOODS_UPLOAD_PRODUCT_SUCCESS':
            return {...state, error: null, message: action.payload.message};
        case 'GOODS_UPLOAD_PRODUCT_FAIL':
            return {...state, error: action.payload, message: 'UPLOAD_PRODUCT_FAIL'};

        case 'GOODS_GET_PRODUCT':
            return {...state, goodses: action.payload.goodses, error: null, message: action.payload.message};
        case 'GOODS_GET_PRODUCT_SUCCESS':
            return {...state, error: null, message: action.payload.message};
        case 'GOODS_GET_PRODUCT_FAIL':
            return {...state, error: action.payload, message: 'GET_PRODUCT_FAIL'};

        case 'GOODS_STORE_CATEGORY':
            return {...state, goodses: action.payload.goodses, error: null, message: action.payload.message};
        case 'GOODS_STORE_CATEGORY_SUCCESS':
            return {...state, error: null, message: action.payload.message};
        case 'GOODS_STORE_CATEGORY_FAIL':
            return {...state, error: action.payload, message: 'STORE_CATEGORY_FAIL'};

        case 'GOODS_STORE_SUB_CATEGORY':
            return {...state, goodses: action.payload.goodses, error: null, message: action.payload.message};
        case 'GOODS_STORE_SUB_CATEGORY_SUCCESS':
            return {...state, error: null, message: action.payload.message};
        case 'GOODS_STORE_SUB_CATEGORY_FAIL':
            return {...state, error: action.payload, message: 'STORE_SUB_CATEGORY_FAIL'};

        case 'GOODS_STORE_PRODUCT':
            return {...state, goodses: action.payload.goodses, error: null, message: action.payload.message};
        case 'GOODS_STORE_PRODUCT_SUCCESS':
            return {...state, error: null, message: action.payload.message};
        case 'GOODS_STORE_PRODUCT_FAIL':
            return {...state, error: action.payload, message: 'STORE_PRODUCT_FAIL'};

        case 'GOODS_UPDATE_CATEGOY':
            return {...state, goodses: action.payload.goodses, error: null, message: action.payload.message};
        case 'GOODS_UPDATE_CATEGOY_SUCCESS':
            return {...state, error: null, message: action.payload.message};
        case 'GOODS_UPDATE_CATEGOY_FAIL':
            return {...state, error: action.payload, message: 'UPDATE_CATEGOY_FAIL'};

        case 'GOODS_UPDATE_SUB_CATEGORY':
            return {...state, goodses: action.payload.goodses, error: null, message: action.payload.message};
        case 'GOODS_UPDATE_SUB_CATEGORY_SUCCESS':
            return {...state, error: null, message: action.payload.message};
        case 'GOODS_UPDATE_SUB_CATEGORY_FAIL':
            return {...state, error: action.payload, message: 'UPDATE_SUB_CATEGORY_FAIL'};

        case 'GOODS_UPDATE_PRODUCT':
            return {...state, goodses: action.payload.goodses, error: null, message: action.payload.message};
        case 'GOODS_UPDATE_PRODUCT_SUCCESS':
            return {...state, error: null, message: action.payload.message};
        case 'GOODS_UPDATE_PRODUCT_FAIL':
            return {...state, error: action.payload, message: 'UPDATE_PRODUCT_FAIL'};

        case 'GOODS_DELETE_PRODUCT':
            return {...state, goodses: action.payload.goodses, error: null, message: action.payload.message};
        case 'GOODS_DELETE_PRODUCT_SUCCESS':
            return {...state, error: null, message: action.payload.message};
        case 'GOODS_DELETE_PRODUCT_FAIL':
            return {...state, error: action.payload, message: 'DELETE_PRODUCT_FAIL'};
// [ANCHOR_1]













        case 'START_LOADING':
            return {...state, loading: true};
        case 'END_LOADING':
            return {...state, loading: false};
        default:
            return state;
    }
};

export default GoodsReducer;
    