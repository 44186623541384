import React, { Component } from "react";
import { GoodsCategoryRequest } from "../../../redux/types/merchant_types";

interface CategorySelectorProps {
  // Define your props interface here
  select_category: (category: GoodsCategoryRequest) => void;
  categories: GoodsCategoryRequest[];
}

interface CategorySelectorState {
  // Define your state interface here
  categories: GoodsCategoryRequest[];
  input_value: string;
}

class CategorySelector extends Component<
  CategorySelectorProps,
  CategorySelectorState
> {
  constructor(props: CategorySelectorProps) {
    super(props);
    // Initialize the state with default values
    this.state = {
      categories: [],
      input_value: "",
    };
  }

  componentDidUpdate(
    prevProps: CategorySelectorProps,
    prevState: CategorySelectorState
  ) {
    // Check if input_value has changed
    if (prevState.input_value !== this.state.input_value) {
      this.handle_get_categories(this.state.input_value);
    }
  }

  componentDidMount() {
    this.handle_get_categories(this.state.input_value);
  }

  handle_get_categories(input_value: string) {
    // Implement your logic for getting categories here
  }

  handle_select_category(category: GoodsCategoryRequest) {
    this.props.select_category(category);
  }

  render() {
    return (
      // JSX code for your component's UI goes here
      <div>
        <div>
          <p>{this.state.input_value}</p>
          <input
            className="w-full p-2 border border-gray-200 dark:border-gray-700 dark:bg-gray-800 dark:text-gray-300"
            value={this.state.input_value}
            onInput={(e: React.FormEvent<HTMLInputElement>) =>
              this.setState({ input_value: e.currentTarget.value })
            }
          />
        </div>
        {/* Example usage of handle_select_category */}
        <div className="w-full gap-2 p-2">
          {this.props.categories.map((category) => {
            return (
              <div
                key={category.id}
                className="flex items-center justify-between p-2 border-b border-gray-200 cursor-pointer hover:bg-gray-100 dark:hover:bg-gray-800 dark:border-gray-700 dark:text-gray-300 dark:borde"
                onClick={() => this.handle_select_category(category)}
              >
                <div className="flex items-center">
                  <div className="text-sm font-semibold text-gray-600">
                    {category.name}
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    );
  }
}

export default CategorySelector;
