import NoAuthFullWidthLayoutLegal from "../../layouts/no_auth_full_width_layout_legal";
import { ConnectedProps, connect } from "react-redux";
import { HiCheck } from "react-icons/hi";
import LoadingFullWidth from "../../components/loaders/loading_full_width";
import { loginWeb3 } from "../../redux/actions/web3";
import { postGoogle } from "../../core/redux/actions/google_action";
import { RootState } from "../../store";
import { TextField } from "@mui/material";
import { UserAccount } from "./redux/types/user_account_types";
import { useState } from "react";
import { passwordResetUserAccount } from "./redux/actions/user_account_action";
import { CustomButton } from "../../core/components/buttons/custom_button";
import { Link } from "react-router-dom";

const PasswordReset: React.FC<PropsFromRedux> = ({
  loading,
  passwordResetUserAccount,
  user_account_state,
}: PropsFromRedux) => {
  const [formData, setFormData] = useState<UserAccount>({
    email: "",
  });

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    try {
      event.preventDefault();
      passwordResetUserAccount(formData);
    } catch (error) {
      console.log("Error new case modal : ", error);
    }
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  return (
    <NoAuthFullWidthLayoutLegal>
      <div className="text-center">
        <p className="mt-8 text-xl font-gilroy-bold dark:text-stone-50 text-slate-900 sm:text-3xl sm:tracking-tight lg:text-4xl">
          No te preocupes
        </p>
        <p className="max-w-xl my-5 mx-auto text-xl dark:text-stone-50 text-slate-500">
          Por favor ingresa tu correo y enviaremos un enlace para que puedas
          restablecer tu contraseña
        </p>
      </div>

      {!loading ? (
        <div className="shadow overflow-hidden sm:rounded-md">
          <div className="divide-y divide-gray-200">
            <form
              onSubmit={handleSubmit}
              className="flex flex-wrap items-left p-2 bg-slate-50 dark:bg-slate-300"
            >
              <div className="p-1 w-full">
                <TextField
                  type="text"
                  name="email"
                  id="email"
                  label="email"
                  variant="outlined"
                  onChange={handleInputChange}
                  value={formData.email}
                  className="w-full"
                />
              </div>

              <div className="w-full pt-2 flex justify-end space-x-2">
                <div>
                  {user_account_state?.error && (
                    <div className="p-1 w-full text-red-500">
                      {Object.keys(user_account_state.error).map((key) => (
                        <p key={key}>
                          {key.includes("password") ? "password" : key}:{" "}
                          {user_account_state.error[key]}
                        </p>
                      ))}
                    </div>
                  )}

                  {user_account_state?.detail &&
                    user_account_state?.detail !== "" && (
                      <div className="p-1 w-full text-green-500">
                        <p>{user_account_state?.detail}</p>
                      </div>
                    )}
                </div>
                <div>
                  <CustomButton
                    label="Enviar datos"
                    onClick={() => {}}
                    colors={["blue", "blue"]}
                    icon={<HiCheck />}
                    type="submit"
                  />
                </div>
              </div>
              <div className="p-1 flex">
                <div>
                  <Link to="/login" className="text-blue-500 hover:underline">
                    Login
                  </Link>
                </div>
                <span className="mx-2">|</span>
                <div>
                  <Link
                    to="/registration"
                    className="text-blue-500 hover:underline"
                  >
                    Registrarme
                  </Link>
                </div>
              </div>
            </form>
          </div>
        </div>
      ) : (
        <LoadingFullWidth />
      )}
      <br />
    </NoAuthFullWidthLayoutLegal>
  );
};

const mapStateToProps = (state: RootState) => ({
  loading: state.web3.loading,
  account: state.web3.account,
  nextwork: state.web3.network,
  google_state: state.google_state,
  user_account_state: state.user_account_state,
  //my_user: state.user.my_user,
});

const mapDispatchToProps = {
  loginWeb3,
  postGoogle,
  passwordResetUserAccount,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(PasswordReset);
