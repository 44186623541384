import { Dispatch } from "redux";
import { SeatActionTypes, SeatRequest, SeatState } from "../types/seat_types";
import axios from "axios";
axios.defaults.withCredentials = true;

export const postSeat =
  (data: SeatRequest) => async (dispatch: Dispatch<SeatActionTypes>) => {
    {
      try {
        dispatch({
          type: "START_LOADING",
          payload: { ...data, error: null, message: "START_LOADING" },
        });
        const response = await axios.post(
          `${process.env.REACT_APP_API_URL}/api/lawyer/seat`,
          data
        );
        dispatch({ type: "POST_SEAT", payload: response.data });
        dispatch({
          type: "END_LOADING",
          payload: { ...data, error: null, message: "END_LOADING" },
        });
        dispatch({
          type: "POST_SUCCESS_SEAT",
          payload: {
            ...response.data,
            error: null,
            message: "POST_SEAT_SUCCESS",
          },
        });
      } catch (error) {
        dispatch({
          type: "END_LOADING",
          payload: { ...data, error: null, message: "END_LOADING" },
        });
        dispatch({
          type: "POST_FAIL_SEAT",
          payload: { ...data, error: error, message: "POST_SEAT_FAIL" },
        });
      }
    }
  };

export const putSeat =
  (data: SeatRequest) => async (dispatch: Dispatch<SeatActionTypes>) => {
    {
      try {
        dispatch({
          type: "START_LOADING",
          payload: { ...data, error: null, message: "START_LOADING" },
        });
        const response = await axios.put(
          `${process.env.REACT_APP_API_URL}/api/lawyer/seat?id=${data.id}&name=${data.name}`
        );
        dispatch({ type: "PUT_SEAT", payload: response.data });
        dispatch({
          type: "END_LOADING",
          payload: { ...data, error: null, message: "END_LOADING" },
        });
        dispatch({
          type: "PUT_SUCCESS_SEAT",
          payload: {
            ...response.data,
            error: null,
            message: "PUT_SEAT_SUCCESS",
          },
        });
      } catch (error) {
        dispatch({
          type: "END_LOADING",
          payload: { ...data, error: null, message: "END_LOADING" },
        });
        dispatch({
          type: "PUT_FAIL_SEAT",
          payload: { ...data, error: error, message: "PUT_SEAT_FAIL" },
        });
      }
    }
  };

export const updateSeat =
  (data: SeatRequest) => async (dispatch: Dispatch<SeatActionTypes>) => {
    {
      try {
        dispatch({
          type: "START_LOADING",
          payload: { ...data, error: null, message: "START_LOADING" },
        });
        const response = await axios.put(
          `${process.env.REACT_APP_API_URL}/api/lawyer/seat?id=${data.id}&name=${data.name}`
        );
        dispatch({ type: "UPDATE_SEAT", payload: response.data });
        dispatch({
          type: "END_LOADING",
          payload: { ...data, error: null, message: "END_LOADING" },
        });
        dispatch({
          type: "UPDATE_SUCCESS_SEAT",
          payload: {
            ...response.data,
            error: null,
            message: "UPDATE_SEAT_SUCCESS",
          },
        });
      } catch (error) {
        dispatch({
          type: "END_LOADING",
          payload: { ...data, error: null, message: "END_LOADING" },
        });
        dispatch({
          type: "UPDATE_FAIL_SEAT",
          payload: { ...data, error: error, message: "UPDATE_SEAT_FAIL" },
        });
      }
    }
  };

export const deleteSeat =
  (data: SeatRequest) => async (dispatch: Dispatch<SeatActionTypes>) => {
    {
      try {
        dispatch({
          type: "START_LOADING",
          payload: { ...data, error: null, message: "START_LOADING" },
        });
        const response = await axios.delete(
          `${process.env.REACT_APP_API_URL}/api/lawyer/seat?id=${data.id}&name=${data.name}`
        );
        dispatch({ type: "DELETE_SEAT", payload: response.data });
        dispatch({
          type: "END_LOADING",
          payload: { ...data, error: null, message: "END_LOADING" },
        });
        dispatch({
          type: "DELETE_SUCCESS_SEAT",
          payload: {
            ...response.data,
            error: null,
            message: "DELETE_SEAT_SUCCESS",
          },
        });
      } catch (error) {
        dispatch({
          type: "END_LOADING",
          payload: { ...data, error: null, message: "END_LOADING" },
        });
        dispatch({
          type: "DELETE_FAIL_SEAT",
          payload: { ...data, error: error, message: "DELETE_SEAT_FAIL" },
        });
      }
    }
  };

export const getSeat =
  (data: SeatRequest) => async (dispatch: Dispatch<SeatActionTypes>) => {
    {
      try {
        dispatch({
          type: "START_LOADING",
          payload: { ...data, error: null, message: "START_LOADING" },
        });
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/api/lawyer/seat?id=${data.id}&name=${data.name}`
        );

        dispatch({ type: "GET_SEAT", payload: response.data });
        dispatch({
          type: "END_LOADING",
          payload: { ...data, error: null, message: "END_LOADING" },
        });
        dispatch({
          type: "GET_SUCCESS_SEAT",
          payload: {
            ...response.data,
            error: null,
            message: "GET_SEAT_SUCCESS",
          },
        });
      } catch (error) {
        dispatch({
          type: "END_LOADING",
          payload: { ...data, error: null, message: "END_LOADING" },
        });
        dispatch({
          type: "GET_FAIL_SEAT",
          payload: { ...data, error: error, message: "GET_SEAT_FAIL" },
        });
      }
    }
  };
// [ANCHOR_1]
