import { Dispatch } from "redux";
import { TreasuryActionTypes, TreasuryRequest } from "../types/treasury_types";
import axios, { AxiosError } from "axios";

export const postTreasury =
  (data: TreasuryRequest) => (dispatch: Dispatch<TreasuryActionTypes>) => {
    try {
      dispatch({ type: "POST_TREASURY", payload: data });

      dispatch({
        type: "POST_SUCCESS_TREASURY",
        payload: { ...data, error: null, message: "POST_SUCCESS" },
      });
    } catch (error) {
      dispatch({
        type: "POST_FAIL_TREASURY",
        payload: { ...data, error: error, message: "POST_FAIL" },
      });
    }
  };

export const putTreasury =
  (data: TreasuryRequest) => (dispatch: Dispatch<TreasuryActionTypes>) => {
    try {
      dispatch({ type: "PUT_TREASURY", payload: data });

      dispatch({
        type: "PUT_SUCCESS_TREASURY",
        payload: { ...data, error: null, message: "PUT_SUCCESS" },
      });
    } catch (error) {
      dispatch({
        type: "PUT_FAIL_TREASURY",
        payload: { ...data, error: error, message: "PUT_FAIL" },
      });
    }
  };

export const updateTreasury =
  (data: TreasuryRequest) => (dispatch: Dispatch<TreasuryActionTypes>) => {
    try {
      dispatch({ type: "UPDATE_TREASURY", payload: data });

      dispatch({
        type: "UPDATE_SUCCESS_TREASURY",
        payload: { ...data, error: null, message: "UPDATE_SUCCESS" },
      });
    } catch (error) {
      dispatch({
        type: "UPDATE_FAIL_TREASURY",
        payload: { ...data, error: error, message: "UPDATE_FAIL" },
      });
    }
  };

export const deleteTreasury =
  (data: TreasuryRequest) => (dispatch: Dispatch<TreasuryActionTypes>) => {
    try {
      dispatch({ type: "DELETE_TREASURY", payload: data });

      dispatch({
        type: "DELETE_SUCCESS_TREASURY",
        payload: { ...data, error: null, message: "DELETE_SUCCESS" },
      });
    } catch (error) {
      dispatch({
        type: "DELETE_FAIL_TREASURY",
        payload: { ...data, error: error, message: "DELETE_FAIL" },
      });
    }
  };

export const getTreasury =
  (data: TreasuryRequest) => (dispatch: Dispatch<TreasuryActionTypes>) => {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    dispatch({
      type: "START_LOADING",
      payload: { ...data, error: null, message: "START_LOADING" },
    });

    axios
      .post(`${process.env.REACT_APP_API_URL}/api/lawyer/treasury`, {
        id: 0,
        search: "both",
        name: "",
        case: {
          id: data.case ? data.case.id : 0,
        },
      })
      .then((response) => {
        dispatch({
          type: "GET_TREASURY",
          payload: response.data,
        });
        dispatch({
          type: "END_LOADING",
          payload: {
            id: 0,
            error: null,
            message: "END_LOADING",
            search: "",
            case: {
              id: 0,
              new_one: null,
            },
            amount: 0,
            description: "",
            hours: 0,
            tags: [],
            photo: "",
            start_at: null,
            end_at: null,
            income_type: null,
            validated: false,
            created_by: null,
            validated_by: null,
          },
        });
        dispatch({
          type: "GET_SUCCESS_TREASURY",
          payload: {
            ...response.data,
            error: null,
            message: "GET_SUCCESS_TREASURY",
          },
        });
      })
      .catch((error: AxiosError<any>) => {
        if (error.response) {
          console.log("Error response data:", error.response.data);
          console.log("Status code:", error.response.status);
          console.log("Headers:", error.response.headers);

          dispatch({
            type: "END_LOADING",
            payload: {
              id: 0,
              error: null,
              message: "END_LOADING",
              search: "",
              case: {
                id: 0,
                new_one: null,
              },
              amount: 0,
              description: "",
              hours: 0,
              tags: [],
              photo: "",
              start_at: null,
              end_at: null,
              income_type: null,
              validated: false,
              created_by: null,
              validated_by: null,
            },
          });

          dispatch({
            type: "GET_FAIL_TREASURY",
            payload: {
              error: error.response.data,
              message: "Usuario no valido",
              id: 0,
              search: "",
              case: {
                id: 0,
                new_one: null,
              },
              amount: 0,
              description: "",
              hours: 0,
              tags: [],
              photo: "",
              start_at: null,
              end_at: null,
              income_type: null,
              validated: false,
              created_by: null,
              validated_by: null,
            },
          });
        } else if (error.request) {
          console.log("No response received:", error.request);
        } else {
          console.log("Error:", error.message);
        }
        console.log("Config:", error.config);
      });
  };
// [ANCHOR_1]
