
import { GoogleActionTypes, GoogleState } from '../types/google_types';
import axios from 'axios'
axios.defaults.withCredentials = true;

const initialState: GoogleState = {
    id: 0,
    name: '',
    user: null,
    data: [],
    error: null,
    message: '',

    account: null,
    authuser: "0",
    expires_in: 0,
    prompt: "consent",
    scope: "",
    token_type: "Bearer",
    access: '',
    refresh: '',
    access_token: ''
};

const GoogleReducer = (state = initialState, action: GoogleActionTypes) => {
    switch (action.type) {
        
        case 'POST_GOOGLE':
            // const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/`, {});
            // console.log(response)
            return {...state, ...action.payload};
        case 'POST_SUCCESS_GOOGLE':
            return {...state, error: null, message: action.payload.message};
        case 'POST_FAIL_GOOGLE':
            localStorage.removeItem('access');
            return {
                ...state,
                access: null,
                isAuthenticated: false,
                user: null,
                message: "Login has failed"
            }
        
        case 'PUT_GOOGLE':
            // const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/`, {});
            // console.log(response)
            return {...state, data: []};
        case 'PUT_SUCCESS_GOOGLE':
            return {...state, error: null, message: action.payload.message};
        case 'PUT_FAIL_GOOGLE':
            return {...state, error: action.payload.error, message: action.payload.message};
        
        case 'UPDATE_GOOGLE':
            // const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/`, {});
            // console.log(response)
            return {...state, data: []};
        case 'UPDATE_SUCCESS_GOOGLE':
            return {...state, error: null, message: action.payload.message};
        case 'UPDATE_FAIL_GOOGLE':
            return {...state, error: action.payload.error, message: action.payload.message};
            
        case 'DELETE_GOOGLE':
            // const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/`, {});
            // console.log(response)
            return {...state, data: []};
        case 'DELETE_SUCCESS_GOOGLE':
            return {...state, error: null, message: action.payload.message};
        case 'DELETE_FAIL_GOOGLE':
            return {...state, error: action.payload.error, message: action.payload.message};
        
        case 'GET_GOOGLE':
            // const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/`, {});
            // console.log(response)
            return {...state, data: []};
        case 'GET_SUCCESS_GOOGLE':
            return {...state, error: null, message: action.payload.message};
        case 'GET_FAIL_GOOGLE':
            return {...state, access_token: action.payload.error, message: action.payload.message};

        case 'VERIFY_GOOGLE':
            return {
                ...state,
                isAuthenticated: true
            }
        case 'VERIFY_SUCCESS_GOOGLE':
            return {...state, data: []};
        case 'VERIFY_FAIL_GOOGLE':
            return {
                ...state,
                isAuthenticated: false
            }

        case 'USER_GOOGLE':
            return {
                ...state,
                user: action.payload
            }
        case 'USER_SUCCESS_GOOGLE':
            return {...state, data: []};
        case 'USER_FAIL_GOOGLE':
            return {
                ...state,
                user: null
            }

        case 'REFRESH_GOOGLE':
            localStorage.setItem('access', action.payload.access? action.payload.access: '');
            return {
                ...state,
                access: action.payload.access,
                isAuthenticated: true,
                message: "Refresh token success"
            }
        case 'REFRESH_SUCCESS_GOOGLE':
            return {...state, data: []};
        case 'REFRESH_FAIL_GOOGLE':
            localStorage.removeItem('access');
            return {
                ...state,
                access: null,
                isAuthenticated: false,
                user: null,
                message: "Refresh token fail"
            }

        case 'LOGOUT_GOOGLE':
            localStorage.removeItem('access');
            return {
                ...state,
                access: null,
                isAuthenticated: false,
                user: null,
                message: "User has logged out"
            }
        case 'LOGOUT_SUCCESS_GOOGLE':
            return {...state, data: []};
        case 'LOGOUT_FAIL_GOOGLE':
            return {...state, error: action.payload.error, data: []};

        case 'GUEST_GOOGLE':
            return {...state};
        case 'GUEST_SUCCESS_GOOGLE':
            return {...state};
        case 'GUEST_FAIL_GOOGLE':
            return {...state};

        case 'FAIL_GOOGLE':
            localStorage.removeItem('access');
            return {
                ...state,
                access: null,
                isAuthenticated: false,
                user: null,
                message: "Login has failed"
            }
        case 'FAIL_SUCCESS_GOOGLE':
            return {...state, data: []};
        case 'FAIL_FAIL_GOOGLE':
            return {...state, error: action.payload.error, data: []};

        case 'LOGIN_GOOGLE':
            localStorage.setItem('access', action.payload.access? action.payload.access: '');
            return {
                ...state,
                access: action.payload.access,
                isAuthenticated: true,
                user: action.payload.user,
                message: "Login has successed"
            }
        case 'LOGIN_SUCCESS_GOOGLE':
            return {...state, data: []};
        case 'LOGIN_FAIL_GOOGLE':
            localStorage.removeItem('access');
            return {
                ...state,
                access: null,
                isAuthenticated: false,
                user: null,
                message: "Login has failed"
            }
// [ANCHOR_1]
        case 'START_LOADING':
            return {...state, loading: true};
        case 'END_LOADING':
            return {...state, loading: false};

        default:
            return state;
    }
};

export default GoogleReducer;
    